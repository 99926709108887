import React, {Component} from "../../_snowpack/pkg/react.js";
import {Fragment as F} from "../../_snowpack/pkg/react.js";
import {Link} from "../../_snowpack/pkg/react-router-dom.js";
import schoolBranding from "../school-branding.js";
import FeeSchedule from "./FeeSchedule.js";
import withPageLoading from "../HOC/withPageLoading.js";
import {dummyScheduleContent} from "../utils/dummyScheduleContent.js";
const renderIf = (predicate) => (element) => predicate && element;
const ProviderData = (props) => {
  const renderAddress = () => {
    const hasAddress1 = props.provider.address1;
    const hasAddress2 = props.provider.address2;
    const addr1 = props.provider.address1 || null;
    const addr2 = hasAddress2 ? /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("br", null), props.provider.address2) : null;
    const addr3 = /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("br", null), props.provider.city, ", ", props.provider.state, " ", props.provider.zip);
    const rowSpan = hasAddress1 && hasAddress2 ? 3 : 2;
    return /* @__PURE__ */ React.createElement(F, null, /* @__PURE__ */ React.createElement("span", null, "Address:"), /* @__PURE__ */ React.createElement("span", null, addr1, addr2, addr3));
  };
  const formatPhoneNumber = (input) => {
    const regex = /(\d{3})[-\s]?(\d{3})[-\s]?(\d{4})/;
    const match = input.match(regex);
    if (match) {
      const formattedNumber = `(${match[1]}) ${match[2]}-${match[3]}`;
      return formattedNumber;
    } else {
      return input;
    }
  };
  const formattedPhone = props.provider.phone && formatPhoneNumber(props.provider.phone);
  const formattedWebsite = props.provider.website && props.provider.website.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split("/")[0];
  return /* @__PURE__ */ React.createElement("section", {
    className: "card provider-info fee-schedule"
  }, /* @__PURE__ */ React.createElement("h2", null, "Provider info"), /* @__PURE__ */ React.createElement("address", {
    className: "provider-data"
  }, renderIf(props.provider.first_name)(/* @__PURE__ */ React.createElement(F, null, /* @__PURE__ */ React.createElement("span", null, "Name: "), /* @__PURE__ */ React.createElement("span", {
    id: "provider-name"
  }, props.provider.first_name, " ", props.provider.middle_initial, " ", props.provider.last_name, renderIf(props.provider.prof_des)(`, ${props.provider.prof_des}`)))), renderIf(props.provider.health_club_id)(/* @__PURE__ */ React.createElement(F, null, /* @__PURE__ */ React.createElement("span", null, "Name: "), /* @__PURE__ */ React.createElement("span", null, props.provider.location))), renderIf(props.provider.specialty)(/* @__PURE__ */ React.createElement(F, null, /* @__PURE__ */ React.createElement("span", null, "Specialty: "), /* @__PURE__ */ React.createElement("span", null, props.provider.specialty))), renderAddress(), renderIf(props.provider.group_name && props.provider.group_name !== "unaffiliated")(/* @__PURE__ */ React.createElement(F, null, /* @__PURE__ */ React.createElement("span", null, "Group:"), /* @__PURE__ */ React.createElement("span", null, props.provider.group_name))), renderIf(props.provider.school)(/* @__PURE__ */ React.createElement(F, null, /* @__PURE__ */ React.createElement("span", null, "School:"), /* @__PURE__ */ React.createElement("span", null, props.provider.school))), renderIf(props.provider.location && !props.provider.health_club_id)(/* @__PURE__ */ React.createElement(F, null, /* @__PURE__ */ React.createElement("span", null, "Location"), /* @__PURE__ */ React.createElement("span", null, props.provider.location))), renderIf(props.provider.phone)(/* @__PURE__ */ React.createElement(F, null, /* @__PURE__ */ React.createElement("span", null, "Phone:"), /* @__PURE__ */ React.createElement("a", {
    className: "phone-with-icon",
    href: `tel:${props.provider.phone}`
  }, /* @__PURE__ */ React.createElement("img", {
    style: {marginRight: "var(--size-1)"},
    alt: "phone icon",
    src: "/images/icon/ph.svg"
  }), " ", /* @__PURE__ */ React.createElement("span", null, formattedPhone)))), renderIf(props.provider.website)(/* @__PURE__ */ React.createElement(F, null, /* @__PURE__ */ React.createElement("span", null, "Website"), /* @__PURE__ */ React.createElement("a", {
    target: "_blank",
    href: props.provider.website
  }, formattedWebsite))), renderIf(props.provider.admin_contact)(/* @__PURE__ */ React.createElement(F, null, /* @__PURE__ */ React.createElement("span", null, "Admin. contact:"), /* @__PURE__ */ React.createElement("span", null, props.provider.admin_contact))), renderIf(props.provider.fee_schedule_name)(/* @__PURE__ */ React.createElement(F, null, /* @__PURE__ */ React.createElement("span", null, "Fee schedule:"), /* @__PURE__ */ React.createElement("span", null, props.provider.fee_schedule_name))), renderIf(props.provider.note && props.provider.note.length > 0)(/* @__PURE__ */ React.createElement(F, null, /* @__PURE__ */ React.createElement("span", null, "Note:"), /* @__PURE__ */ React.createElement("span", null, props.provider.note)))));
};
class ScheduleContent extends Component {
  getProviderName = (provider) => {
    const {group_name, health_club_id} = provider;
    if (group_name.toLowerCase().indexOf("aspen") > -1) {
      return "Aspen Dental";
    }
    if (health_club_id) {
      return provider.location;
    }
    const {first_name, middle_initial, last_name} = provider;
    return `${first_name} ${middle_initial || ""} ${last_name}`;
  };
  renderStudentInfo = (dental_id) => dental_id ? /* @__PURE__ */ React.createElement("section", {
    className: "card important-info fee-schedule"
  }, /* @__PURE__ */ React.createElement("h2", {
    classNamec: "h2"
  }, "IMPORTANT"), /* @__PURE__ */ React.createElement("p", null, "Payment is due at the time of service or you can be charged full price. Discounts may not be combined with any other insurance or offers except at the discretion of the provider."), /* @__PURE__ */ React.createElement("p", null, "Occasionally, a staff member will not be familiar with the", " ", /* @__PURE__ */ React.createElement("strong", null, "BASIX Student Health Discount Program"), ". If there are questions or problems, please reach out to the administrative contact listed above.")) : null;
  renderSchedule = (props) => {
    const {scheduleTableData} = props;
    const {provider} = scheduleTableData;
    const dental_id = provider.dental_id && provider.dental_id;
    return /* @__PURE__ */ React.createElement(FeeSchedule, {
      componentName: "FeeSchedule",
      data: scheduleTableData
    });
  };
  renderPrintAndEmailBtns = (props) => {
    const {
      btnDisabled,
      emailBtnCopy,
      printBtnCopy,
      emailBtnLink,
      printBtnLink,
      btnClassName,
      revealEmailForm,
      printScreen
    } = props;
    const {emailDivClass, onEmailChange, userEmailAddress, children} = props;
    return /* @__PURE__ */ React.createElement("form", {
      className: "save-info",
      onSubmit: (e) => e.preventDefault()
    }, /* @__PURE__ */ React.createElement("fieldset", null, /* @__PURE__ */ React.createElement("label", {
      htmlFor: "#email-input"
    }, "Email Address: "), /* @__PURE__ */ React.createElement("input", {
      className: "mb-0",
      id: "email-input",
      onChange: onEmailChange,
      placeholder: "you@email.com",
      type: "text",
      value: userEmailAddress
    }), /* @__PURE__ */ React.createElement("div", {
      className: "mb-0",
      ariaLive: "polite"
    }, this.props.emailStatusMsg), /* @__PURE__ */ React.createElement("button", {
      className: "btn-link filled mb-0",
      type: "button",
      onClick: this.props.sendEmail
    }, /* @__PURE__ */ React.createElement("img", {
      className: "btn-icon",
      alt: "email icon",
      src: "images/icon/email.svg"
    }), "Email this page"), /* @__PURE__ */ React.createElement("button", {
      className: "btn-link filled",
      type: "button",
      onClick: this.props.printScreen
    }, /* @__PURE__ */ React.createElement("img", {
      className: "btn-icon",
      alt: "phone icon",
      src: "images/icon/printer.svg"
    }), "Print this page")));
  };
  render() {
    const {provider} = this.props.scheduleTableData;
    const {dental_id} = provider;
    const providerName = this.getProviderName(provider);
    return /* @__PURE__ */ React.createElement("div", {
      className: "main-page-container"
    }, /* @__PURE__ */ React.createElement(ProviderData, {
      provider,
      complete: true
    }), this.renderStudentInfo(dental_id), this.renderPrintAndEmailBtns(this.props), this.renderSchedule(this.props));
  }
}
export default withPageLoading("scheduleTableData")(ScheduleContent);
