import React, {Component} from "../../_snowpack/pkg/react.js";
import Ajax from "../utils/ajax.js";
import Analytics from "../utils/GA-methods.js";
import SchoolContent from "../components/SchoolContent.js";
class School extends Component {
  constructor(props) {
    super(props);
    this.state = {
      schoolName: "",
      schoolState: "",
      servicesProps: [],
      headerLink: "/"
    };
    this.buildServicesProps = this.buildServicesProps.bind(this);
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    console.log("proops");
    console.log(this.props);
    console.log(`this.props.location.pathname ${this.props.location.pathname}`);
    const routerURL = this.props.match.params.schoolName;
    Ajax.getSchool(routerURL).then((response) => {
      let responseData = response.data.schoolData;
      this.setState({
        schoolName: responseData.name,
        schoolState: responseData.state,
        broker: responseData.broker,
        headerLink: this.props.location.pathname,
        servicesProps: this.buildServicesProps(responseData)
      }, () => {
        let GTMPayload = Analytics.buildSchoolInfo(responseData);
        Analytics.send("schoolEvent", GTMPayload);
      });
    });
  }
  buildServicesProps(responseData) {
    const serviceStuff = {
      dental: {
        serviceCopy: "dental providers"
      },
      chiro: {
        serviceCopy: "chiropractic professionals"
      },
      eyewear: {
        serviceCopy: "eyewear providers"
      },
      health_club: {
        serviceCopy: "health clubs"
      }
    };
    let name = responseData.name;
    let schoolKey = responseData.key;
    let state = responseData.state;
    let servicesArray = responseData.services;
    let servicesProps = [];
    let serviceCopy = void 0;
    const defaultQueryParams = {
      University_of_New_Hampshire: "?state=NH&city=Portsmouth",
      Northeastern: "?state=MA&city=Boston",
      BYU: "?state=UT&city=Provo",
      Duke: "?state=NC&city=Durham",
      Dartmouth: "?state=NH&city=Hanover"
    };
    for (let service in servicesArray) {
      let serviceName = servicesArray[service];
      if (Object.keys(serviceStuff).indexOf(serviceName) > -1) {
        serviceCopy = serviceStuff[serviceName].serviceCopy;
      }
      servicesProps.push({
        name: `${servicesArray[service]}`,
        state,
        btnLink: `/${schoolKey}/services/${servicesArray[service]}${defaultQueryParams[schoolKey]}`,
        headerCopy: `Find ${serviceCopy} in your area`
      });
    }
    return servicesProps;
  }
  render() {
    const {history} = this.props;
    return /* @__PURE__ */ React.createElement(SchoolContent, {
      componentName: "SchoolContent",
      schoolName: this.props.match.params.schoolName,
      schoolCopy: this.state.schoolCopy,
      servicesProps: this.state.servicesProps,
      broker: this.state.broker,
      headerLink: this.state.headerLink,
      history
    });
  }
}
export default School;
