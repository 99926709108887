const Templates = {
  chiro: {
    _id: 'new',
    first_name: '',
    middle_initial: '',
    last_name: '',
    prof_des: '',
    specialty: '',
    group_name: '',
    location: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    phone: '',
    fax: '',
    school: '',
    fee_shedule: '',
    admin_contact: '',
    note: '',
    fee_schedule_name: '',
    regions: '',
    preferred: false,
    rank: 0,
    ranking_group: '',
    ranking_group_position: 0,
    emails: ['']
  },
  dental: {
    _id: 'new',
    dental_id: 0,
  	first_name: '',
  	middle_initial: '',
  	last_name: '',
  	prof_des: '',
  	specialty: '',
  	group_name: '',
  	location: '',
  	address1: '',
  	address2: '',
  	city: '',
  	state: '',
  	zip: '',
  	phone: '',
  	fax: '',
  	school: '',
  	fee_shedule: '',
  	admin_contact: '',
  	note: '',
  	fee_schedule_name: '',
  	regions: '',
  	preferred: false,
  	rank: 0,
  	ranking_group: '',
  	ranking_group_position: 0,
    emails: ['']
  },
  eyewear: {
    _id: 'new',
    eyewear_id: 0,
  	first_name: '',
  	middle_initial: '',
  	last_name: '',
  	prof_des: '',
  	specialty: '',
  	group_name: '',
  	location: '',
  	address1: '',
  	address2: '',
  	city: '',
  	state: '',
  	zip: '',
  	phone: '',
  	fax: '',
  	school: '',
  	fee_shedule: '',
  	admin_contact: '',
  	note: '',
  	fee_schedule_name: '',
  	regions: '',
  	preferred: false,
  	rank: 0,
  	ranking_group: '',
  	ranking_group_position: 0,
    emails: ['']
  },
  health_club: {
    _id: 'new',
    health_club_id: 0,
    location: '',
    address1: '',
    address2: '',
    city: '',
    state: '',
    zip: '',
    phone: '',
    fax: '',
    regions: '',
    fee_shedule: '',
    preferred: false,
    rank: 0,
    group_name: '',
    ranking_group: '',
    ranking_group_position: 0,
    emails: [''],
    fee_schedule_name: ''
  }
}

export default Templates
