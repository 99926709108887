import axios from "../../_snowpack/pkg/axios.js";
import DOMPurify from "../../_snowpack/pkg/dompurify.js";

//const rootDomain = "http://127.0.0.1:3002";
// const rootDomain = "https://dev.basixstudent.com";
const rootDomain = "";

export default {
  getSchool: function (route) {
    console.log("getting school");
    let URL = `${rootDomain}/${route}/main`;
    URL = DOMPurify.sanitize(URL);
    return axios.get(URL);
  },
  getProviderFilters: {
    bySchool: function (school, service) {
      let URL = `${rootDomain}/${school}/providerFiltersBySchool/${service}`;
      URL = DOMPurify.sanitize(URL);
      return axios.get(URL);
    },
    byState: function (state, service) {
      let URL = `${rootDomain}/${state}/providerFiltersByState/${service}`;
      URL = DOMPurify.sanitize(URL);
      return axios.get(URL);
    },
  },
  queryStateInfo: function (state, service) {
    let URL = `${rootDomain}/${state}/providerFiltersByState/${service}`;
    URL = DOMPurify.sanitize(URL);
    return axios.get(URL);
  },
  queryProviders: function (school, service, queryString) {
    let URL = `${rootDomain}/${school}/providers/${service}${queryString}`;
    return axios.get(URL);
  },
  getProviderAndFeeSchedule: function (schoolName, providerType, providerID) {
    let URL = `${rootDomain}/${schoolName}/providers/${providerType}/${providerID}`;
    URL = DOMPurify.sanitize(URL);
    return axios.get(URL);
  },
  emailSchedule: function (emailBody, emailSubjectLine, targetAddress) {
    let URL = `${rootDomain}/sendEmail`;
    URL = DOMPurify.sanitize(URL);
    emailBody = DOMPurify.sanitize(emailBody);
    emailSubjectLine = DOMPurify.sanitize(emailSubjectLine);
    targetAddress = DOMPurify.sanitize(targetAddress);
    return axios.post(URL, {
      emailBody: emailBody,
      emailSubjectLine: emailSubjectLine,
      targetAddress: targetAddress,
    });
  },
  captchaCheck: function (captchaResponse) {
    return axios.post(`${rootDomain}/captchaVerify`, {
      response: captchaResponse,
    });
  },
  getWaiver: function (schoolName) {
    let URL = DOMPurify.sanitize(`${rootDomain}/getWaiver/${schoolName}`);
    return axios.get(URL);
  },
  logClientIP: function () {
      return axios.post(`${rootDomain}/logIP`);
  },
  getSchoolList: function () {
    console.log("gettin sum schools");
    let URL = DOMPurify.sanitize(`${rootDomain}/getSchoolList/`);
    return axios.get(URL);
  },
  getImage: (path) => {
    const requestUrl = DOMPurify.sanitize(`${rootDomain}/images/${path}`);

    return axios.get(requestUrl);
  },
};
