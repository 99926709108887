import React, {Component} from "../../_snowpack/pkg/react.js";
import {Link} from "../../_snowpack/pkg/react-router-dom.js";
import ProviderList from "../components/ProviderList.js";
import SearchArea from "../components/SearchArea.js";
import Dropdown from "../components/Dropdown.js";
import ReactModal from "../../_snowpack/pkg/react-modal.js";
import {CopyPage, HowToUse} from "../components/CopyPage.js";
import schoolBranding from "../school-branding.js";
import Ajax from "../utils/ajax.js";
import Analytics from "../utils/GA-methods.js";
ReactModal.setAppElement("#root");
class ProviderSearch extends Component {
  constructor(props) {
    super(props);
    this.setHeader = this.setHeader.bind(this);
    this.scrollUp = this.scrollUp.bind(this);
    this.updateQueries = this.updateQueries.bind(this);
    this.buildBtnLink = this.buildBtnLink.bind(this);
    this.buildDropdownOptions = this.buildDropdownOptions.bind(this);
    this.updateDropdownOptions = this.updateDropdownOptions.bind(this);
    this.updateDropdownSelection = this.updateDropdownSelection.bind(this);
    this.getDropdownSelections = this.getDropdownSelections.bind(this);
    this.changeState = this.changeState.bind(this);
    this.parseQueryString = this.parseQueryString.bind(this);
    this.queryProviders = this.queryProviders.bind(this);
    this.autoQuery = this.autoQuery.bind(this);
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.state = {
      showModal: false,
      state: {
        options: [],
        selectedValue: void 0,
        searchBarBootstrapProps: "",
        name: "state",
        searchBarClassname: "state"
      },
      city: {
        options: [],
        selectedValue: void 0,
        searchBarBootstrapProps: "",
        name: "city",
        searchBarClassname: "city"
      },
      specialty: {
        options: [],
        selectedValue: void 0,
        searchBarBootstrapProps: "",
        name: "specialty",
        searchBarClassname: "specialty"
      },
      searchArea: {
        btnWrapperCls: "col",
        onClick: null,
        wrapperCls: "",
        btnClassName: "primary-cta futura",
        btnDisabled: true,
        btnLink: "blank",
        btnCopy: "Search"
      },
      providers: [],
      queryParams: {
        state: void 0,
        city: void 0,
        specialty: void 0
      },
      queryString: void 0,
      queryingProviders: false,
      searchMessage: "",
      searchStatus: "initial page load",
      headerText: "",
      headerLink: "/",
      schoolInfo: {
        schoolName: void 0,
        schoolState: void 0,
        broker: void 0
      },
      scrollButtonDisplay: "hide"
    };
  }
  handleOpenModal() {
    this.setState({showModal: true});
  }
  handleCloseModal() {
    this.setState({showModal: false});
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    let school = this.props.match.params.schoolName;
    let service = this.props.match.params.service;
    Ajax.getSchool(school).then((response) => {
      console.log(response);
      let responseData = response.data.schoolData;
      let schoolStateObj = this.state.schoolInfo;
      let updatedState = Object.assign({}, schoolStateObj, {
        schoolName: responseData.name,
        schoolState: responseData.state,
        broker: responseData.broker
      });
      this.setState({schoolInfo: updatedState});
    });
    this.setHeader(service);
    if (!this.props.location.search) {
      Ajax.getProviderFilters.bySchool(school, service).then((response) => {
        let dropdownOptions = {
          states: response.data[0].state,
          cities: response.data[1].city,
          specialties: response.data[2].specialty
        };
        this.updateDropdownOptions(dropdownOptions).then(() => {
          Promise.all([
            this.updateDropdownSelection("state", {key: response.data[3].state, label: response.data[3].state}, true),
            this.updateDropdownSelection("city", "none"),
            this.updateDropdownSelection("specialty", "none")
          ]);
        });
      });
    }
    if (this.props.location.search) {
      this.autoQuery(this.props.location.search);
    }
    let GTMPayload = Analytics.buildSchoolServiceVisit(this.props.match.params);
    Analytics.send("serviceActivity", GTMPayload);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      this.autoQuery(nextProps.location.search);
    }
  }
  getDropdownSelections(stateSelection, citySelection, specialtySelection) {
    return {
      state: stateSelection,
      city: citySelection,
      specialty: specialtySelection
    };
  }
  updateDropdownOptions(dropdownOptions) {
    console.log("wat", dropdownOptions);
    return new Promise((resolve, reject) => {
      let newState = this.state.state;
      let newCity = this.state.city;
      let newSpecialty = this.state.specialty;
      newState.options = this.buildDropdownOptions(dropdownOptions.states);
      newCity.options = this.buildDropdownOptions(dropdownOptions.cities);
      newSpecialty.options = this.buildDropdownOptions(dropdownOptions.specialties);
      this.setState({
        state: newState,
        city: newCity,
        specialty: newSpecialty
      }, () => {
        resolve();
      });
    });
  }
  buildDropdownOptions(optionsArray) {
    let options = [];
    if (optionsArray.length === 0) {
      let optionObj = {};
      optionObj.key = "";
      optionObj.label = "No filters for this category";
      options.push(optionObj);
      return options;
    } else if (optionsArray[0] === "getting new options") {
      return [];
    } else {
      for (let option in optionsArray) {
        let optionObj = {};
        optionObj.key = encodeURIComponent(optionsArray[option]);
        optionObj.label = optionsArray[option];
        options.push(optionObj);
      }
      return options;
    }
  }
  updateDropdownSelection(dropdown, selection, dontUpdateOtherDropdownOptions) {
    return new Promise((resolve, reject) => {
      let newDropdownState = this.state[dropdown];
      if (dropdown === "state" && !dontUpdateOtherDropdownOptions) {
        this.changeState(selection).then((newDropdownOptions) => {
          this.updateDropdownOptions(newDropdownOptions).then(() => {
            Promise.all([
              this.updateDropdownSelection("state", selection, true),
              this.updateDropdownSelection("city", "none"),
              this.updateDropdownSelection("specialty", "none")
            ]).then(() => {
              resolve();
            });
          });
        });
      } else {
        if (selection !== "none" && selection.key !== void 0) {
          newDropdownState.selectedValue = selection;
        } else {
          newDropdownState.selectedValue = void 0;
        }
        this.setState({
          [dropdown]: newDropdownState
        }, () => {
          this.updateQueries(this.state[dropdown].name, this.state[dropdown].selectedValue).then(() => {
            this.buildBtnLink(this.state.queryParams);
          });
        });
      }
    });
  }
  changeState(selection) {
    return new Promise((resolve, reject) => {
      let state = selection.key;
      let service = this.props.match.params.service;
      this.updateDropdownOptions({
        states: ["getting new options"],
        cities: ["getting new options"],
        specialties: ["getting new options"]
      }).then(() => {
        Ajax.getProviderFilters.byState(state, service).then((response) => {
          if (response) {
            let newDropdownOptions = {
              states: response.data[0].state,
              cities: response.data[1].city,
              specialties: response.data[2].specialty
            };
            resolve(newDropdownOptions);
          } else {
            reject("error when querying for new dropdown options");
          }
        });
      });
    });
  }
  parseQueryString(queryString) {
    return new Promise((resolve, reject) => {
      let params = {
        state: {label: void 0, key: void 0},
        city: {label: void 0, key: void 0},
        specialty: {label: void 0, key: void 0}
      };
      const stateRegEx = /(?:state=)([a-zA-Z%0-9]*)(?:&|$)/;
      const cityRegEx = /(?:city=)([a-zA-Z%0-9]*)(?:&|$)/;
      const specialtyRegEx = /(?:specialty=)([a-zA-Z%0-9]*)(?:&|$)/;
      if (queryString.match(stateRegEx)) {
        params.state.label = decodeURIComponent(queryString.match(stateRegEx)[1]);
        params.state.key = queryString.match(stateRegEx)[1];
      }
      if (queryString.match(cityRegEx)) {
        params.city.label = decodeURIComponent(queryString.match(cityRegEx)[1]);
        params.city.key = queryString.match(cityRegEx)[1];
      }
      if (queryString.match(specialtyRegEx)) {
        params.specialty.label = decodeURIComponent(queryString.match(specialtyRegEx)[1]);
        params.specialty.key = queryString.match(specialtyRegEx)[1];
      }
      resolve(params);
    });
  }
  updateQueries(dropdown, selection) {
    return new Promise((resolve, reject) => {
      if (selection !== "") {
        let paramSelection = selection;
        let newQueryParams = this.state.queryParams;
        newQueryParams[dropdown] = paramSelection;
        this.setState({
          queryParams: newQueryParams
        }, () => {
          resolve(this.state.queryParams);
        });
      } else {
        resolve(this.state.queryParams);
      }
    });
  }
  buildBtnLink(queryParams) {
    const btnLink = Object.keys(queryParams).map((param, i) => {
      if (queryParams[param] && queryParams[param].key) {
        const searchCategory = param;
        const searchTerm = queryParams[param].key;
        const searchParam = `${searchCategory}=${searchTerm}`;
        return i === 0 ? `?${searchParam}` : `&${searchParam}`;
      }
    }).join("");
    let newSearchArea = this.state.searchArea;
    newSearchArea.btnLink = btnLink;
    newSearchArea.btnDisabled = false;
    this.setState({
      searchArea: newSearchArea,
      queryString: btnLink
    });
  }
  queryProviders() {
    if (this.state.queryString === "?" || !this.state.queryString) {
      this.setState({
        searchStatus: "failed",
        searchMessage: "Please select a state from the dropdown menus before searching.",
        queryProviders: false,
        providers: []
      });
    } else {
      let school = this.props.match.params.schoolName;
      let service = this.props.match.params.service;
      let queryString = this.state.queryString;
      this.setState({
        queryingProviders: true,
        providers: []
      });
      return Ajax.queryProviders(school, service, queryString).then((result) => {
        if (result.status === 200) {
          this.setState({
            providers: result.data,
            queryingProviders: false,
            searchMessage: "",
            searchStatus: "success"
          });
        }
        if (result.status === 204) {
          this.setState({
            providers: [],
            queryingProviders: false,
            searchMessage: "Sorry, there weren't any results for your search terms. Try a different search.",
            searchStatus: "failed"
          });
        }
      }).then(() => {
        let searchParams = this.state.queryParams;
        let service2 = this.props.match.params.service;
        let GTMPayload = Analytics.buildSearchParamInfo(searchParams, service2);
        Analytics.send("searchActivity", GTMPayload);
      });
    }
  }
  setHeader(service) {
    switch (service) {
      case "dental":
        this.setState({headerText: "Dental Services"});
        break;
      case "chiro":
        this.setState({headerText: "Chiropractic Services"});
        break;
      case "eyewear":
        this.setState({headerText: "Eyewear Services"});
        break;
      case "health_club":
        this.setState({headerText: "Health Club Services"});
        break;
    }
  }
  autoQuery(queryString) {
    this.setState({
      queryString
    }, () => {
      let school = this.props.match.params.schoolName;
      let service = this.props.match.params.service;
      this.queryProviders(school, service, this.state.queryString);
      this.parseQueryString(this.state.queryString).then((preselectedValues) => {
        Ajax.getProviderFilters.byState(preselectedValues.state.key, this.props.match.params.service).then((response) => {
          let dropdownOptions = {
            states: response.data[0].state,
            cities: response.data[1].city,
            specialties: response.data[2].specialty
          };
          this.updateDropdownOptions(dropdownOptions).then(() => {
            Promise.all([
              this.updateDropdownSelection("state", preselectedValues.state, true),
              this.updateDropdownSelection("city", preselectedValues.city),
              this.updateDropdownSelection("specialty", preselectedValues.specialty)
            ]);
          });
        });
      });
    });
  }
  scrollUp() {
    window.scrollTo(0, 0);
  }
  render() {
    const {history} = this.props;
    const renderSearchArea = () => {
      return /* @__PURE__ */ React.createElement("details", {
        className: "search-options"
      }, /* @__PURE__ */ React.createElement("summary", {
        className: "search-options-btn"
      }, /* @__PURE__ */ React.createElement("span", null, "Search options"), /* @__PURE__ */ React.createElement("img", {
        alt: "icon",
        src: "/images/icon/details.svg"
      })), /* @__PURE__ */ React.createElement(SearchArea, {
        searchArea: {
          ...this.state.searchArea,
          aria: {"aria-controls": "provider-list"}
        }
      }, /* @__PURE__ */ React.createElement(Dropdown, {
        dropdown: this.state.state,
        label: "State",
        onChangeSelection: this.updateDropdownSelection
      }), /* @__PURE__ */ React.createElement(Dropdown, {
        dropdown: this.state.city,
        label: "City (optional)",
        onChangeSelection: this.updateDropdownSelection
      }), /* @__PURE__ */ React.createElement(Dropdown, {
        label: "Specialty (optional)",
        dropdown: this.state.specialty,
        onChangeSelection: this.updateDropdownSelection
      })));
    };
    const renderProviderList = () => {
      const {schoolName, service} = this.props.match.params;
      return /* @__PURE__ */ React.createElement(ProviderList, {
        providers: this.state.providers,
        schoolAndService: {schoolName, service},
        queryingProviders: this.state.queryingProviders,
        searchStatus: this.state.searchStatus,
        searchMessage: this.state.searchMessage
      });
    };
    const renderHowToUse = () => {
      return /* @__PURE__ */ React.createElement("button", {
        className: "btn-link filled",
        onClick: this.handleOpenModal
      }, "How to use the program");
    };
    return /* @__PURE__ */ React.createElement("div", {
      className: "main-page-container"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "content-wrap"
    }, renderHowToUse(), renderSearchArea(), renderProviderList()), /* @__PURE__ */ React.createElement(ReactModal, {
      isOpen: this.state.showModal,
      contentLabel: "onRequestClose Example",
      onRequestClose: this.handleCloseModal,
      shouldCloseOnOverlayClick: true
    }, /* @__PURE__ */ React.createElement("section", {
      className: "less-padding"
    }, /* @__PURE__ */ React.createElement(HowToUse, null)), /* @__PURE__ */ React.createElement(Link, {
      className: "btn-link",
      style: {marginBottom: "var(--scale-2)"},
      to: "/how-to-use"
    }, "Click to learn more"), /* @__PURE__ */ React.createElement("button", {
      className: "btn-link no-button",
      onClick: this.handleCloseModal
    }, /* @__PURE__ */ React.createElement("img", {
      className: "btn-icon",
      alt: "close icon",
      src: "/images/icon/close.svg"
    }), "Close")));
  }
}
export default ProviderSearch;
