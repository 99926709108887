import React from "../../_snowpack/pkg/react.js";
const WithBranding = (props) => {
  const kids = props.children && /* @__PURE__ */ React.createElement("div", {
    className: "card main-page"
  }, props.children);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", {
    className: "img-and-card"
  }, /* @__PURE__ */ React.createElement("img", {
    className: "branding-img",
    src: props.img,
    alt: "background image with school logo"
  }), kids));
};
export default WithBranding;
