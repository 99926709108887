import React from "../../../_snowpack/pkg/react.js";
import {useTable, usePagination, useSortBy} from "../../../_snowpack/pkg/react-table.js";
const BrowsingTable = (props) => {
  const columns = React.useMemo(() => props.tableColumns.map((x) => ({Header: x, accessor: x})), props.tableColumns[0]);
  const data = React.useMemo(() => props.tableItems, props.tableItems);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    rows,
    state: {pageIndex, pageSize}
  } = useTable({
    columns,
    data,
    initialState: {pageIndex: 2}
  }, useSortBy, usePagination);
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("table", {
    className: "table table-sm table-striped",
    ...getTableProps()
  }, /* @__PURE__ */ React.createElement("thead", null, headerGroups.map((headerGroup) => /* @__PURE__ */ React.createElement("tr", {
    ...headerGroup.getHeaderGroupProps()
  }, headerGroup.headers.map((column) => /* @__PURE__ */ React.createElement("th", {
    ...column.getHeaderProps(column.getSortByToggleProps())
  }, column.render("Header"), /* @__PURE__ */ React.createElement("span", null, column.isSorted ? column.isSortedDesc ? " 🔽" : " 🔼" : "")))))), /* @__PURE__ */ React.createElement("tbody", {
    ...getTableBodyProps()
  }, page.map((row, i) => {
    prepareRow(row);
    return /* @__PURE__ */ React.createElement("tr", {
      ...row.getRowProps()
    }, row.cells.map((cell) => {
      return /* @__PURE__ */ React.createElement("td", {
        ...cell.getCellProps()
      }, cell.render("Cell"));
    }));
  }))), /* @__PURE__ */ React.createElement("div", {
    className: "pagination"
  }, /* @__PURE__ */ React.createElement("button", {
    onClick: () => gotoPage(0),
    disabled: !canPreviousPage
  }, "<<"), " ", /* @__PURE__ */ React.createElement("button", {
    onClick: () => previousPage(),
    disabled: !canPreviousPage
  }, "<"), " ", /* @__PURE__ */ React.createElement("button", {
    onClick: () => nextPage(),
    disabled: !canNextPage
  }, ">"), " ", /* @__PURE__ */ React.createElement("button", {
    onClick: () => gotoPage(pageCount - 1),
    disabled: !canNextPage
  }, ">>"), " ", /* @__PURE__ */ React.createElement("span", null, "Page", " ", /* @__PURE__ */ React.createElement("strong", null, pageIndex + 1, " of ", pageOptions.length), " "), /* @__PURE__ */ React.createElement("span", null, "| Go to page:", " ", /* @__PURE__ */ React.createElement("input", {
    type: "number",
    defaultValue: pageIndex + 1,
    onChange: (e) => {
      const page2 = e.target.value ? Number(e.target.value) - 1 : 0;
      gotoPage(page2);
    },
    style: {width: "100px"}
  })), " ", /* @__PURE__ */ React.createElement("select", {
    value: pageSize,
    onChange: (e) => {
      setPageSize(Number(e.target.value));
    }
  }, [10, 20, 30, 40, 50].map((pageSize2) => /* @__PURE__ */ React.createElement("option", {
    key: pageSize2,
    value: pageSize2
  }, "Show ", pageSize2)))));
};
export default BrowsingTable;
