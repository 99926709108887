import React from "../../../_snowpack/pkg/react.js";
import Actions from "./actions.js";
import Store from "./store.js";
import ResultList from "./ResultList.js";
import BrowseProviders from "./Components/BrowseProviders.js";
import CreateProvider from "./Components/CreateProvider.js";
import SearchProviders from "./Components/SearchProviders.js";
import ExportProviders from "./Components/ExportProviders.js";
import DropdownOptions from "./dropdownFieldOptions.js";
class ProviderArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = Store.getState();
    this.checkForCompleteSearchParams = this.checkForCompleteSearchParams.bind(this);
  }
  componentDidMount() {
    this.unsubscribeStore = Store.subscribe(this.onStoreChange.bind(this));
    this.setState({
      dbOptions: [
        {key: "dental", label: "Dental"},
        {key: "health_club", label: "Health Club"},
        {key: "eyewear", label: "Eyewear"},
        {key: "chiro", label: "Chiro"}
      ],
      selectedDB: ""
    });
  }
  onStoreChange() {
    this.setState(Store.getState());
  }
  componentWillUnmount() {
    this.unsubscribeStore();
  }
  componentWillUpdate(nextProps, nextState) {
    if (nextState.providers) {
      if (nextState.providers.errorMessage === "missing state or city info") {
        alert("The city and/or state info for your provider entry wasn't specified or is incorrect, please check the city and state fields for errors. State info should be entered as a two character abbreviation (e.g. UT, NY).");
        Actions.resetErrorMessage();
      }
    }
  }
  checkForCompleteSearchParams(paramObj) {
    for (let key in paramObj) {
      if (paramObj.hasOwnProperty(key)) {
        if (paramObj[key] === "") {
          alert("Please enter all search terms");
          return false;
        }
      }
    }
    return true;
  }
  render() {
    return /* @__PURE__ */ React.createElement("section", {
      className: "section-home container"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "row"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "col-sm-12"
    }, /* @__PURE__ */ React.createElement("h1", {
      className: "page-header"
    }, "Providers"), /* @__PURE__ */ React.createElement(SearchProviders, {
      dbOptions: this.state.dbOptions,
      dropdownOptions: DropdownOptions
    }), /* @__PURE__ */ React.createElement(ExportProviders, {
      dbOptions: this.state.dbOptions,
      dropdownOptions: DropdownOptions
    }), /* @__PURE__ */ React.createElement(BrowseProviders, {
      dbOptions: this.state.dbOptions,
      browsingColumnOptions: DropdownOptions
    }), /* @__PURE__ */ React.createElement(CreateProvider, {
      dbOptions: this.state.dbOptions,
      dropdownOptions: DropdownOptions
    }), /* @__PURE__ */ React.createElement("div", {
      className: "row"
    }, /* @__PURE__ */ React.createElement(ResultList, {
      providers: this.state.providers,
      db: this.state.providers.selectedDB
    })))));
  }
}
export default ProviderArea;
