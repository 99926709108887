import React from "../../_snowpack/pkg/react.js";
import schoolBranding from "../school-branding.js";
import {Link} from "../../_snowpack/pkg/react-router-dom.js";
const SchoolContent = (props) => {
  const renderServices = () => {
    const cta = {
      dental: {
        img: "/images/btn-bg/pair.png",
        copy: "Search Dental Providers"
      },
      health_club: {
        img: "/images/btn-bg/sitting.png",
        copy: "Search Health Clubs"
      },
      chiro: {
        img: "/images/btn-bg/mental-health.png",
        copy: "Search Chiropractors"
      },
      eyewear: {
        img: "/images/btn-bg/glasses.png",
        copy: "Search Vision Providers"
      }
    };
    const buttons = props.servicesProps.map((service, index) => {
      console.log(service);
      const serviceName = cta[service.name].copy;
      const img = cta[service.name].img;
      return /* @__PURE__ */ React.createElement("li", {
        className: "btn-card",
        key: index
      }, /* @__PURE__ */ React.createElement("img", {
        className: "btn-img",
        src: img
      }), /* @__PURE__ */ React.createElement(Link, {
        className: "btn-link",
        to: service.btnLink
      }, serviceName));
    });
    return /* @__PURE__ */ React.createElement("section", {
      className: "service-list"
    }, /* @__PURE__ */ React.createElement("h2", {
      className: "h2 text-center"
    }, "Available Services"), /* @__PURE__ */ React.createElement("nav", null, /* @__PURE__ */ React.createElement("ul", {
      className: "no-padding"
    }, buttons)));
  };
  return /* @__PURE__ */ React.createElement("div", {
    className: "main-page-container"
  }, renderServices());
};
export default SchoolContent;
