export default {
  export: function(scheduleToExport, opts, Store, Constants) {
    this.parseSchedule(scheduleToExport, opts)
    .then((parsedScheduleArray) => {
      this.stringify(parsedScheduleArray)
      .then((stringifiedScheduleArray) => {
        Store.dispatch({
          type: Constants.GENERATE_SCHEDULE_EXPORT_RESPONSE,
          scheduleExportString: stringifiedScheduleArray,
          scheduleExportFilename: `${scheduleToExport.scheduleName}.tsv`
        })
      })
    })
  },
  stringify: function(arrayOfLines){
    return new Promise((resolve, reject) => {
      let lines = arrayOfLines.map((lineArray, index) => {
        let line = lineArray.join("\t");
        return (index == 0 ? "data:text/tsv;charset=utf-8," + line : line);
      });
      resolve(lines.join("\n"))
    })
  },

  parseSchedule: function(schedule, opts) {
    return new Promise((resolve, reject) => {
      let tsvLines = [
        ['']
      ]
      if (opts.printScheduleName) {
        tsvLines.push([schedule.scheduleName])
        tsvLines.push([''])
      }
      if (opts.printColumnHeaders) {
        tsvLines.push([ schedule.columnHeaders.first,
                        schedule.columnHeaders.second,
                        schedule.columnHeaders.third  ])
        tsvLines.push([''])
      }
      if (schedule.services && opts.printServices) {
        if (schedule.services.length > 0) {
          schedule.services.forEach(service => {
            tsvLines.push(['', service.serviceName, ''])
            service.codes.forEach(code => {
              tsvLines.push([ code.code,
                              code.details.description,
                              code.details.price ])
            })
          })
        }
      }
      if (schedule.nonTabularInfo && opts.printNonTabularInfo) {
        if (schedule.nonTabularInfo.lines.length > 0) {
          tsvLines.push(['', 'FREEFORM INFO', ''])

          schedule.nonTabularInfo.lines.forEach(line => {
            if (opts.writeNonTabularInfoWithHtmlInfo) {
              tsvLines.push([ line.tagName,
                              line.text,
                              line.className])
            }
            if (opts.writeNonTabularTextOnly) {
              tsvLines.push(['', line.text, ''])
            }
          })
        }
      }
      resolve(tsvLines);
    })
  }
}
