import React, {Fragment} from "../../_snowpack/pkg/react.js";
import withPageLoading from "../HOC/withPageLoading.js";
import InsightPayPalForm from "./Insight.js";
import DOMPurify from "../../_snowpack/pkg/dompurify.js";
const ServiceRow = (props) => {
  const {codes, serviceName} = props.service;
  const description = props.service && props.service.description;
  const renderDescription = (description2) => {
    const renderDescriptionLine = (description3) => description3.map((line) => line);
    if (description2) {
      return /* @__PURE__ */ React.createElement("thead", null, /* @__PURE__ */ React.createElement("tr", null, /* @__PURE__ */ React.createElement("th", null), /* @__PURE__ */ React.createElement("th", {
        id: description2
      }, renderDescriptionLine(description2)), /* @__PURE__ */ React.createElement("th", null)));
    }
    return null;
  };
  const renderCodes = (codes2) => codes2.map((code) => /* @__PURE__ */ React.createElement(CodeRow, {
    code,
    subheaderId: serviceName
  }));
  const spacer = /* @__PURE__ */ React.createElement("tr", {
    className: "spacer-row",
    "aria-hidden": "true"
  }, /* @__PURE__ */ React.createElement("td", null, /* @__PURE__ */ React.createElement("span", {
    style: {visibility: "hidden"}
  })), /* @__PURE__ */ React.createElement("td", null), /* @__PURE__ */ React.createElement("td", null));
  return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("tbody", null, /* @__PURE__ */ React.createElement("tr", {
    colSpan: 3,
    scope: "colgroup"
  }, /* @__PURE__ */ React.createElement("th", {
    colSpan: "3",
    id: serviceName
  }, serviceName)), renderDescription(description), renderCodes(codes), spacer));
};
const CodeRow = (props) => {
  const {subheaderId} = props;
  const rowData = props.code;
  const {code, details} = rowData;
  const {description, price} = details;
  return /* @__PURE__ */ React.createElement("tr", null, /* @__PURE__ */ React.createElement("td", {
    headers: `${subheaderId} ada-code`
  }, code), /* @__PURE__ */ React.createElement("td", {
    headers: `${subheaderId} description`
  }, description), /* @__PURE__ */ React.createElement("td", {
    headers: `${subheaderId} price`
  }, price));
};
const NonTabularInfo = ({html}) => {
  const createSafeMarkup = (html2) => {
    return {__html: DOMPurify.sanitize(html2)};
  };
  const renderNonTabularHtml = (html2) => {
    return /* @__PURE__ */ React.createElement("div", {
      dangerouslySetInnerHTML: createSafeMarkup(html2)
    });
  };
  return renderNonTabularHtml(html);
};
const NonTabularInfoOnly = (props) => {
  const {html, scheduleName} = props;
  const renderHtml = (html2) => {
    if (!html2) {
      return null;
    }
    return /* @__PURE__ */ React.createElement(NonTabularInfo, {
      html: html2
    });
  };
  const renderPayPal = (scheduleName2) => {
    switch (scheduleName2) {
      case "ut_byuinsight":
        return /* @__PURE__ */ React.createElement(InsightPayPalForm, null);
        break;
      default:
        return null;
    }
  };
  return /* @__PURE__ */ React.createElement("div", {
    id: "schedule"
  }, /* @__PURE__ */ React.createElement("h2", null, "Discounted fee schedule"), renderHtml(html), renderPayPal(scheduleName));
};
const HealthClub = (props) => {
  const {paragraphs} = props;
  const renderListItems = (listItems) => {
    return listItems.map((item, index) => {
      return /* @__PURE__ */ React.createElement("li", {
        key: index
      }, item);
    });
  };
  const renderParagraphs = (paragraphs2) => {
    return paragraphs2.map((paragraph) => {
      const listItems = paragraph.listItems;
      if (listItems.length === 0) {
        return /* @__PURE__ */ React.createElement("p", null, paragraph.text);
      } else {
        const {listItemStyle, text} = paragraph;
        const ListComponent = listItemStyle === "unordered" ? "li" : "ul";
        return /* @__PURE__ */ React.createElement(Fragment, null, /* @__PURE__ */ React.createElement("p", null, text), /* @__PURE__ */ React.createElement(ListComponent, null, renderListItems(paragraph.listItems)));
      }
    });
  };
  return /* @__PURE__ */ React.createElement("div", null, renderParagraphs(paragraphs));
};
const ScheduleTable = (props) => {
  const {feeSchedule, scheduleName} = props;
  const {columnHeaders, services, nonTabularInfo} = feeSchedule;
  const html = nonTabularInfo && nonTabularInfo.html && nonTabularInfo.html;
  const renderServices = (services2) => {
    if (services2) {
      return services2.map((service) => /* @__PURE__ */ React.createElement(ServiceRow, {
        service
      }));
    }
    return null;
  };
  const renderNonTabularInfo = (html2) => {
    if (html2) {
      return /* @__PURE__ */ React.createElement("tbody", null, /* @__PURE__ */ React.createElement("tr", null, /* @__PURE__ */ React.createElement("td", {
        colspan: "3",
        style: {padding: "var(--size-2)"}
      }, "Additional Info: ", /* @__PURE__ */ React.createElement(NonTabularInfo, {
        html: html2
      }))));
    }
    return null;
  };
  return /* @__PURE__ */ React.createElement("section", null, /* @__PURE__ */ React.createElement("h2", {
    className: "h2",
    style: {marginLeft: "var(--size-2)"}
  }, "Discounted fee schedule"), /* @__PURE__ */ React.createElement("table", {
    id: "schedule",
    className: "schedule-table"
  }, /* @__PURE__ */ React.createElement("thead", null, /* @__PURE__ */ React.createElement("tr", null, /* @__PURE__ */ React.createElement("th", {
    scope: "col",
    id: "ada-code"
  }, columnHeaders.first), /* @__PURE__ */ React.createElement("th", {
    scope: "col",
    id: "description"
  }, columnHeaders.second), /* @__PURE__ */ React.createElement("th", {
    scope: "col",
    id: "price"
  }, "Discount Price"))), renderServices(services), renderNonTabularInfo(html)));
};
const FeeSchedule = (props) => {
  const {provider, feeSchedule} = props.data;
  const {health_club_id} = provider;
  const {services, nonTabularInfo, scheduleName} = feeSchedule;
  const html = nonTabularInfo && nonTabularInfo.html;
  const isHealthClub = health_club_id;
  const isTabularSchedule = services && services.length > 0;
  const isNonTabularSchedule = services && services.length === 0 && html;
  if (isHealthClub || isNonTabularSchedule) {
    return /* @__PURE__ */ React.createElement(NonTabularInfoOnly, {
      scheduleName,
      html
    });
  }
  if (isTabularSchedule) {
    return /* @__PURE__ */ React.createElement(ScheduleTable, {
      scheduleName,
      feeSchedule
    });
  }
  return null;
};
export default withPageLoading("data")(FeeSchedule);
