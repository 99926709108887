import React, {Component} from "../../../../../_snowpack/pkg/react.js";
import Actions from "../../actions.js";
import Store from "../../store.js";
import BrowsingTable from "../../../SharedComponents/BrowsingTable.js";
class BrowseSchedules extends Component {
  constructor(props) {
    super(props);
    let store = Store.getState();
    this.state = {
      feeSchedules: store.feeSchedules,
      hideBrowseSchedulesBtn: "hidden",
      browseSchedulesRow: "hidden"
    };
    this.getBrowsingList = this.getBrowsingList.bind(this);
    this.clearBrowsingList = this.clearBrowsingList.bind(this);
    this.showHideRowAndButton = this.showHideRowAndButton.bind(this);
  }
  componentDidMount() {
    this.unsubscribeStore = Store.subscribe(this.onStoreChange.bind(this));
  }
  onStoreChange() {
    this.setState(Store.getState());
  }
  componentWillUnmount() {
    this.unsubscribeStore();
  }
  getBrowsingList(e) {
    e.preventDefault();
    Actions.getBrowsingList();
  }
  clearBrowsingList(e) {
    e.preventDefault();
    Actions.clearBrowsingList();
  }
  showHideRowAndButton(e, toggleType, rowName, btnName) {
    e.preventDefault();
    this.setState({
      [rowName]: toggleType,
      [btnName]: toggleType
    });
  }
  renderTable = () => {
    if (this.state.feeSchedules.scheduleBrowsingList.length < 1) {
      return null;
    }
    console.log("we have data", this.state.feeSchedules);
    return /* @__PURE__ */ React.createElement(BrowsingTable, {
      tableItems: this.state.feeSchedules.scheduleBrowsingList,
      tableColumns: ["_id", "scheduleName"]
    });
  };
  render() {
    return /* @__PURE__ */ React.createElement("div", {
      className: "row margin subSectionPadding"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "col-xs-12"
    }, /* @__PURE__ */ React.createElement("h2", null, "Browse schedule entries")), /* @__PURE__ */ React.createElement("form", {
      className: "margin col-xs-12"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "form-group"
    }, /* @__PURE__ */ React.createElement("label", {
      className: ""
    }, "Browse all entries"), /* @__PURE__ */ React.createElement("button", {
      className: "browse btn btn-primary",
      onClick: (e) => {
        this.getBrowsingList(e);
        this.showHideRowAndButton(e, "show", "browseSchedulesRow", "hideBrowseSchedulesBtn");
      }
    }, "Browse"), /* @__PURE__ */ React.createElement("button", {
      className: this.state.hideBrowseSchedulesBtn + " browse btn btn-primary",
      onClick: (e) => {
        this.clearBrowsingList(e);
        this.showHideRowAndButton(e, "hide", "browseSchedulesRow", "hideBrowseSchedulesBtn");
      }
    }, " Hide "))), /* @__PURE__ */ React.createElement("div", {
      className: this.state.browseSchedulesRow + " col-xs-12"
    }, this.renderTable()));
  }
}
export default BrowseSchedules;
