import Constants from '../constants.js'
import ObjectAssign from '../../../../_snowpack/pkg/object-assign.js'
import cloneDeep from '../../../../_snowpack/pkg/lodash.clonedeep.js'
import papaParse from '../../../../_snowpack/pkg/papaparse.js'

import Templates from '../providerTemplates.js'

const initialState = {
  searchResults: [],
  providerChanges: [],
  newProvider: {},
  newProviderChanges: {},
  providerBrowsingList: []
};

const reducer = function (state = initialState, action) {

  if (action.response) {
    if (action.response.message) {
      handleError(action.response.message)
    }
  }

  if (action.type === Constants.CLEAR_SEARCH_AND_CHANGES) {
    return ObjectAssign({}, state, {
      searchResults: [],
      providerChanges: [],
      newProvider: {},
      newProviderChanges: {}
    });
  }

  if (action.type === Constants.GET_PROVIDERS_RESPONSE) {
    let providersResponse = []
    if (action.response.providers) {
      providersResponse = action.response.providers
    }

    if (Object.keys(state.newProvider).length > 0 || Object.keys(state.newProviderChanges).length > 0) {
      if (confirm('Do you want to search for existing providers? Any unsaved changes will be lost')) {
        return ObjectAssign({}, state, {
          searchResults: providersResponse,
          providerChanges: providersResponse,
          selectedDB: action.response.db,
          newProvider: {},
          newProviderChanges: {}
        });
      }
    } else {
      return ObjectAssign({}, state, {
        searchResults: providersResponse,
        providerChanges: providersResponse,
        selectedDB: action.response.db,
        newProvider: {},
        newProviderChanges: {}
      });
    }
  }

  if (action.type === Constants.GET_PROVIDERS_LIST_RESPONSE) {
    return ObjectAssign({}, state, {
      providerBrowsingList: action.response.providerList,
      tableColumns: action.response.tableColumns
    });
  }

  if (action.type === Constants.CLEAR_PROVIDERS_LIST) {
    return ObjectAssign({}, state, {
      providerBrowsingList: []
    });
  }

  if (action.type === Constants.REPORT_CHANGE) {

    if (action.change.currentValue._id === 'new') {
      return ObjectAssign({}, state, {
        newProviderChanges: action.change.currentValue
      })
    } else {
      return ObjectAssign({}, state, {
        providerChanges: state.providerChanges.map(provider => {
          if (provider._id === action.change._id) {
            return action.change.currentValue
          } else {
            return provider
          }
        })
      })
    }
  }

  if (action.type === Constants.SEND_DELETION_RESPONSE) {
    if (action.response.deletion === 'success') {
      alert(`Record deleted successfully: ${action.response.recordDeleted}`)
      return Object.assign({}, state, {
        providerChanges: state.providerChanges.filter(deleteItem),
        searchResults: state.searchResults.filter(deleteItem)
      })
    } else if (action.response.deletion === 'failed') {
      alert(`DELETION FAILED: ${action.response.error}`)
    }
    function deleteItem(item) {
      return (item._id !== action.response.recordDeleted)
    }
  }

  if (action.type === Constants.CREATE_NEW_PROVIDER) {
    console.log('creating new provider in 3...2...1');
    console.log(action);
    return createProvider(Templates[action.providerType], action.providerType)
  }

  if (action.type === Constants.CREATE_CLONE_PROVIDER_RESPONSE) {
    console.log('creatin that clone provider');

    let newProviderTemplate = cloneDeep(action.response.providers[0])
    delete newProviderTemplate._id
    newProviderTemplate._id = 'new'
    return createProvider(newProviderTemplate, action.response.db);
  }

  if (action.type === Constants.SEND_UPDATE_RESPONSE) {
    console.log('action.response');
    console.log(action.response);
    if (action.response.save === 'failed') {
      alert(`💣 Provider save UNSUCCESSFUL: ${JSON.stringify(action.response.error)}`)
    }
    if (action.response.save === 'success') {
      alert(`🚀 Provider save successful! Entry ID: ${action.response._id}`)
    }
  }

  if (action.type === Constants.NO_CITY_OR_STATE_ERR) {
    return Object.assign({}, state, {
      errorMessage: 'missing state or city info'
    })
  }

  if (action.type === Constants.RESET_ERRORS) {
    return Object.assign({}, state, {
      errorMessage: ''
    })
  }

  if (action.type === Constants.GET_PROVIDERS_EXPORT_RESPONSE) {
    return createExportDocument(action.response)
  }

  function createProvider(newProviderObject, db) {
    console.log('creating new provider?');
    console.log(newProviderObject);
    if (state.providerChanges.length > 0 || state.searchResults.length > 0) {
      if (confirm('Do you want to create a new provider? Any unsaved changes will be lost')) {
        return Object.assign({}, state, {
          newProvider: newProviderObject,
          newProviderChanges: newProviderObject,
          selectedDB: db,
          providerChanges: [],
          searchResults: []
        })
      }
    } else {
      return Object.assign({}, state, {
        newProvider: newProviderObject,
        newProviderChanges: newProviderObject,
        selectedDB: db
      })
    }
  }

  function createExportDocument(exportData){
    let data = papaParse.unparse({
        fields: exportData.keys,
        data: exportData.providers
      },
      {delimiter: '\t', header: true}
    )

    let dateTime = new Date()
    let filename = `providers_export_${dateTime}.tsv`
    let blob = new Blob([data], {type: 'application/octet-stream'});
    if (typeof window.navigator.msSaveBlob !== 'undefined') {
      // IE workaround
      window.navigator.msSaveBlob(blob, filename);
    }
    else {
      let blobURL = window.URL.createObjectURL(blob);
      let tempLink = document.createElement('a');
      tempLink.style.display = 'none';
      tempLink.href = blobURL;
      tempLink.setAttribute('download', filename);
      // Safari workaround
      if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank');
      }
      document.body.appendChild(tempLink);
      tempLink.click();
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(blobURL);
    }
  }

  function handleError(errorMsg) {
    if (errorMsg === 'no results' || errorMsg === 'providers not found') {
      alert('There were no results for the specified search terms')
      return false
    }
    if (errorMsg === 'error') {
      alert('There was a problem with your search, please check your search terms')
      return false
    }
  }

  return state;
};



export default reducer;
