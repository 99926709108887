import Constants from '../constants.js'
import cloneDeep from '../../../../_snowpack/pkg/lodash.clonedeep.js'
import isEqual from '../../../../_snowpack/pkg/lodash.isequal.js'

const initialState = {
  searchResult: {},
  waiverChanges: {},
  newWaiver: {},
  newWaiverChanges: {}
};

const reducer = function (state = initialState, action) {

  if (action.type === Constants.CLEAR_SEARCH_AND_CHANGES) {
    return Object.assign({}, state, {
      searchResult: {},
      waiverChanges: {},
      newWaiver: {},
      newWaiverChanges: {}
    });
  }


  if (action.type === Constants.GET_WAIVER_LIST_RESPONSE) {
    console.log('hello');
    return Object.assign({}, state, {
      waiverList: action.response.waiverList
    });
  }

  if (action.type === Constants.GET_WAIVER_RESPONSE) {
    return Object.assign({}, state, {
      searchResult: action.response.waiver,
      waiverChanges: action.response.waiver,
      newWaiver: {},
      newWaiverChanges: {}
    });
  }

  if (action.type === Constants.REPORT_CHANGE) {

    function editWaiver(change, scheduleType){
      let waiverUpdate = undefined

      if (scheduleType === 'new') {
        waiverUpdate = cloneDeep(state.newWaiverChanges)

      } else if (scheduleType === 'existing') {
        waiverUpdate = cloneDeep(state.waiverChanges)
      }

      switch (change.dataType) {
        case 'waiverName':
          waiverUpdate.waiverName = change.currentValue.waiverName
        break;
        case 'lines':
          waiverUpdate.lines = change.currentValue
        break;
      }
      return waiverUpdate
    }

    if (action.change._id === 'new') {
      return Object.assign({}, state, {
        newWaiverChanges: editWaiver(action.change, 'new')
      })
    } else {
      return Object.assign({}, state, {
        waiverChanges: editWaiver(action.change, 'existing')
      })
    }
  }

  if (action.type === Constants.SEND_DELETION_RESPONSE) {
    console.log('deleted?');
    if (action.response.deletion === 'success') {
      alert(`record for _id: ${action.response.recordDeleted} deleted`)
      return Object.assign({}, state, {
        waiverChanges: {},
        searchResult: {}
      })
    } else {
      alert(`deletion NOT successful: ${action.response.error}`)
    }
  }

  if (action.type === Constants.CREATE_WAIVER) {
    console.log('creatin waiver');
    const WaiverTemplate = {
      _id: 'new',
      waiverName: '',
      lines: [{
        className: '',
        tagName: 'p',
        text: 'new line'
      }]
    }

    if (!isEqual(state.waiverChanges, state.searchResult)) {
      if (confirm('Do you want to create a new school? Any unsaved changes will be lost')) {
        return Object.assign({}, state, {
          searchResult: {},
          waiverChanges: {},
          newWaiver: WaiverTemplate,
          newWaiverChanges: WaiverTemplate
        })
      }
    } else {
      return Object.assign({}, state, {
        newWaiver: WaiverTemplate,
        newWaiverChanges: WaiverTemplate
      })
    }
  }

  if (action.type === Constants.SEND_WAIVER_UPDATE_RESPONSE) {
    if (action.response.save === 'failed') {
      alert(`💣 Waiver save UNSUCCESSFUL: ${JSON.stringify(action.response.error)}`)
    }
    if (action.response.save === 'success') {
      alert(`🚀 Waiver save successful! Entry ID: ${action.response._id}`)
    }
  }

  if (action.type === Constants.INCOMPLETE_WAIVER_INFO) {
    switch(action.error) {
      case 'no waiver name':
        alert(`You have not filled out the waiver name field, please fill out all fields before saving.`)
      break;
      case 'no lines':
        alert(`To save a waiver it must have at least one line. Please enter a line before saving.`)
      break;
      case 'missing tags':
        alert(`All lines must have an HTML tag associated with them, you are missing tags on line(s) ${action.missingTagIndicies.map(index => ' ' + index)}`)
      break;
    }
  }

  return state;
};


export default reducer;
