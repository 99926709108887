import Constants from './constants.js'
import Store from './store.js'
import axios from '../../../_snowpack/pkg/axios.js'

class Actions {

  static getWaiverList(){
    axios.get('/admin/waivers/list').then(res => {
      Store.dispatch({
        type: Constants.GET_WAIVER_LIST_RESPONSE, 
        response: res.data
      }) 
    });
  }

  static getWaiver(query) {
    axios({
      method: 'post',
      url: '/admin/waivers/getWaiver',
      data: query
    }).then(res => {
      const { data } = res

      Store.dispatch({
        type: Constants.GET_WAIVER_RESPONSE, 
        response: data
      })
    });
  }

  static reportChange(changeObj) {
    Store.dispatch({
      type: Constants.REPORT_CHANGE,
      change: changeObj,
    });
  }

  static writeChange(waiverId){
    let reduxStore = Store.getState()
    let editedWaiver = undefined

    if (waiverId === 'new') {
      editedWaiver = reduxStore.waivers.newWaiverChanges
    } else {
      editedWaiver = reduxStore.waivers.waiverChanges
    }

    // Error handling
    if (!editedWaiver.waiverName) {
      Store.dispatch({
        type: Constants.INCOMPLETE_WAIVER_INFO,
        error: 'no waiver name'
      });
      return false;
    }
    if (editedWaiver.lines.length < 1) {
      Store.dispatch({
        type: Constants.INCOMPLETE_WAIVER_INFO,
        error: 'no lines'
      });
      return false;
    }

    let missingTagIndicies = []
    editedWaiver.lines.forEach((line, index) => {
      if (line.tagName === '' || line.tagName.length < 1) {
        missingTagIndicies.push(index + 1)
      }
    })
    if (missingTagIndicies.length > 0) {
      Store.dispatch({
        type: Constants.INCOMPLETE_WAIVER_INFO,
        error: 'missing tags',
        missingTagIndicies: missingTagIndicies
      });
      return false;
    }

    axios({
      method: 'post',
      url: '/admin/waivers/update/',
      data: {editedWaiver: editedWaiver}
    }).then(res => {
      const { data } = res

      Store.dispatch({
        type: Constants.SEND_WAIVER_UPDATE_RESPONSE, 
        response: data
      })
    })
  }

  static deleteItem(waiverId){

    axios({
      method: 'post',
      url: '/admin/waivers/delete/',
      data: {waiverToDelete: waiverId}
    }).then(res => {
      const { data } = res

      Store.dispatch({
        type: Constants.SEND_DELETION_RESPONSE, 
        response: data
      })
    })
  }

  static createNewWaiver(){
    console.log(Constants.CREATE_WAIVER);
    Store.dispatch({
      type: Constants.CREATE_WAIVER,
      change: {}
    });
  }

  static resetErrorMessage(){
    Store.dispatch({
      type: Constants.RESET_ERRORS,
      change: {}
    });
  }

  static clearSearchAndChanges() {
    return new Promise((resolve, reject) => {
      console.log('hi from clearSearchAndChanges');
      let state = Store.getState()
      console.log('wat');
      console.log(state);
      if (Object.keys(state.waivers.searchResult).length > 0 || Object.keys(state.waivers.newWaiver).length > 0) {
        if (confirm('Would you like to proceed? Any unsaved changes you\'ve made will be lost')) {
          Store.dispatch({
            type: Constants.CLEAR_SEARCH_AND_CHANGES
          })
          resolve()
        }
        reject()
      }
      resolve()
    })
  }
}


export default Actions;
