import React, {Component} from "../../../../_snowpack/pkg/react.js";
class ScheduleName extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return /* @__PURE__ */ React.createElement("tbody", null, /* @__PURE__ */ React.createElement("tr", {
      className: `${this.props.rowClassName} underline`
    }, /* @__PURE__ */ React.createElement("td", null, "Edit?", /* @__PURE__ */ React.createElement("input", {
      type: "checkbox",
      onChange: this.props.toggleCheckBox
    })), /* @__PURE__ */ React.createElement("td", {
      colspan: "1"
    }, /* @__PURE__ */ React.createElement("span", null, /* @__PURE__ */ React.createElement("strong", null, "Schedule Name:"))), /* @__PURE__ */ React.createElement("td", {
      colspan: "3"
    }, this.props.renderValue({tag: "td", className: "field", content: "scheduleName"}))));
  }
}
export default ScheduleName;
