import React from "../../_snowpack/pkg/react.js";
import ProviderData from "./ProviderData.js";
import DOMPurify from "../../_snowpack/pkg/dompurify.js";
const renderName = (providerData) => {
  const {
    first_name,
    middle_initial,
    last_name,
    health_club_id,
    location,
    prof_des
  } = providerData;
  if (first_name) {
    return `${first_name} ${middle_initial} ${last_name} ${prof_des}`;
  }
  if (health_club_id) {
    return `${location}`;
  }
  return null;
};
const renderTitle = (providerData) => {
  const {specialty} = providerData;
  const SPECIALTY = specialty ? `- ${specialty}` : null;
  return /* @__PURE__ */ React.createElement("p", {
    className: "provider-name"
  }, renderName(providerData), " ", SPECIALTY);
};
const renderWebsite = (providerData) => {
  const {website} = providerData;
  if (website) {
    const formattedWebsite = website.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split("/")[0];
    return /* @__PURE__ */ React.createElement("a", {
      href: website,
      target: "_blank"
    }, formattedWebsite);
  }
  return null;
};
const renderPhone = (provider) => {
  if (provider.phone) {
    const formatPhoneNumber = (input) => {
      const regex = /(\d{3})[-\s]?(\d{3})[-\s]?(\d{4})/;
      const match = input.match(regex);
      if (match) {
        const formattedNumber = `(${match[1]}) ${match[2]}-${match[3]}`;
        return formattedNumber;
      } else {
        return input;
      }
    };
    const formattedPhone = formatPhoneNumber(provider.phone);
    return /* @__PURE__ */ React.createElement("p", {
      className: "phone-p"
    }, /* @__PURE__ */ React.createElement("span", {
      className: "bold",
      style: {marginRight: "var(--size-1)"}
    }, "Phone:", " "), /* @__PURE__ */ React.createElement("a", {
      className: "phone-with-icon",
      href: `tel:${provider.phone}`
    }, /* @__PURE__ */ React.createElement("img", {
      style: {marginRight: "var(--size-1)"},
      alt: "phone icon",
      src: "/images/icon/ph.svg"
    }), " ", /* @__PURE__ */ React.createElement("span", null, formattedPhone)));
  }
  return null;
  console.log(provider);
};
const renderAddress = (provider) => {
  const hasAddress1 = provider.address1;
  const hasAddress2 = provider.address2;
  const addr1 = provider.address1 || null;
  const addr2 = hasAddress2 ? `${provider.address2}, ` : "";
  const addr3 = `${provider.city}, ${provider.state} ${provider.zip}`;
  const fullAddr = `${addr1}, ${addr2}${addr3}`;
  return /* @__PURE__ */ React.createElement("p", null, /* @__PURE__ */ React.createElement("span", {
    className: "bold"
  }, "Address: "), /* @__PURE__ */ React.createElement("span", null, fullAddr));
};
const Provider = (props) => {
  const {providerData, service, schoolName} = props;
  const {_id} = providerData;
  const btnLink = DOMPurify.sanitize(`#/${schoolName}/providers/${service}/${_id}`);
  return /* @__PURE__ */ React.createElement("li", {
    className: "card darker"
  }, renderTitle(providerData), /* @__PURE__ */ React.createElement("address", null, renderWebsite(providerData), renderPhone(providerData), renderAddress(providerData)), /* @__PURE__ */ React.createElement("a", {
    className: "btn-link",
    target: "_blank",
    href: btnLink
  }, "View provider details"));
};
export default Provider;
