import React, {Component} from "../../../../_snowpack/pkg/react.js";
const renderIf = (predicate) => (element) => predicate && element;
class Headers extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return /* @__PURE__ */ React.createElement("tbody", {
      className: "underline"
    }, /* @__PURE__ */ React.createElement("tr", {
      className: this.props.rowClassName + " headerRow"
    }, /* @__PURE__ */ React.createElement("td", null, "Edit?", /* @__PURE__ */ React.createElement("input", {
      type: "checkbox",
      onChange: this.props.toggleCheckBox
    })), /* @__PURE__ */ React.createElement("td", null, /* @__PURE__ */ React.createElement("strong", null, "Headers:")), /* @__PURE__ */ React.createElement("td", {
      colspan: "3"
    }, this.props.renderValue({tag: "td", name: "firstHeader", content: {0: "columnHeaders", 1: "first"}})), /* @__PURE__ */ React.createElement("td", {
      colspan: "3"
    }, this.props.renderValue({tag: "td", name: "secondHeader", content: {0: "columnHeaders", 1: "second"}})), /* @__PURE__ */ React.createElement("td", {
      colspan: "3"
    }, this.props.renderValue({tag: "td", name: "thirdHeader", content: {0: "columnHeaders", 1: "third"}}))));
  }
}
export default Headers;
