import React, {Component} from "../../../_snowpack/pkg/react.js";
import isEqual from "../../../_snowpack/pkg/lodash.isequal.js";
import ProviderCategory from "./Components/ProviderCategory.js";
class ResultList extends Component {
  constructor(props) {
    super(props);
    this.renderProviderTables = this.renderProviderTables.bind(this);
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.providers !== void 0 && this.props.providers !== void 0) {
      if (!isEqual(nextProps.providers.newProvider, this.props.providers.newProvider)) {
        return true;
      }
      if (!isEqual(nextProps.providers.newProviderChanges, this.props.providers.newProviderChanges)) {
        return true;
      }
      if (!isEqual(nextProps.providers.searchResults, this.props.providers.searchResults)) {
        return true;
      }
      if (!isEqual(nextProps.providers.providerChanges, this.props.providers.providerChanges)) {
        return true;
      }
      return false;
    }
  }
  renderProviderTables() {
    if (this.props.providers !== void 0 && Object.keys(this.props.providers.newProvider).length > 0) {
      return /* @__PURE__ */ React.createElement(ProviderCategory, {
        data: {provider: this.props.providers.newProvider},
        db: this.props.db,
        key: 1
      });
    } else {
      if (this.props.providers.searchResults !== void 0) {
        if (this.props.providers.searchResults.length > 0) {
          return this.props.providers.searchResults.map((provider, tableIndex) => {
            return /* @__PURE__ */ React.createElement("div", {
              className: "margin"
            }, /* @__PURE__ */ React.createElement("div", {
              className: "margin"
            }, /* @__PURE__ */ React.createElement(ProviderCategory, {
              data: {provider},
              db: this.props.db,
              key: provider._id
            })));
          });
        }
      }
    }
  }
  render() {
    return /* @__PURE__ */ React.createElement("div", {
      className: "providerTable col-xs-10 col-xs-offset-1"
    }, this.renderProviderTables());
  }
}
export default ResultList;
