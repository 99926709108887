import React from "../../../_snowpack/pkg/react.js";
import Actions from "./actions.js";
import Store from "./store.js";
import ResultList from "./ResultList.js";
import Select from "../SharedComponents/Select.js";
class SchoolArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = Store.getState();
    this.state.selectedSchool = "";
    this.updateSelection = this.updateSelection.bind(this);
    this.getSchoolList = this.getSchoolList.bind(this);
    this.getSchool = this.getSchool.bind(this);
    this.createNewSchool = this.createNewSchool.bind(this);
  }
  componentDidMount() {
    this.unsubscribeStore = Store.subscribe(this.onStoreChange.bind(this));
    this.getSchoolList();
  }
  onStoreChange() {
    this.setState(Store.getState());
  }
  componentWillUnmount() {
    this.unsubscribeStore();
  }
  componentWillUpdate(nextProps, nextState) {
    if (nextState.schools.schoolList !== this.state.schools.schoolList) {
      console.log("ding ding");
      this.setState({
        dropdownOptions: nextState.schools.schoolList
      });
    }
  }
  updateSelection(selection) {
    this.setState({
      selectedSchool: selection
    });
  }
  updateFieldOptions(db) {
    let options = DropdownOptions[db];
    this.setState({
      searchFieldOptions: options
    });
  }
  getSchoolList() {
    Actions.getSchoolList();
  }
  getSchool() {
    let allParamsHaveBeenSelected = true;
    if (this.state.selectedSchool === "") {
      allParamsHaveBeenSelected = false;
      alert("Please select at least one school from the dropdown menu");
    }
    if (allParamsHaveBeenSelected) {
      Actions.clearSearchAndChanges().then(() => {
        Actions.getSchool({schoolId: this.state.selectedSchool});
      }).catch();
    }
  }
  createNewSchool() {
    Actions.clearSearchAndChanges().then(() => {
      Actions.createNewSchool();
    }).catch();
  }
  render() {
    return /* @__PURE__ */ React.createElement("section", {
      className: "section-home container"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "row"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "col-sm-12"
    }, /* @__PURE__ */ React.createElement("h1", {
      className: "page-header"
    }, "Schools"), /* @__PURE__ */ React.createElement("div", {
      className: "row margin subSectionPadding"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "col-xs-12"
    }, /* @__PURE__ */ React.createElement("h2", null, "Search for existing schools")), /* @__PURE__ */ React.createElement("form", {
      className: "form-inline"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "form-group"
    }, /* @__PURE__ */ React.createElement(Select, {
      options: this.state.dropdownOptions,
      placeholder: "Which school?",
      selectedValue: this.state.selectedSchool,
      updateSelection: this.updateSelection,
      paramType: "field"
    })), /* @__PURE__ */ React.createElement("div", {
      className: "form-group"
    }, /* @__PURE__ */ React.createElement("button", {
      className: "search btn btn-primary",
      onClick: (e) => {
        e.preventDefault();
        this.getSchool();
      }
    }, "Show")), /* @__PURE__ */ React.createElement("div", {
      className: "form-group"
    }, /* @__PURE__ */ React.createElement("button", {
      className: "search btn btn-primary",
      onClick: (e) => {
        e.preventDefault();
        this.getSchoolList();
      }
    }, "Refresh list")))), /* @__PURE__ */ React.createElement("div", {
      className: "row margin subSectionPadding"
    }, /* @__PURE__ */ React.createElement("form", {
      className: "form-inline"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "col-xs-12"
    }, /* @__PURE__ */ React.createElement("h2", null, "Add a new school")), /* @__PURE__ */ React.createElement("div", {
      className: "form-group"
    }, /* @__PURE__ */ React.createElement(Select, {
      options: this.state.dbOptions,
      updateSelection: this.updateNewSchoolDropdownSelection
    })), /* @__PURE__ */ React.createElement("div", {
      className: "form-group"
    }, /* @__PURE__ */ React.createElement("button", {
      className: "btn btn-success",
      onClick: (e) => {
        e.preventDefault();
        this.createNewSchool();
      }
    }, "Create a new school")))), /* @__PURE__ */ React.createElement("div", {
      className: "row"
    }, /* @__PURE__ */ React.createElement(ResultList, {
      schools: this.state.schools,
      searchParams: this.state.searchParams
    })))));
  }
}
export default SchoolArea;
