import React, {Component} from "../../../../_snowpack/pkg/react.js";
const renderIf = (predicate) => (element) => predicate && element;
class ServiceName extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
  }
  render() {
    return /* @__PURE__ */ React.createElement("tbody", null, /* @__PURE__ */ React.createElement("tr", {
      className: `${this.props.rowClassName} underline`
    }, /* @__PURE__ */ React.createElement("td", null, "Edit?", /* @__PURE__ */ React.createElement("input", {
      type: "checkbox",
      onChange: this.props.toggleCheckBox
    })), /* @__PURE__ */ React.createElement("td", {
      colspan: "2"
    }, /* @__PURE__ */ React.createElement("span", null, /* @__PURE__ */ React.createElement("strong", null, "Service Name:"))), /* @__PURE__ */ React.createElement("td", {
      colspan: "2"
    }, this.props.renderValue({tag: "td", className: "field", content: "serviceName"}))));
  }
}
export default ServiceName;
