import React from "../../../_snowpack/pkg/react.js";
import Actions from "./actions.js";
import Store from "./store.js";
import ResultList from "./ResultList.js";
import Select from "../SharedComponents/Select.js";
class WaiverArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = Store.getState();
    this.state.selectedWaiverId = "";
    this.updateSelection = this.updateSelection.bind(this);
    this.getWaiverList = this.getWaiverList.bind(this);
    this.getWaiver = this.getWaiver.bind(this);
    this.createNewWaiver = this.createNewWaiver.bind(this);
  }
  componentDidMount() {
    this.unsubscribeStore = Store.subscribe(this.onStoreChange.bind(this));
    this.getWaiverList();
  }
  onStoreChange() {
    this.setState(Store.getState());
  }
  componentWillUnmount() {
    this.unsubscribeStore();
  }
  componentWillUpdate(nextProps, nextState) {
    if (nextState.waivers.waiverList !== this.state.waivers.waiverList) {
      this.setState({
        dropdownOptions: nextState.waivers.waiverList
      });
    }
  }
  updateSelection(selection) {
    this.setState({
      selectedWaiverId: selection
    });
  }
  updateFieldOptions(db) {
    let options = DropdownOptions[db];
    this.setState({
      searchFieldOptions: options
    });
  }
  getWaiverList() {
    Actions.getWaiverList();
  }
  getWaiver() {
    console.log("getting waiver");
    let allParamsHaveBeenSelected = true;
    if (this.state.selectedWaiverId === "") {
      allParamsHaveBeenSelected = false;
      alert("Please select at least one waiver from the dropdown menu");
    }
    if (allParamsHaveBeenSelected) {
      console.log("this should happen before clearSearchAndChanges");
      Actions.clearSearchAndChanges().then(() => {
        Actions.getWaiver({waiverId: this.state.selectedWaiverId});
      }).catch();
    }
  }
  createNewWaiver() {
    Actions.clearSearchAndChanges().then(() => {
      Actions.createNewWaiver();
    }).catch();
  }
  render() {
    return /* @__PURE__ */ React.createElement("section", {
      className: "section-home container"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "row"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "col-sm-12"
    }, /* @__PURE__ */ React.createElement("h1", {
      className: "page-header"
    }, "Waivers"), /* @__PURE__ */ React.createElement("div", {
      className: "row margin subSectionPadding"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "col-xs-12"
    }, /* @__PURE__ */ React.createElement("h2", null, "Search for existing waivers")), /* @__PURE__ */ React.createElement("form", {
      className: "form-inline"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "form-group"
    }, /* @__PURE__ */ React.createElement(Select, {
      options: this.state.dropdownOptions,
      placeholder: "Which waiver?",
      selectedValue: this.state.selectedWaiverId,
      updateSelection: this.updateSelection,
      paramType: "field"
    })), /* @__PURE__ */ React.createElement("div", {
      className: "form-group"
    }, /* @__PURE__ */ React.createElement("button", {
      className: "search btn btn-primary",
      onClick: (e) => {
        e.preventDefault();
        this.getWaiver();
      }
    }, "Show")), /* @__PURE__ */ React.createElement("div", {
      className: "form-group"
    }, /* @__PURE__ */ React.createElement("button", {
      className: "search btn btn-primary",
      onClick: (e) => {
        e.preventDefault();
        this.getWaiverList();
      }
    }, "Refresh list")))), /* @__PURE__ */ React.createElement("div", {
      className: "row margin subSectionPadding"
    }, /* @__PURE__ */ React.createElement("form", {
      className: "form-inline"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "col-xs-12"
    }, /* @__PURE__ */ React.createElement("h2", null, "Add a new waiver")), /* @__PURE__ */ React.createElement("div", {
      className: "form-group"
    }, /* @__PURE__ */ React.createElement("button", {
      className: "btn btn-success",
      onClick: (e) => {
        e.preventDefault();
        this.createNewWaiver();
      }
    }, "Create")))), /* @__PURE__ */ React.createElement("div", {
      className: "row"
    }, /* @__PURE__ */ React.createElement(ResultList, {
      waivers: this.state.waivers
    })))));
  }
}
export default WaiverArea;
