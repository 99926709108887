import React, {Component} from "../../../_snowpack/pkg/react.js";
import cloneDeep from "../../../_snowpack/pkg/lodash.clonedeep.js";
import debounce from "../../../_snowpack/pkg/lodash.debounce.js";
import isEqual from "../../../_snowpack/pkg/lodash.isequal.js";
import isPlainObject from "../../../_snowpack/pkg/lodash.isplainobject.js";
import shortid from "../../../_snowpack/pkg/shortid.js";
const withCategory = (WrappedComponent) => (ReduxActions) => {
  return class Category extends Component {
    constructor(props) {
      super(props);
      this.state = {
        currentVals: cloneDeep(this.props.data),
        originalVals: cloneDeep(this.props.data),
        keyID: shortid.generate()
      };
      this.addRow = this.addRow.bind(this);
      this.deleteRow = this.deleteRow.bind(this);
      this.updateFieldValue = this.updateFieldValue.bind(this);
      this.updateArrayValue = this.updateArrayValue.bind(this);
      this.pushNewItemToArray = this.pushNewItemToArray.bind(this);
      this.deleteArrayItem = this.deleteArrayItem.bind(this);
      this.renderContent = this.renderContent.bind(this);
      this.renderItem = this.renderItem.bind(this);
      this.renderEmptyList = this.renderEmptyList.bind(this);
      this.reportChange = debounce(this.reportChange.bind(this), 500, {
        trailing: true
      });
    }
    addRow(targetIndex, type) {
      if (targetIndex === -1) {
        targetIndex = 0;
      } else if (targetIndex >= 0) {
        targetIndex += 1;
      }
      let currentValsUpdate = cloneDeep(this.state.currentVals);
      let updateItem = void 0;
      if (type === "code") {
        updateItem = {
          code: "",
          details: {
            description: "",
            price: ""
          },
          keyID: shortid.generate()
        };
      } else if (type === "line") {
        updateItem = {
          text: "",
          className: "",
          tagName: "",
          keyID: shortid.generate()
        };
      }
      currentValsUpdate.splice(targetIndex, 0, updateItem);
      this.setState({
        currentVals: currentValsUpdate
      });
    }
    deleteRow(itemToDelete) {
      if (confirm("Are you sure you want to delete this row?")) {
        let currentValsUpdate = this.state.currentVals;
        this.setState({
          currentVals: currentValsUpdate.filter((item, index) => {
            return index !== itemToDelete.props.itemIndex;
          })
        });
      }
    }
    componentDidUpdate(prevProps, prevState) {
      if (isEqual(this.state.currentVals, prevState.currentVals) === false) {
        if (this.props.dataType === "serviceCategoryItem" || this.props.dataType === "serviceCategoryName" || this.props.dataType === "providerInfo") {
          this.props.reportToParent({
            currentValue: this.state.currentVals,
            dataType: this.props.dataType,
            categoryIndex: this.props.index.serviceIndex
          });
        } else {
          this.reportChange(this.state.currentVals, this.props.dataType, this.props._id);
        }
      }
    }
    shouldComponentUpdate(nextProps, nextState) {
      if (isEqual(nextState.currentVals, this.state.currentVals)) {
        return false;
      }
      return true;
    }
    componentWillReceiveProps(nextProps) {
      if (!isEqual(nextProps.data, this.state.originalVals)) {
        this.setState({
          currentVals: cloneDeep(nextProps.data),
          originalVals: cloneDeep(nextProps.data)
        });
      }
    }
    updateArrayValue(e, arrayName, indexToUpdate) {
      let newState = cloneDeep(this.state.currentVals);
      newState[arrayName] = newState[arrayName].map((item, index) => {
        if (index === indexToUpdate) {
          return e.target.value;
        } else {
          return item;
        }
      });
      this.setState({
        currentVals: newState
      });
    }
    pushNewItemToArray(arrayName) {
      let newState = cloneDeep(this.state.currentVals);
      newState[arrayName].push("");
      this.setState({
        currentVals: newState
      });
    }
    deleteArrayItem(arrayName, deleteIndex) {
      let newState = cloneDeep(this.state.currentVals);
      newState[arrayName] = newState[arrayName].filter((item, index) => {
        return index !== deleteIndex;
      });
      this.setState({
        currentVals: newState
      });
    }
    updateFieldValue(e, propertyToUpdate, rowIndex, categoryIndex) {
      let currentValsUpdate = cloneDeep(this.state.currentVals);
      if (typeof propertyToUpdate === "object") {
        if (Object.keys(this.state.currentVals)[0] === "columnHeaders") {
          currentValsUpdate[propertyToUpdate[0]][propertyToUpdate[1]] = e.target.value;
        } else {
          currentValsUpdate[rowIndex][propertyToUpdate[0]][propertyToUpdate[1]] = e.target.value;
        }
      } else if (rowIndex !== void 0 && Array.isArray(this.state.currentVals)) {
        currentValsUpdate[rowIndex][propertyToUpdate] = e.target.value;
      } else {
        currentValsUpdate[propertyToUpdate] = e.target.value;
      }
      this.setState({
        currentVals: currentValsUpdate
      });
    }
    reportChange(currentVals, dataType, _id) {
      ReduxActions.reportChange({
        currentValue: currentVals,
        dataType,
        _id
      });
    }
    renderItem(item, key, index) {
      let keyID = void 0;
      if (this.props.keyID === void 0) {
        keyID = item.keyID;
      } else {
        keyID = this.props.keyID;
      }
      return /* @__PURE__ */ React.createElement(WrappedComponent, {
        key: keyID,
        childKey: `${keyID}_${index}`,
        content: item,
        itemIndex: index,
        categoryIndex: this.props.index,
        addRow: this.addRow,
        deleteRow: this.deleteRow,
        updateFieldValue: this.updateFieldValue,
        updateArrayValue: this.updateArrayValue,
        pushNewItemToArray: this.pushNewItemToArray,
        deleteArrayItem: this.deleteArrayItem,
        dataType: this.props.dataType,
        empty: false
      });
    }
    renderEmptyList() {
      return /* @__PURE__ */ React.createElement(WrappedComponent, {
        key: "none",
        content: "",
        itemIndex: 0,
        categoryIndex: this.props.index,
        addRow: this.addRow,
        deleteRow: this.deleteRow,
        updateFieldValue: this.updateFieldValue,
        updateArrayValue: this.updateArrayValue,
        pushNewItemToArray: this.pushNewItemToArray,
        deleteArrayItem: this.deleteArrayItem,
        dataType: this.props.dataType,
        empty: true
      });
    }
    renderContent(inputType) {
      const renderString = () => {
        const {originalVals, currentVals} = this.state;
        const keyID = shortid.generate();
        let item = void 0;
        console.log("originalVals");
        console.log(originalVals);
        console.log("currentVals");
        console.log(currentVals);
        if (originalVals === currentVals) {
          item = this.state.originalVals;
        } else {
          item = this.state.currentVals;
        }
        return this.renderItem(item, keyID, void 0);
      };
      if (this.state.currentVals.length < 1) {
        return this.renderEmptyList();
      }
      if (inputType === "string") {
        return renderString();
      }
      if (inputType === "array") {
        if (isEqual(this.state.currentVals, this.state.originalVals)) {
          return this.state.originalVals.map((item, index) => {
            let keyID = shortid.generate();
            return this.renderItem(item, keyID, index);
          });
        } else {
          return this.state.currentVals.map((item, index) => {
            let keyID = shortid.generate();
            return this.renderItem(item, keyID, index);
          });
        }
      }
      if (inputType === "object") {
        let item = void 0;
        if (isEqual(this.state.currentVals, this.state.originalVals)) {
          return Object.keys(this.state.originalVals).map((item2, index) => {
            let keyID = shortid.generate();
            let thing = {};
            thing[item2] = this.state.originalVals[item2];
            return this.renderItem(thing, keyID, index);
          });
        } else {
          return Object.keys(this.state.currentVals).map((item2, index) => {
            let keyID = shortid.generate();
            let thing = {};
            thing[item2] = this.state.currentVals[item2];
            return this.renderItem(thing, keyID, index);
          });
        }
      }
    }
    render() {
      console.log("hey thur");
      if (Array.isArray(this.state.originalVals)) {
        return /* @__PURE__ */ React.createElement("table", {
          className: "table table-sm table-striped"
        }, this.renderContent("array"));
      } else if (isPlainObject(this.state.originalVals)) {
        return /* @__PURE__ */ React.createElement("table", {
          className: "table table-sm table-striped"
        }, this.renderContent("object"));
      } else {
        return /* @__PURE__ */ React.createElement("table", {
          className: "table table-sm table-striped"
        }, this.renderContent("string"));
      }
    }
  };
};
export default withCategory;
