import React, {Component} from "../../../../../_snowpack/pkg/react.js";
import Actions from "../../actions.js";
import Store from "../../store.js";
import Select from "../../../SharedComponents/Select.js";
import Dropzone from "../../../../../_snowpack/pkg/react-dropzone.js";
class CreateSchedule extends Component {
  constructor(props) {
    super(props);
    let store = Store.getState();
    this.state = {
      feeSchedules: store.feeSchedules,
      hideCreateFeeScheduleRowBtn: "hide",
      createFeeScheduleRow: "hide",
      scheduleCloningParams: {
        db: "schedules",
        field: "_id",
        query: ""
      },
      fieldOptions: [
        {key: "_id", label: "ID number"},
        {key: "scheduleName", label: "Schedule name"}
      ]
    };
    this.updateScheduleCloningParams = this.updateScheduleCloningParams.bind(this);
    this.checkForCompleteSearchParams = this.checkForCompleteSearchParams.bind(this);
    this.showHideRowAndButton = this.showHideRowAndButton.bind(this);
    this.createSchedule = this.createSchedule.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }
  componentDidMount() {
    this.unsubscribeStore = Store.subscribe(this.onStoreChange.bind(this));
  }
  onStoreChange() {
    this.setState(Store.getState());
  }
  componentWillUnmount() {
    this.unsubscribeStore();
  }
  showHideRowAndButton(e, toggleType, rowName, btnName) {
    e.preventDefault();
    this.setState({
      [rowName]: toggleType,
      [btnName]: toggleType
    });
  }
  updateScheduleCloningParams(selection, paramType) {
    let scheduleCloningParamsUpdate = this.state.scheduleCloningParams;
    scheduleCloningParamsUpdate[paramType] = selection;
    this.setState({
      scheduleCloningParams: scheduleCloningParamsUpdate
    });
  }
  createSchedule(e, newOrClone) {
    e.preventDefault();
    if (newOrClone === "clone") {
      if (this.checkForCompleteSearchParams(this.state.scheduleCloningParams)) {
        Actions.clearSearchAndChanges().then(() => {
          Actions.createSchedule(newOrClone, this.state.scheduleCloningParams);
        }).catch();
      }
    } else {
      Actions.clearSearchAndChanges().then(() => {
        Actions.createSchedule(newOrClone);
      }).catch();
    }
  }
  checkForCompleteSearchParams(paramObj) {
    for (let key in paramObj) {
      if (paramObj.hasOwnProperty(key)) {
        if (paramObj[key] === "") {
          alert("Please enter all search terms");
          return false;
        }
      }
    }
    return true;
  }
  onDrop(acceptedFiles, rejectedFiles) {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const fileContents = reader.result;
        Actions.handleScheduleImport(fileContents);
      };
      reader.onabort = () => alert("file reading was aborted");
      reader.onerror = () => alert("file reading has failed");
      reader.readAsText(file);
    });
  }
  render() {
    return /* @__PURE__ */ React.createElement("div", {
      className: "row margin subSectionPadding"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "col-xs-12"
    }, /* @__PURE__ */ React.createElement("h2", null, "Add a new schedule")), /* @__PURE__ */ React.createElement("div", {
      className: "col-xs-3"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "row"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "col-xs-12"
    }, /* @__PURE__ */ React.createElement("button", {
      className: "btn btn-primary",
      onClick: (e) => {
        this.showHideRowAndButton(e, "show", "createFeeScheduleRow", "hideCreateFeeScheduleRowBtn");
      }
    }, " Create new schedule"))), /* @__PURE__ */ React.createElement("div", {
      className: "row marginTop"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "col-xs-12"
    }, /* @__PURE__ */ React.createElement("button", {
      className: this.state.hideCreateFeeScheduleRowBtn + " btn btn-warning",
      onClick: (e) => {
        this.showHideRowAndButton(e, "hide", "createFeeScheduleRow", "hideCreateFeeScheduleRowBtn");
      }
    }, " Hide panel")))), /* @__PURE__ */ React.createElement("div", {
      className: "col-xs-8"
    }, /* @__PURE__ */ React.createElement("div", {
      className: this.state.createFeeScheduleRow + " row"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "row subSectionPadding margin"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "col-xs-12"
    }, /* @__PURE__ */ React.createElement("h3", null, "Create a blank fee schedule")), /* @__PURE__ */ React.createElement("div", {
      className: "col-xs-12 margin"
    }, /* @__PURE__ */ React.createElement("button", {
      className: "btn btn-success",
      onClick: (e) => {
        this.createSchedule(e, "new");
      }
    }, "Create"))), /* @__PURE__ */ React.createElement("div", {
      className: "row subSectionPadding margin"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "col-xs-12"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "row"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "col-xs-12"
    }, /* @__PURE__ */ React.createElement("h3", null, "Clone an existing fee schedule"), /* @__PURE__ */ React.createElement("div", {
      className: "row"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "col-xs-12"
    }, "To clone an existing fee schedule, enter in the existing fee schedule's details below, just like if you were searching for that schedule:", /* @__PURE__ */ React.createElement("div", {
      className: "col-xs-12"
    }, /* @__PURE__ */ React.createElement("form", {
      className: "form-inline margin"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "form-group"
    }, /* @__PURE__ */ React.createElement("label", {
      className: ""
    }, "Search field:"), /* @__PURE__ */ React.createElement(Select, {
      options: this.state.fieldOptions,
      placeholder: "Which field?",
      selectedValue: this.state.scheduleCloningParams.field,
      updateSelection: this.updateScheduleCloningParams,
      paramType: "field"
    })), /* @__PURE__ */ React.createElement("div", {
      className: "form-group"
    }, /* @__PURE__ */ React.createElement("label", {
      className: ""
    }, "Search term:"), /* @__PURE__ */ React.createElement("input", {
      placeholder: "Please enter search term",
      className: "form-control inputBox",
      onKeyUp: (e) => {
        this.updateScheduleCloningParams(e.target.value, "query");
      }
    })))))), /* @__PURE__ */ React.createElement("div", {
      className: "row"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "col-xs-12"
    }, /* @__PURE__ */ React.createElement("button", {
      className: "btn btn-success",
      onClick: (e) => {
        this.createSchedule(e, "clone");
      }
    }, "Create"))))))), /* @__PURE__ */ React.createElement("div", {
      className: "row subSectionPadding margin"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "col-xs-12"
    }, /* @__PURE__ */ React.createElement("h3", null, "Import a fee schedule table")), /* @__PURE__ */ React.createElement("div", {
      className: "col-xs-12 margin"
    }, /* @__PURE__ */ React.createElement("p", {
      className: "bg-danger"
    }, "Note:", /* @__PURE__ */ React.createElement("ul", null, /* @__PURE__ */ React.createElement("li", null, /* @__PURE__ */ React.createElement("span", null, "Files must be in TSV format, which is an export option in Excel.")), /* @__PURE__ */ React.createElement("li", null, /* @__PURE__ */ React.createElement("span", null, "Make sure that you include the markers for the sections of your fee schedule table: ", /* @__PURE__ */ React.createElement("strong", null, "#!table"), " goes at the beginning of your table, ", /* @__PURE__ */ React.createElement("strong", null, "#!nonTabular"), " goes at the beginning of a section of free-form text.")))), /* @__PURE__ */ React.createElement(Dropzone, {
      className: "dropzoneOuter",
      onDrop: this.onDrop
    }, () => {
      return /* @__PURE__ */ React.createElement("div", {
        className: "dropzoneInner"
      }, /* @__PURE__ */ React.createElement("p", null, "Click here or drag a file to upload."));
    }))))));
  }
}
export default CreateSchedule;
