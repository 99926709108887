const schoolBranding = {
  BYU: {
    name: "Brigham Young University",
    logo: "/images/school-logos/byu.svg",
    title: "Brigham Young University",
    bgImg: "/images/bg-img/byu.png",
  },
  Dartmouth: {
    name: "Dartmouth College",
    logo: "/images/school-logos/dartmouth.svg",
    title: "Dartmouth College",
    bgColor: "--dartmouth-rich-forest-green",
    bgImg: "/images/bg-img/dartmouth.png",
  },
  Duke: {
    name: "Duke University",
    logo: "/images/school-logos/generic.svg",
    title: "Duke University",
    bgColor: "--duke-dandelion",
    bgImg: "/images/bg-img/duke.png",
  },
  Northeastern: {
    name: "Northeastern University",
    logo: "/images/school-logos/northeastern.svg",
    title: "Northeastern University",
    bgColor: "--northeastern-black",
    bgImg: "/images/bg-img/northeastern.png",
  },
  University_of_New_Hampshire: {
    name: "University of New Hampshire",
    logo: "/images/school-logos/unh.svg",
    title: "University of New Hampshire",
    bgColor: "--unh-white",
    bgImg: "/images/bg-img/unh.png",
  },
};

export default schoolBranding;
