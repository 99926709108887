import React, {Component} from "../../../../_snowpack/pkg/react.js";
import Actions from "../actions.js";
import Select from "../../SharedComponents/Select.js";
const renderIf = (predicate) => (element) => predicate && element;
class ExportProviders extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDB: "dental",
      queries: [
        {
          field: "",
          query: "",
          hasWildCardSettings: false,
          caseSensitive: false,
          caseInsensitive: false
        }
      ],
      exportAll: false,
      searchFieldOptions: this.props.dropdownOptions.dental,
      hideExportProviderRowBtn: "hide",
      exportProvidersRow: "hide"
    };
    this.updateProviderSearchSelection = this.updateProviderSearchSelection.bind(this);
    this.handleCheckBox = this.handleCheckBox.bind(this);
    this.updateFieldOptions = this.updateFieldOptions.bind(this);
    this.updateDBSelection = this.updateDBSelection.bind(this);
    this.exportProviders = this.exportProviders.bind(this);
    this.verifyExportParameters = this.verifyExportParameters.bind(this);
    this.addQueryField = this.addQueryField.bind(this);
    this.removeQueryField = this.removeQueryField.bind(this);
    this.showHideRowAndButton = this.showHideRowAndButton.bind(this);
  }
  updateProviderSearchSelection(selection, paramType, index) {
    if (paramType === "db") {
      this.updateFieldOptions(selection);
      this.updateDBSelection(selection);
    }
    let queriesUpdate = this.state.queries;
    queriesUpdate[index][paramType] = selection;
    this.setState({
      queries: queriesUpdate
    });
  }
  handleCheckBox(e, checkBoxName, index) {
    let queriesUpdate = this.state.queries;
    toggleCheckBox(queriesUpdate, checkBoxName, index).then((queryWithToggledCheckbox) => {
      toggleWildCardSettings(queryWithToggledCheckbox, index).then((completedQueryObject) => {
        this.setState({
          queries: completedQueryObject
        });
      });
    });
    function toggleCheckBox(queriesUpdate2, checkBoxName2, index2) {
      return new Promise((resolve, reject) => {
        if (queriesUpdate2[index2][checkBoxName2] === true) {
          queriesUpdate2[index2][checkBoxName2] = false;
          resolve(queriesUpdate2);
        } else {
          queriesUpdate2[index2][checkBoxName2] = true;
          resolve(queriesUpdate2);
        }
      });
    }
    function toggleWildCardSettings(queriesUpdate2, index2) {
      return new Promise((resolve, reject) => {
        if (queriesUpdate2[index2].caseInsensitive === true || queriesUpdate2[index2].caseSensitive === true) {
          queriesUpdate2[index2].hasWildCardSettings = true;
          resolve(queriesUpdate2);
        } else {
          queriesUpdate2[index2].hasWildCardSettings = false;
          resolve(queriesUpdate2);
        }
      });
    }
  }
  handleExportAllCheckbox() {
    if (this.state.exportAll === true) {
      this.setState({exportAll: false});
    } else {
      this.setState({exportAll: true});
    }
  }
  updateFieldOptions(db) {
    let options = this.props.dropdownOptions[db];
    this.setState({
      searchFieldOptions: options
    });
  }
  updateDBSelection(selection) {
    this.setState({
      selectedDB: selection
    });
  }
  verifyExportParameters() {
    return new Promise((resolve, reject) => {
      if (!this.state.exportAll) {
        let allParamsHaveBeenSelected = true;
        let noConflictingWildCardSelections = true;
        let noConflictingSearchFields = true;
        let queries = this.state.queries;
        queries.forEach((query) => {
          if (query.field === "" || query.query === "") {
            allParamsHaveBeenSelected = false;
          }
          if (query.caseSensitive === true && query.caseInsensitive === true) {
            noConflictingWildCardSelections = false;
          }
        });
        let selectedQueryFields = queries.map((query) => query.field);
        let uniqueQueryFields = new Set(selectedQueryFields);
        if (selectedQueryFields.length !== uniqueQueryFields.size) {
          noConflictingSearchFields = false;
        }
        if (allParamsHaveBeenSelected && noConflictingWildCardSelections && noConflictingSearchFields) {
          resolve();
        }
        if (!allParamsHaveBeenSelected) {
          reject("Please select at least one item from the dropdown menus and indicate a search term");
        }
        if (!noConflictingWildCardSelections) {
          reject('Only one wildcard search setting can be selected, pick either "case sensitive" or "case insensitive"');
        }
        if (!noConflictingSearchFields) {
          reject("Only one search term per field can be selected, please check your search fields.");
        }
      } else {
        resolve();
      }
    });
  }
  exportProviders(e) {
    e.preventDefault();
    this.verifyExportParameters().then(() => {
      Actions.getProviders({
        db: this.state.selectedDB,
        queries: JSON.stringify(this.state.queries),
        exportAll: this.state.exportAll,
        type: "export"
      });
    }).catch((error) => alert(error));
  }
  addQueryField(e) {
    e.preventDefault();
    let queriesUpdate = this.state.queries;
    queriesUpdate.push({
      field: "",
      query: "",
      hasWildCardSettings: false,
      caseSensitive: false,
      caseInsensitive: false
    });
    this.setState({
      queries: queriesUpdate
    });
  }
  removeQueryField(e) {
    e.preventDefault();
    let queriesUpdate = this.state.queries;
    queriesUpdate.pop();
    this.setState({
      queries: queriesUpdate
    });
  }
  showHideRowAndButton(e, toggleType, rowName, btnName) {
    e.preventDefault();
    this.setState({
      [rowName]: toggleType,
      [btnName]: toggleType
    });
  }
  renderSearchFields(index) {
    return this.state.queries.map((query, index2) => {
      return /* @__PURE__ */ React.createElement("form", {
        className: "form-inline",
        key: index2
      }, /* @__PURE__ */ React.createElement("div", {
        className: "row"
      }, /* @__PURE__ */ React.createElement("div", {
        className: "col-xs-12 col-md-6"
      }, /* @__PURE__ */ React.createElement("div", {
        className: "form-group"
      }, /* @__PURE__ */ React.createElement(Select, {
        options: this.state.searchFieldOptions,
        selectedValue: this.state.queries[index2].field,
        placeholder: "Which search field?",
        index: index2,
        updateSelection: this.updateProviderSearchSelection,
        paramType: "field"
      })), /* @__PURE__ */ React.createElement("div", {
        className: "form-group"
      }, /* @__PURE__ */ React.createElement("input", {
        placeholder: "Please enter search term",
        className: "form-control inputBox",
        onKeyUp: (e) => this.updateProviderSearchSelection(e.target.value, "query", index2)
      }))), /* @__PURE__ */ React.createElement("div", {
        className: "col-md-4"
      }, /* @__PURE__ */ React.createElement("div", {
        className: "form-group"
      }, /* @__PURE__ */ React.createElement("label", null, /* @__PURE__ */ React.createElement("span", null, "Not case sensitive"), /* @__PURE__ */ React.createElement("input", {
        type: "checkbox",
        checked: this.state.wildCard,
        onChange: (e) => {
          this.handleCheckBox(e, "caseInsensitive", index2);
        }
      }))), /* @__PURE__ */ React.createElement("div", {
        className: "form-group"
      }, /* @__PURE__ */ React.createElement("label", null, /* @__PURE__ */ React.createElement("span", null, "Case sensitive"), /* @__PURE__ */ React.createElement("input", {
        type: "checkbox",
        checked: this.state.caseSensitive,
        onChange: (e) => {
          this.handleCheckBox(e, "caseSensitive", index2);
        }
      }))))), /* @__PURE__ */ React.createElement("br", null));
    });
  }
  render() {
    return /* @__PURE__ */ React.createElement("div", {
      className: "row margin subSectionPadding"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "col-xs-12"
    }, /* @__PURE__ */ React.createElement("h2", null, "Export provider entries")), /* @__PURE__ */ React.createElement("div", {
      className: "col-xs-12"
    }, /* @__PURE__ */ React.createElement("form", {
      className: "form-inline margin"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "form-group row"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "col-xs-5"
    }, /* @__PURE__ */ React.createElement("button", {
      className: "browse btn btn-primary",
      onClick: (e) => {
        this.showHideRowAndButton(e, "show", "exportProvidersRow", "hideExportProviderRowBtn");
      }
    }, "Show panel")), /* @__PURE__ */ React.createElement("div", {
      className: this.state.hideExportProviderRowBtn + " col-xs-4"
    }, /* @__PURE__ */ React.createElement("button", {
      className: "browse btn btn-warning",
      onClick: (e) => {
        this.showHideRowAndButton(e, "hide", "exportProvidersRow", "hideExportProviderRowBtn");
      }
    }, " Close panel "))))), /* @__PURE__ */ React.createElement("div", {
      className: this.state.exportProvidersRow + " col-xs-12"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "row"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "col-xs-3"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "form-group"
    }, /* @__PURE__ */ React.createElement("label", null, "Which db?"), /* @__PURE__ */ React.createElement(Select, {
      options: this.props.dbOptions,
      placeholder: "Which database?",
      selectedValue: this.state.selectedDB,
      updateSelection: this.updateProviderSearchSelection,
      paramType: "db"
    }))), /* @__PURE__ */ React.createElement("div", {
      className: "col-xs-12"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "row"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "col-xs-6 form-group"
    }, /* @__PURE__ */ React.createElement("strong", null, "Search terms:")), /* @__PURE__ */ React.createElement("div", {
      className: "col-xs-4 form-group"
    }, /* @__PURE__ */ React.createElement("strong", null, "Wildcard settings:"))), this.renderSearchFields()), /* @__PURE__ */ React.createElement("div", {
      className: "col-xs-12",
      style: {marginBottom: "10px"}
    }, /* @__PURE__ */ React.createElement("form", {
      className: "form-inline row"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "form-group col-xs-5"
    }, /* @__PURE__ */ React.createElement("button", {
      className: "btn btn-success btn-sm",
      onClick: (e) => this.addQueryField(e)
    }, "Add query field"), renderIf(this.state.queries.length > 1)(/* @__PURE__ */ React.createElement("button", {
      className: "btn btn-danger btn-sm",
      onClick: (e) => this.removeQueryField(e)
    }, "Remove query field"))))), /* @__PURE__ */ React.createElement("div", {
      className: "col-xs-12"
    }, /* @__PURE__ */ React.createElement("form", {
      className: "form-inline row"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "form-group col-xs-5"
    }, /* @__PURE__ */ React.createElement("label", null, " Export all entries "), /* @__PURE__ */ React.createElement("input", {
      type: "checkbox",
      checked: this.state.exportAll,
      onChange: (e) => {
        this.handleExportAllCheckbox(e);
      }
    }))))), /* @__PURE__ */ React.createElement("form", {
      className: "form-inline row margin"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "form-group col-xs-5"
    }, /* @__PURE__ */ React.createElement("button", {
      className: "search btn btn-primary",
      onClick: (e) => {
        this.exportProviders(e);
      }
    }, "Generate and download export file")))));
  }
}
export default ExportProviders;
