/* eslint-disable strict */
import papaParse from '../../../_snowpack/pkg/papaparse.js'

/* TODO:
  1) refactor this to use papaParse instead of babyParse
  2) get rid of all the FS stuff
  3) hook it up to the admin panel
  5) hook up opts to input delivered via the admin panel
  6) get the schedule name from the file name of whatever is uploaded to the panel
  7) spit the output into the results component
  8) save the newly created file
  9) make a template for michelle and write up rules for importing files
  10) ???
  11) profit
*/

export default {
  parse: function(fileContents){
    return new Promise((resolve, reject) => {
      let opts = {
        hasTable: true,
        hasNonTabularInfo: true,
        nonTabularInfoOnly: false
      }

      if (opts.nonTabularInfoOnly === true) {
        this.parseFile(fileContents, 'nonTabularOnly')
        .then(parsedFile => {
          resolve(parsedFile)
        }).catch(err => {
          console.log('Danger Will Robinson');
          console.log(err);
        })
      }

      if (opts.nonTabularInfoOnly === false) {
        this.parseFile(fileContents, 'tabular')
          .then(parsedFile => {
            this.cleanUp(parsedFile)
              .then(tidyFile => {
                this.splitIntoSections(tidyFile, opts)
                  .then(sections => {
                    this.buildJSON(sections)
                    .then(jsonSchedule => {
                      resolve(jsonSchedule)
                    })
                  })
              })
          }).catch(err => {
            console.log('Danger Will Robinson');
            console.log(err);
          })
      }
    })
  },
  cleanUp: function(fileArray) {
    return new Promise((resolve, reject) => {
      this.removeEmptySpaces(fileArray)
      .then(fileArray => {
        this.removeEmptyLines(fileArray)
        .then(fileArray => {
          resolve(fileArray)
        })
      })
    })
  },
  // JSON object template
  JSONObject: function(scheduleName) {
    this.scheduleName = scheduleName
    this._id = 'new'
    this.columnHeaders = {
      first: "ADA Codes",
      second: "Service Description",
      third: "Fee Amount"
    }
    this.services = []
    this.nonTabularInfo = {
      lines: []
    }
  },
  removeEmptyLines: function(fileArray){
    return new Promise((resolve, reject) => {
      resolve(fileArray.filter(line => {
        return line.length > 0
      }))
    })
  },
  parseFile: function(file, type) {
    console.log('parsing file?');
    return new Promise((resolve, reject) => {
      if (type === 'tabular') {
        papaParse.parse(file, {
          // delimiter: "\t",
          skipEmptyLines: true,
          // newline: '\n\r',
          complete: function(results, file) {
            if (results.errors.length === 0) {
              resolve(results.data)
            } else if (results.errors.length > 0) {
              let errorLog = results.errors.map(error => {
                return `error: ${error.message}, row: ${error.row}`
              })
              reject(errorLog)
            }
          }
        })
      }
      if (type === 'nonTabularOnly') {
        let fileArray = file.split('\n').filter(line => {
          return line !== '\r' && line !== '\n' && line !== '' && line.length > 0
        })
        resolve(fileArray)
      }
    })
  },
  removeEmptySpaces: function(fileContentsArray) {
    return new Promise((resolve, reject) => {
      let fileArrayWithoutSpaces = fileContentsArray.map(row => {
        return row.filter(column => {
          if (column != false) {
            return true
          }
        })
      })
      resolve(fileArrayWithoutSpaces)
    })
  },
  splitIntoSections: function(fileArray, opts) {
    return new Promise((resolve, reject) => {
      this.findScheduleLandmarks(fileArray, opts)
        .then(indexOf => {
          let tabularSection = undefined
          let nonTabularSection = undefined

          if (opts.hasTable) {
            tabularSection = fileArray.filter((line, index) => {
              if (indexOf.nonTabularSection) {
                return index > indexOf.tabularSection && index < indexOf.nonTabularSection
              }
              return index > indexOf.tabularSection
            })
          }

          if (opts.hasNonTabularInfo) {
            nonTabularSection = fileArray.filter((line, index) => {
              return index > indexOf.nonTabularSection
            })
          }

          if (tabularSection !== undefined || nonTabularSection !== undefined) {
            resolve({
              tabularSection: tabularSection,
              nonTabularSection: nonTabularSection
            })
          }
          reject('error finding schedule sections')
        })
    })
  },
  buildJSON: function(schedule) {
    return new Promise((resolve, reject) => {
      let jsonTemplate = new this.JSONObject('test')
      this.buildTabularSection(schedule.tabularSection, jsonTemplate)
      .then(templateWithTabularSectionCompleted => {
        this.buildNonTabularSection(schedule.nonTabularSection, templateWithTabularSectionCompleted)
        .then(completeSchedule => {
          // console.log('completeSchedule?');
          // console.log(util.inspect(completeSchedule, true, null));
          resolve(completeSchedule)
        })
      })
    })
  },
  buildNonTabularSchedule: function(scheduleArray) {
    return new Promise((resolve, reject) => {
      let jsonTemplate = new this.JSONObject('test')
      this.buildNonTabularSection(lines, jsonTemplate)
        .then(jsonSchedule => {
          resolve(jsonSchedule)
        })
    })
  },
  buildTabularSection: function(lines, jsonTemplate) {
    // console.log(lines);
    // console.log(jsonTemplate);
    return new Promise((resolve, reject) => {
      // regex matching patterns for fee schedule info
      let regex = {
        serviceCategory: /\w[A-Z]+/,
      }
      // starts at -1 so the first array index is 0 when the first service category is encountered
      let currentServiceCat = -1

      lines.forEach((line, index) => {
        if (checkForServiceCategory(line)) {
          currentServiceCat += 1
          jsonTemplate.services[currentServiceCat] = {
            serviceName: line[0],
            codes: []
          }
        } else if (currentServiceCat !== undefined && line.length >= 3) {
          // if the first three indices have info, this is a normal code
          if (line[0] != false && line[1] != false && line[2] != false) {
            jsonTemplate.services[currentServiceCat].codes.push({
              code: line[0],
              details: {
                price: line[2],
                description: line[1]
              }
            })
          }
        }
        if (index === lines.length - 1) {
          resolve(jsonTemplate)
        }
      })

      function checkForServiceCategory(line) {
        if (line[0].match(regex.serviceCategory) && line.length === 1) {
          // console.log('category found!');
          // console.log(line);
          return true
        }
        return false
      }

    })
  },
  buildNonTabularSection: function(lines, jsonTemplate) {
    return new Promise((resolve, reject) => {
      const htmlString = lines.reduce((htmlString, [line]) => {
        return htmlString += `<p>${line}</p> `
      }, '')
      jsonTemplate.nonTabularInfo.html = htmlString
      
      resolve(jsonTemplate)
    })
  },
  findScheduleLandmarks: function(fileArray, opts) {
    // console.log('FILE ARRAY');
    // console.log(fileArray.forEach(line => console.log(line)));
    return new Promise((resolve, reject) => {
      const indicatorFor = {
        tabularSection: undefined,
        nonTabularSection: undefined
      }
      // console.log(util.inspect(fileArray, false, null))
      fileArray.forEach((row, index) => {
        if (opts.hasTable === true) {
          if (row[0].toLowerCase().indexOf('#!table') > -1) {
            indicatorFor.tabularSection = index
          }
        }
        if (opts.hasNonTabularInfo === true) {
          if (row[0].toLowerCase().indexOf('#!freeform') > -1) {
            indicatorFor.nonTabularSection = index
          }
        }
      })
      if (indicatorFor.tabularSection !== undefined || indicatorFor.nonTabularSection !== undefined) {
        resolve(indicatorFor)
      } else {
        reject('no indicator symbols found')
      }
    })
  }

}
