import React, {Component} from "../../_snowpack/pkg/react.js";
import Header from "./Header.js";
import Footer from "./Footer.js";
import {Link} from "../../_snowpack/pkg/react-router-dom.js";
class PayPalThankYou extends Component {
  constructor(props) {
    super(props);
    this.state = {
      headerCopy: "Basix Student",
      headerBootstrapProps: "col-xs-12 col-md-8",
      textBoxCopy: "The Basix Dental Savings Program is a fast, convenient way to save money on dental care. Our Program is a discount plan, not insurance. You have access to our Program because you’ve enrolled in your student health plan. Smart move!  Find your school, search for providers and explore their offers, and save.",
      textBoxBootStrapProps: "col-xs-10 col-xs-offset-1 col-sm-6 col-sm-offset-3 col-md-4 col-md-offset-4",
      btnPath: "temp",
      contentWrapHeight: {height: "100%"},
      footerAlign: {}
    };
    this.setWrapMargin = this.setWrapMargin.bind(this);
  }
  setWrapMargin() {
    this.setState({
      contentTopMargin: {marginTop: `${50}px`}
    });
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.setWrapMargin();
    this.getSchoolList();
  }
  render() {
    return /* @__PURE__ */ React.createElement("div", {
      className: "mainPage"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "row"
    }, /* @__PURE__ */ React.createElement(Header, {
      pageType: "mainPage",
      enableResize: false,
      hideSubtitleOnScroll: false,
      headerPadding: "30",
      logoType: "fullLogo"
    })), /* @__PURE__ */ React.createElement("div", {
      className: "row"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "container-fluid contentWrap",
      style: this.state.contentTopMargin
    }, /* @__PURE__ */ React.createElement("div", {
      className: "row mainPageSearchArea"
    }, /* @__PURE__ */ React.createElement("div", {
      className: this.state.textBoxBootStrapProps
    }, /* @__PURE__ */ React.createElement("div", {
      className: "textBox thankYouTextBox border-complement-4 box-shadow-complement-4"
    }, /* @__PURE__ */ React.createElement("p", null, "Thank you for your purchase!"), /* @__PURE__ */ React.createElement("p", null, /* @__PURE__ */ React.createElement(Link, {
      to: "/"
    }, "Back to main page"))))))), /* @__PURE__ */ React.createElement(Footer, null));
  }
}
export default PayPalThankYou;
