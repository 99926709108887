import React, {Component} from "../../_snowpack/pkg/react.js";
const InsightPayPalForm = (props) => {
  return /* @__PURE__ */ React.createElement("form", {
    action: "https://www.paypal.com/cgi-bin/webscr",
    method: "post",
    target: "_top",
    className: "textBoxSection"
  }, /* @__PURE__ */ React.createElement("input", {
    type: "hidden",
    name: "cmd",
    value: "_s-xclick"
  }), /* @__PURE__ */ React.createElement("input", {
    type: "hidden",
    name: "hosted_button_id",
    value: "XKS2HQQGYYCCA"
  }), /* @__PURE__ */ React.createElement("table", {
    className: "vertical-offset-base"
  }, /* @__PURE__ */ React.createElement("tr", null, /* @__PURE__ */ React.createElement("td", null, /* @__PURE__ */ React.createElement("input", {
    type: "hidden",
    name: "on0",
    value: "Package Options"
  }), "Package Options:")), /* @__PURE__ */ React.createElement("tr", null, /* @__PURE__ */ React.createElement("td", null, /* @__PURE__ */ React.createElement("select", {
    name: "os0",
    className: "paypalSelect"
  }, /* @__PURE__ */ React.createElement("option", {
    value: "Eye Glasses Only"
  }, "Eye Glasses Only $79.00 USD"), /* @__PURE__ */ React.createElement("option", {
    value: "Eye Exam and Glasses"
  }, "Eye Exam and Glasses $109.00 USD"), /* @__PURE__ */ React.createElement("option", {
    value: "Eye Exam, Contact Lens Fitting, 12 Pair Acuvue Oasys Contacts"
  }, "Eye Exam, Contact Lens Fitting, 12 Pair Acuvue Oasys Contacts $225.00 USD"))))), /* @__PURE__ */ React.createElement("input", {
    type: "hidden",
    name: "currency_code",
    value: "USD"
  }), /* @__PURE__ */ React.createElement("input", {
    className: "paypalButton",
    type: "image",
    src: "https://www.paypalobjects.com/en_US/i/btn/btn_buynowCC_LG.gif",
    border: "0",
    name: "submit",
    alt: "PayPal - The safer, easier way to pay online!"
  }), /* @__PURE__ */ React.createElement("img", {
    alt: "",
    border: "0",
    src: "https://www.paypalobjects.com/en_US/i/scr/pixel.gif",
    width: "1",
    height: "1"
  }));
};
export default InsightPayPalForm;
