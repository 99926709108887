import Constants from './constants.js'
import Store from './store.js'
import axios from '../../../_snowpack/pkg/axios.js'

class Actions {

  static getSchoolList(){
    axios.get('/admin/schools/list').then(res => {
      Store.dispatch({
        type: Constants.GET_SCHOOL_LIST_RESPONSE, 
        response: res.data
      }) 
    });
  }

  static getSchool(query) {
    axios({
      method: 'post',
      url: '/admin/schools/getSchool',
      data: query
    }).then(res => {
      const { data } = res

      Store.dispatch({
        type: Constants.GET_SCHOOL_RESPONSE, 
        response: data
      })
    });
  }

  static writeChange(scheduleId){
    let reduxStore = Store.getState()
    let editedSchool = undefined

    if (scheduleId === 'new') {
      editedSchool = reduxStore.schools.newSchoolChanges
      Object.keys(editedSchool).forEach(key => {
        if (!editedSchool[key]) {
          Store.dispatch({
            type: Constants.INCOMPLETE_SCHOOL_INFO,
            error: key
          });
          return false;
        }
      })
    } else {
      editedSchool = reduxStore.schools.schoolChanges
    }

    axios({
      method: 'post',
      url: '/admin/schools/update',
      data: {editedSchool: editedSchool}
    }).then(res => {
      const { data } = res

      Store.dispatch({
        type: Constants.SEND_SCHOOL_UPDATE_RESPONSE, 
        response: data
      })
    })
  }

  
  static deleteItem(schoolId){
    axios({
      method: 'post',
      url: '/admin/schools/delete/',
      data: {schoolToDelete: schoolId}
    }).then(res => {
      const { data } = res

      Store.dispatch({
        type: Constants.SEND_DELETION_RESPONSE, 
        response: data
      })
    })
  }

  static createNewSchool(){
    Store.dispatch({
      type: Constants.CREATE_SCHOOL,
      change: {}
    });
  }
  static resetErrorMessage(){
    Store.dispatch({
      type: Constants.RESET_ERRORS,
      change: {}
    });
  }

  static clearSearchAndChanges(){
    return new Promise((resolve, reject) => {
      let state = Store.getState()
      if (Object.keys(state.schools.searchResult).length > 0 || Object.keys(state.schools.newSchool).length > 0) {
        if (confirm('Would you like to proceed? Any unsaved changes you\'ve made will be lost')) {
          Store.dispatch({
            type: Constants.CLEAR_SEARCH_AND_CHANGES
          })
          resolve()
        }
        reject()
      }
      resolve()
    })
  }

  static reportChange(changeObj) {
    Store.dispatch({
      type: Constants.REPORT_CHANGE,
      change: changeObj,
    });
  }
}


export default Actions;
