import React, {useState} from "../../_snowpack/pkg/react.js";
import {slide as Menu} from "../../_snowpack/pkg/react-burger-menu.js";
import {Link} from "../../_snowpack/pkg/react-router-dom.js";
const schools = [
  "Dartmouth",
  "University_of_New_Hampshire",
  "BYU",
  "Northeastern",
  "Duke"
];
const Header = (props) => {
  const {history, schoolMap} = props;
  const {
    location: {hash}
  } = history;
  const onSchoolPageP = hash.split("/").some((s) => schools.includes(s));
  const schoolKey = hash.split("/").find((s) => schools.includes(s));
  const isBYUP = schoolKey === "BYU";
  const schoolConfig = schoolMap.find((x) => x.key === schoolKey);
  const mainLink = schoolConfig && schoolConfig.mainLink;
  const logoUrl = onSchoolPageP ? mainLink : "/";
  const [menuOpen, setMenuOpen] = useState(false);
  const handleOpen = () => {
    setTimeout(() => {
      menuOpen ? setMenuOpen(false) : setMenuOpen(true);
    }, 0);
  };
  history.listen((stuff) => setMenuOpen(false));
  return /* @__PURE__ */ React.createElement("header", {
    className: "header"
  }, /* @__PURE__ */ React.createElement(Link, {
    to: logoUrl
  }, /* @__PURE__ */ React.createElement("img", {
    src: "./images/BASIX.svg",
    alt: "Basix"
  })), /* @__PURE__ */ React.createElement(Menu, {
    right: true,
    customBurgerIcon: /* @__PURE__ */ React.createElement("div", {
      className: "burger-btn"
    }, /* @__PURE__ */ React.createElement("span", null, "Menu"), /* @__PURE__ */ React.createElement("img", {
      className: "burger-icon",
      src: "./images/burger.svg"
    }), " "),
    pageWrapId: "page-wrap",
    outerContainerId: "outer-container",
    onOpen: handleOpen,
    isOpen: menuOpen
  }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("div", {
    className: "burger-menu"
  }, /* @__PURE__ */ React.createElement("button", {
    className: "burger-menu-close menu-item",
    onClick: handleOpen
  }, /* @__PURE__ */ React.createElement("img", {
    src: "/images/close.svg",
    alt: "Close"
  }), /* @__PURE__ */ React.createElement("span", null, "Close")), /* @__PURE__ */ React.createElement(Link, {
    className: "menu-item",
    to: "/how-to-use"
  }, /* @__PURE__ */ React.createElement("span", null, "How to Use the Program"), /* @__PURE__ */ React.createElement("img", {
    src: "./images/chevron-right.svg",
    alt: "arrow right"
  })), /* @__PURE__ */ React.createElement(Link, {
    className: "menu-item",
    to: "/"
  }, /* @__PURE__ */ React.createElement("span", null, "Find my school"), /* @__PURE__ */ React.createElement("img", {
    src: "./images/chevron-right.svg",
    alt: "arrow right"
  })), /* @__PURE__ */ React.createElement(Link, {
    className: "menu-item",
    to: "/about"
  }, /* @__PURE__ */ React.createElement("span", null, "Why BASIX?"), /* @__PURE__ */ React.createElement("img", {
    src: "./images/chevron-right.svg",
    alt: "arrow right"
  })), /* @__PURE__ */ React.createElement(Link, {
    className: "menu-item",
    to: "/contact"
  }, /* @__PURE__ */ React.createElement("span", null, "Contact Us"), /* @__PURE__ */ React.createElement("img", {
    src: "./images/chevron-right.svg",
    alt: "arrow right"
  }))))));
};
const Links = () => {
  return /* @__PURE__ */ React.createElement("div", {
    className: "row header--links"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "col about futura"
  }, /* @__PURE__ */ React.createElement(Link, {
    to: "/about"
  }, "About")), /* @__PURE__ */ React.createElement("div", {
    className: "col faq futura"
  }, /* @__PURE__ */ React.createElement(Link, {
    to: "/FAQ"
  }, "FAQs")), /* @__PURE__ */ React.createElement("div", {
    className: "col contact futura"
  }, /* @__PURE__ */ React.createElement(Link, {
    to: "/contact"
  }, "Contact Us")));
};
const Title = () => {
  return /* @__PURE__ */ React.createElement("div", {
    className: "row header--title header-color"
  }, /* @__PURE__ */ React.createElement("a", {
    className: "unstyled-link",
    href: "/"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "col-sm-12 futura header--logo"
  }, "basixstudent.com"), /* @__PURE__ */ React.createElement("div", {
    className: "col-sm-12 futura header--subtitle"
  }, "making your student health plan better.")));
};
export default Header;
