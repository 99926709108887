import React, {Component} from "../../../../_snowpack/pkg/react.js";
const renderIf = (predicate) => (element) => predicate && element;
class WaiverLine extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    if (this.props.empty) {
      return /* @__PURE__ */ React.createElement("tbody", null, /* @__PURE__ */ React.createElement("tr", null, /* @__PURE__ */ React.createElement("td", {
        colspan: "5"
      }, /* @__PURE__ */ React.createElement("button", {
        className: "btn btn-success btn-xs",
        onClick: (e) => {
          e.preventDefault();
          this.props.addRow(-1, "line");
        }
      }, "Add row"))));
    } else {
      return /* @__PURE__ */ React.createElement("tbody", null, renderIf(this.props.itemIndex === 0)(/* @__PURE__ */ React.createElement("tr", null, /* @__PURE__ */ React.createElement("td", {
        colspan: "5"
      }, /* @__PURE__ */ React.createElement("button", {
        className: "btn btn-success btn-xs",
        onClick: (e) => {
          e.preventDefault();
          this.props.addRow(-1, "line");
        }
      }, "Add row")))), /* @__PURE__ */ React.createElement("tr", {
        className: this.props.rowClassName + " nonTabularRow"
      }, /* @__PURE__ */ React.createElement("td", null, /* @__PURE__ */ React.createElement("tr", null, /* @__PURE__ */ React.createElement("td", null, "Edit"), /* @__PURE__ */ React.createElement("td", null, /* @__PURE__ */ React.createElement("input", {
        type: "checkbox",
        onChange: this.props.toggleCheckBox
      }))), /* @__PURE__ */ React.createElement("tr", {
        className: "underline"
      }, /* @__PURE__ */ React.createElement("td", null, /* @__PURE__ */ React.createElement("strong", null, "Class name:")), this.props.renderValue({tag: "td", content: "className"})), /* @__PURE__ */ React.createElement("tr", {
        className: "underline"
      }, /* @__PURE__ */ React.createElement("td", null, /* @__PURE__ */ React.createElement("strong", null, "HTML tag:")), this.props.renderValue({tag: "td", content: "tagName"}))), /* @__PURE__ */ React.createElement("td", null, /* @__PURE__ */ React.createElement("td", null, /* @__PURE__ */ React.createElement("strong", null, "text:")), this.props.renderValue({tag: "td", content: "text"})), /* @__PURE__ */ React.createElement("td", null, /* @__PURE__ */ React.createElement("button", {
        className: "btn btn-danger btn-sm pull-right",
        style: {marginRight: "15px"},
        onClick: (e) => {
          e.preventDefault();
          this.props.deleteRow(this);
        }
      }, "Delete"))), /* @__PURE__ */ React.createElement("tr", null, /* @__PURE__ */ React.createElement("td", {
        colspan: "5"
      }, /* @__PURE__ */ React.createElement("button", {
        className: "btn btn-success btn-xs",
        onClick: (e) => {
          e.preventDefault();
          this.props.addRow(this.props.itemIndex, "line");
        }
      }, "Add row"))));
    }
  }
}
export default WaiverLine;
