import React, {Component} from "../../_snowpack/pkg/react.js";
import {Link} from "../../_snowpack/pkg/react-router-dom.js";
const Button = (props) => {
  const {
    aria,
    className,
    clickHandler,
    disabled,
    link,
    linkCls,
    linkCopy,
    target
  } = props;
  const linkProps = {
    ...aria,
    className: `segoe ${className}`,
    to: link
  };
  return /* @__PURE__ */ React.createElement(Link, {
    ...linkProps
  }, props.linkCopy);
};
export default Button;
