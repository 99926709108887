import React, {Component} from "../../../../_snowpack/pkg/react.js";
import shortid from "../../../../_snowpack/pkg/shortid.js";
const renderIf = (predicate) => (element) => predicate && element;
class SchoolRow extends Component {
  constructor(props) {
    super(props);
    this.renderVals = this.renderVals.bind(this);
    this.renderMultipleItems = this.renderMultipleItems.bind(this);
    this.state = {
      keyID: shortid.generate()
    };
  }
  renderMultipleItems(itemArray, itemType) {
    return /* @__PURE__ */ React.createElement("tr", {
      key: `${this.state.keyID}`,
      className: "underline"
    }, /* @__PURE__ */ React.createElement("td", {
      style: {width: "100px"}
    }, "Edit?", /* @__PURE__ */ React.createElement("input", {
      type: "checkbox",
      onChange: this.props.toggleCheckBox
    })), /* @__PURE__ */ React.createElement("td", {
      style: {width: "200px"}
    }, itemType), /* @__PURE__ */ React.createElement("td", null, itemArray.map((item, index) => {
      if (item === void 0) {
        return /* @__PURE__ */ React.createElement("div", null);
      } else {
        if (index === itemArray.length - 1) {
          return /* @__PURE__ */ React.createElement("div", {
            key: index
          }, /* @__PURE__ */ React.createElement("div", null, this.props.renderArrayItems({
            tag: "td",
            content: item,
            arrayName: itemType,
            arrayIndex: index
          }), renderIf(this.props.checked)(/* @__PURE__ */ React.createElement("button", {
            className: "btn btn-danger btn-xs",
            onClick: () => {
              this.props.deleteArrayItem("services", index);
            }
          }, "Delete", " "))), /* @__PURE__ */ React.createElement("div", null, renderIf(this.props.checked)(/* @__PURE__ */ React.createElement("button", {
            className: "btn btn-success btn-xs",
            onClick: () => {
              this.props.pushNewItemToArray("services");
            }
          }, "Add service"))));
        } else {
          return /* @__PURE__ */ React.createElement("div", {
            key: index
          }, this.props.renderArrayItems({
            tag: "td",
            content: item,
            arrayName: itemType,
            arrayIndex: index
          }), renderIf(this.props.checked)(/* @__PURE__ */ React.createElement("button", {
            className: "btn btn-danger btn-xs",
            onClick: () => {
              this.props.deleteArrayItem("services", index);
            }
          }, "Delete", " ")));
        }
      }
    })));
  }
  renderVals() {
    let content = this.props.content;
    let contentItem = Object.keys(content)[0];
    if (Array.isArray(this.props.content[contentItem])) {
      return /* @__PURE__ */ React.createElement("table", {
        className: "table table-sm table-striped"
      }, /* @__PURE__ */ React.createElement("tbody", null, this.renderMultipleItems(this.props.content[contentItem], contentItem)));
    } else if (contentItem === "_id") {
      return /* @__PURE__ */ React.createElement("table", {
        className: "table table-sm table-striped"
      }, /* @__PURE__ */ React.createElement("tbody", null, /* @__PURE__ */ React.createElement("tr", {
        className: "underline"
      }, /* @__PURE__ */ React.createElement("td", {
        style: {width: "100px"}
      }), /* @__PURE__ */ React.createElement("td", {
        style: {width: "200px"}
      }, contentItem), /* @__PURE__ */ React.createElement("td", null, this.props.renderValue({
        tag: "td",
        content: contentItem
      })))));
    } else {
      return /* @__PURE__ */ React.createElement("table", {
        className: "table table-sm table-striped"
      }, /* @__PURE__ */ React.createElement("tbody", null, /* @__PURE__ */ React.createElement("tr", {
        className: "underline"
      }, /* @__PURE__ */ React.createElement("td", {
        style: {width: "100px"}
      }, "Edit?", /* @__PURE__ */ React.createElement("input", {
        type: "checkbox",
        onChange: this.props.toggleCheckBox
      })), /* @__PURE__ */ React.createElement("td", {
        style: {width: "200px"}
      }, contentItem), /* @__PURE__ */ React.createElement("td", null, this.props.renderValue({
        tag: "td",
        content: contentItem
      })))));
    }
  }
  render() {
    return this.renderVals();
  }
}
export default SchoolRow;
