import React, {Component} from "../../_snowpack/pkg/react.js";
const withDropdownLoading = (propName) => (WrappedComponent) => {
  return class withDropdownLoading extends Component {
    constructor(props) {
      super(props);
      this.isEmpty = this.isEmpty.bind(this);
    }
    isEmpty(prop) {
      if (prop === null || prop === void 0 || prop.hasOwnProperty("length") && prop.length === 0 || prop.constructor === Object && Object.keys(prop).length === 0) {
        return true;
      } else {
        return false;
      }
    }
    render() {
      const isLoading = {
        placeholder: "Updating ...",
        options: [],
        selectedValue: this.props.dropdown.selectedValue,
        searchBarBootstrapProps: this.props.dropdown.searchBarBootstrapProps
      };
      if (this.isEmpty(this.props[propName]["options"])) {
        return /* @__PURE__ */ React.createElement(WrappedComponent, {
          dropdown: isLoading
        });
      }
      if (!this.isEmpty(this.props[propName]["options"])) {
        return /* @__PURE__ */ React.createElement(WrappedComponent, {
          ...this.props
        });
      }
    }
  };
};
export default withDropdownLoading;
