import Constants from '../constants.js'
import isEqual from '../../../../_snowpack/pkg/lodash.isequal.js'

const initialState = {
  searchResult: {},
  schoolChanges: {},
  newSchool: {},
  newSchoolChanges: {}
};

export default (state = initialState, action) => {

  if (action.type === Constants.CLEAR_SEARCH_AND_CHANGES) {
    return Object.assign({}, state, {
      searchResult: {},
      schoolChanges: {},
      newSchool: {},
      newSchoolChanges: {}
    });
  }

  if (action.type === Constants.GET_SCHOOL_LIST_RESPONSE) {
    console.log('hello');
    console.log(action)
    return Object.assign({}, state, {
      schoolList: action.response.schoolList
    });
  }

  if (action.type === Constants.GET_SCHOOL_RESPONSE) {
    console.log('action.response');
    console.log(action.response);
    return Object.assign({}, state, {
      searchResult: action.response.school,
      schoolChanges: action.response.school,
      newSchool: {},
      newSchoolChanges: {}
    });
  }

  if (action.type === Constants.REPORT_CHANGE) {

    if (action.change.currentValue._id === 'new') {
      return Object.assign({}, state, {
        newSchoolChanges: action.change.currentValue
      })
    } else {
      return Object.assign({}, state, {
        schoolChanges: action.change.currentValue
      })
    }
  }

  if (action.type === Constants.SEND_DELETION_RESPONSE) {
    console.log('deleted?');
    if (action.response.deletion === 'success') {
      alert(`record for _id: ${action.response.recordDeleted} deleted`)
      return Object.assign({}, state, {
        schoolChanges: {},
        searchResult: {}
      })
    } else {
      alert(`deletion NOT successful: ${action.response.error}`)
    }
  }

  if (action.type === Constants.CREATE_SCHOOL) {
    console.log('creatin school');
    const SchoolTemplate = {
      _id: 'new',
      key: '',
      name: '',
      state: '',
      services: [''],
      broker: '',
      eligibilityCopy: '',
      eligibilityNumber: '',
      waiverCopy: ''
    }

    if (isEqual(state.schoolChanges, state.searchResult)) {
      if (confirm('Do you want to create a new school? Any unsaved changes will be lost')) {
        return Object.assign({}, state, {
          searchResult: {},
          schoolChanges: {},
          newSchool: SchoolTemplate,
          newSchoolChanges: SchoolTemplate
        })
      }
    } else {
      return Object.assign({}, state, {
        newSchool: SchoolTemplate,
        newSchoolChanges: SchoolTemplate
      })
    }
  }

  if (action.type === Constants.SEND_SCHOOL_UPDATE_RESPONSE) {
    console.log('action.response');
    console.log(action.response);
    if (action.response.save === 'failed') {
      alert(`💣 Provider save UNSUCCESSFUL: ${JSON.stringify(action.response.error)}`)
    }
    if (action.response.save === 'success') {
      alert(`🚀 Provider save successful! Entry ID: ${action.response._id}`)
    }
  }

  if (action.type === Constants.INCOMPLETE_SCHOOL_INFO) {
    alert(`You have not filled out the ${action.error} field, please fill out all fields before saving.`)
  }

  return state;
};
