import React, {Component} from "../../../../_snowpack/pkg/react.js";
import ServiceName from "./ServiceName.js";
import ServiceRow from "./ServiceRow.js";
import Actions from "../actions.js";
import cloneDeep from "../../../../_snowpack/pkg/lodash.clonedeep.js";
import debounce from "../../../../_snowpack/pkg/lodash.debounce.js";
import isEqual from "../../../../_snowpack/pkg/lodash.isequal.js";
import shortid from "../../../../_snowpack/pkg/shortid.js";
import withTableRows from "../../SharedComponents/WithTableRows.js";
import withCategory from "../../SharedComponents/WithCategory.js";
const renderIf = (predicate) => (element) => predicate && element;
const ServiceNameRowHOC = withTableRows(ServiceName);
const ServiceNameCategoryHOC = withCategory(ServiceNameRowHOC)(Actions);
const ServiceRowWithHOC = withTableRows(ServiceRow);
const ServiceCategoryHOC = withCategory(ServiceRowWithHOC)(Actions);
class ServiceCategories extends Component {
  constructor(props) {
    super(props);
    let categories = cloneDeep(this.props.data.serviceCategories);
    categories = categories.map((item) => {
      item.codes = item.codes.map((code) => {
        return Object.assign({}, code, {keyID: shortid.generate()});
      });
      return Object.assign({}, item, {keyID: shortid.generate()});
    });
    this.state = {
      currentVals: cloneDeep(categories),
      originalVals: cloneDeep(categories)
    };
    this.renderServiceCategories = this.renderServiceCategories.bind(this);
    this.addCategory = this.addCategory.bind(this);
    this.deleteCategory = this.deleteCategory.bind(this);
    this.reportChange = this.reportChange.bind(this);
    this.updateCurrentVals = debounce(this.updateCurrentVals.bind(this), 500, {trailing: true});
  }
  componentWillReceiveProps(nextProps) {
  }
  componentWillUpdate(nextProps, nextState) {
  }
  componentWillUnmount() {
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (isEqual(nextState, this.state)) {
    } else {
    }
    return true;
  }
  componentDidUpdate(prevProps, prevState) {
    if (isEqual(this.state.currentVals, prevState.currentVals) === false) {
      this.reportChange(this.state.currentVals, this.props.dataType, this.props._id);
    }
  }
  addCategory(targetIndex, type) {
    if (targetIndex === -1) {
      targetIndex = 0;
    } else if (targetIndex >= 0) {
      targetIndex += 1;
    }
    let currentValsUpdate = cloneDeep(this.state.currentVals);
    let updateItem = {
      serviceName: "NEW",
      keyID: shortid.generate(),
      codes: [{
        code: "999",
        details: {
          price: "$999",
          description: "New Code"
        },
        keyID: shortid.generate()
      }]
    };
    currentValsUpdate.splice(targetIndex, 0, updateItem);
    this.setState({
      currentVals: currentValsUpdate
    });
  }
  deleteCategory(itemToDelete) {
    if (confirm("Are you sure you want to delete this row?")) {
      let currentValsUpdate = cloneDeep(this.state.currentVals);
      this.setState({
        currentVals: currentValsUpdate.filter((item, index) => {
          return item.keyID !== itemToDelete.keyID;
        })
      });
    }
  }
  reportChange(currentVals, dataType, _id) {
    Actions.reportChange({currentValue: currentVals, dataType, _id});
  }
  updateCurrentVals(update) {
    let currentValsUpdate = cloneDeep(this.state.currentVals);
    if (update.dataType === "serviceCategoryName") {
      currentValsUpdate[update.categoryIndex].serviceName = update.currentValue.serviceName;
      this.setState({
        currentVals: currentValsUpdate
      });
    } else if (update.dataType === "serviceCategoryItem") {
      currentValsUpdate[update.categoryIndex].codes = update.currentValue;
      this.setState({
        currentVals: currentValsUpdate
      });
    }
  }
  renderServiceCategories() {
    if (this.state.currentVals.length < 1) {
      return /* @__PURE__ */ React.createElement("div", {
        className: "subSection"
      }, /* @__PURE__ */ React.createElement("div", {
        className: "margin"
      }, /* @__PURE__ */ React.createElement("button", {
        className: "btn btn-primary btn-sm",
        onClick: (e) => {
          e.preventDefault();
          this.addCategory(-1, "category");
        }
      }, " Add a category? ")));
    } else {
      return this.state.currentVals.map((service, index) => {
        return /* @__PURE__ */ React.createElement("div", {
          className: "margin",
          key: `categoryDiv_${service.keyID}`
        }, renderIf(index === 0)(/* @__PURE__ */ React.createElement("div", {
          className: "margin buttonRow row"
        }, /* @__PURE__ */ React.createElement("div", {
          className: "col-xs-12"
        }, /* @__PURE__ */ React.createElement("button", {
          className: "btn btn-primary btn-sm",
          onClick: (e) => {
            e.preventDefault();
            this.addCategory(-1, "category");
          }
        }, "Add category")))), /* @__PURE__ */ React.createElement("div", {
          className: "subSection margin"
        }, /* @__PURE__ */ React.createElement("div", {
          className: "buttonRow row margin"
        }, /* @__PURE__ */ React.createElement("div", {
          className: "col-xs-4 col-xs-offset-8"
        }, /* @__PURE__ */ React.createElement("div", {
          className: "pull-right"
        }, /* @__PURE__ */ React.createElement("button", {
          className: "btn btn-danger",
          onClick: (e) => {
            e.preventDefault();
            this.deleteCategory(service, "category");
          }
        }, "Delete category")))), /* @__PURE__ */ React.createElement(ServiceNameCategoryHOC, {
          key: `serviceNameCategory_${service.keyID}`,
          keyID: `serviceNameTableRow_${index}_${service.keyID}`,
          data: {serviceName: service.serviceName},
          dataType: "serviceCategoryName",
          index: {serviceIndex: index},
          reportChange: this.reportChange,
          reportToParent: this.updateCurrentVals,
          _id: this.props._id
        }), /* @__PURE__ */ React.createElement(ServiceCategoryHOC, {
          key: `serviceCategory_${service.keyID}`,
          serviceName: service.serviceName,
          dataType: "serviceCategoryItem",
          index: {serviceIndex: index},
          data: service.codes,
          reportChange: this.reportChange,
          reportToParent: this.updateCurrentVals,
          _id: this.props._id
        })), /* @__PURE__ */ React.createElement("div", {
          className: "margin buttonRow row"
        }, /* @__PURE__ */ React.createElement("div", {
          className: "col-xs-12"
        }, /* @__PURE__ */ React.createElement("button", {
          className: "btn btn-primary btn-sm",
          onClick: (e) => {
            e.preventDefault();
            this.addCategory(index, "category");
          }
        }, "Add category"))));
      });
    }
  }
  render() {
    return /* @__PURE__ */ React.createElement("div", null, this.renderServiceCategories());
  }
}
export default ServiceCategories;
