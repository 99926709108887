import PropTypes from "../../../_snowpack/pkg/prop-types.js";
import React from "../../../_snowpack/pkg/react.js";
import {Link} from "../../../_snowpack/pkg/react-router-dom.js";
const propTypes = {
  location: PropTypes.object
};
class Navbar extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return /* @__PURE__ */ React.createElement("div", {
      className: "navbar navbar-fixed-top row"
    }, /* @__PURE__ */ React.createElement("div", {
      style: {paddingLeft: "10px"},
      className: "nav row col-10"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "col-sm"
    }, /* @__PURE__ */ React.createElement(Link, {
      to: "/"
    }, "Home")), /* @__PURE__ */ React.createElement("div", {
      className: "col-sm"
    }, /* @__PURE__ */ React.createElement(Link, {
      to: "/feeSchedules"
    }, "Fee Schedules")), /* @__PURE__ */ React.createElement("div", {
      className: "col-sm"
    }, /* @__PURE__ */ React.createElement(Link, {
      to: "/providers"
    }, "Providers")), /* @__PURE__ */ React.createElement("div", {
      className: "col-sm"
    }, /* @__PURE__ */ React.createElement(Link, {
      to: "/schools"
    }, "Schools")), /* @__PURE__ */ React.createElement("div", {
      className: "col-sm"
    }, /* @__PURE__ */ React.createElement(Link, {
      to: "/waivers"
    }, "Waivers")), /* @__PURE__ */ React.createElement("div", {
      className: "col-sm"
    }, /* @__PURE__ */ React.createElement("a", {
      href: "/login/logout"
    }, "Sign out"))));
  }
}
Navbar.propTypes = propTypes;
export default Navbar;
