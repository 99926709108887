// import ApiActions from '../../../actions/api'
import Constants from './constants.js'
import Store from './store.js'
import importSchedule from './importSchedule.js'
import axios from '../../../_snowpack/pkg/axios.js'

const ApiActions = {get: () => {}, post: () => {}}

class Actions {

  static getFeeSchedules(query) {

    axios({
      method: 'get',
      url: '/admin/feeSchedules/',
      params: query
    }).then(res => {
      const { data } = res

      Store.dispatch({
        type: Constants.GET_SCHEDULES_RESPONSE, 
        response: data
      })
    });
  }

  static getBrowsingList() {

    console.log('we getting browsing list?')
    
    axios({
      method: 'get',
      url: '/admin/feeSchedules/list',
    }).then(res => {
      const { data } = res

      Store.dispatch({
        type: Constants.GET_SCHEDULES_LIST_RESPONSE, 
        response: data
      })
    });
  }

  static clearBrowsingList() {
    Store.dispatch({
      type: Constants.CLEAR_BROWSING_LIST,
      param: '',
    });
  }

   static reportChange(changeObj){
    Store.dispatch({
      type: Constants.REPORT_CHANGE,
      change: changeObj,
    });
  }

  static writeChange(scheduleId) {
    let editedSchedule = undefined
    let reduxStore = Store.getState()
    if (scheduleId === 'new') {
      editedSchedule = reduxStore.feeSchedules.newScheduleChanges
    } else {
      editedSchedule = reduxStore.feeSchedules.feeScheduleChanges.map(change => {
        if (change._id === scheduleId) {
          return change
        }
      })
      editedSchedule = editedSchedule[0]
    }

    axios({
      method: 'post',
      url: '/admin/feeSchedules/update/',
      data: editedSchedule
    }).then(res => {
      const { data } = res

      Store.dispatch({
        type: Constants.SEND_UPDATE_RESPONSE, 
        response: data
      })
    });
  }

  static deleteItem(scheduleId) {
    axios({
      method: 'post',
      url: '/admin/feeSchedules/delete/',
      data: {scheduleToDelete: scheduleId},
    }).then(res => {
      const { data } = res

      Store.dispatch({
        type: Constants.SEND_DELETION_RESPONSE, 
        response: data
      })
    });
  }

  static createSchedule(newOrClone, scheduleCloningParams) {
    if (newOrClone === 'new') {
      Store.dispatch({
        type: Constants.CREATE_NEW_SCHEDULE,
        createType: 'new'
      });
    }
    if (newOrClone === 'clone') {
      let query = {
        db: scheduleCloningParams.db,
        field: scheduleCloningParams.field,
        query: scheduleCloningParams.query
      }
      axios({
        method: 'get',
        url: '/admin/feeSchedules/',
        params: query
      }).then(res => {
        const { data } = res
  
        Store.dispatch({
          type: Constants.CREATE_CLONE_SCHEDULE_RESPONSE, 
          response: data
        })
      });
    }
  }

  static generateScheduleExport(schedule, opts){
    Store.dispatch({
      type: Constants.GENERATE_SCHEDULE_EXPORT,
      scheduleToExport: schedule,
      opts: opts,
      Store: Store
    });
  }

  static handleScheduleImport(file) {
    console.log('from here, we will process the file');
    importSchedule.parse(file)
    .then(parsedFile => {
      console.log('parsedFile is:');
      console.log(parsedFile);
      Store.dispatch({
        type: Constants.IMPORT_SCHEDULE_RESPONSE,
        processedSchedule: parsedFile
      });
    })
  }

  static clearSearchAndChanges(){
    return new Promise((resolve, reject) => {
      let state = Store.getState()
      if (state.feeSchedules.searchResults.length > 0 || Object.keys(state.feeSchedules.newSchedule).length > 0) {
        if (confirm('Would you like to proceed? Any unsaved changes you\'ve made will be lost')) {
          Store.dispatch({
            type: Constants.CLEAR_SEARCH_AND_CHANGES
          })
          resolve()
        }
        reject()
      }
      resolve()
    })
  }

}


export default Actions;
