import React, {Component} from "../../../../_snowpack/pkg/react.js";
class NonTabularLine extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    return /* @__PURE__ */ React.createElement("tbody", null, /* @__PURE__ */ React.createElement("tr", {
      className: this.props.rowClassName + " nonTabularRow"
    }, /* @__PURE__ */ React.createElement("td", null, /* @__PURE__ */ React.createElement("tr", null, /* @__PURE__ */ React.createElement("td", null, "Edit"), /* @__PURE__ */ React.createElement("td", null, /* @__PURE__ */ React.createElement("input", {
      type: "checkbox",
      onChange: this.props.toggleCheckBox
    })))), /* @__PURE__ */ React.createElement("td", null, /* @__PURE__ */ React.createElement("td", null, /* @__PURE__ */ React.createElement("strong", null, "text:")), this.props.renderValue({tag: "td", content: "html"})), /* @__PURE__ */ React.createElement("td", null, /* @__PURE__ */ React.createElement("button", {
      className: "btn btn-danger btn-sm pull-right",
      style: {marginRight: "15px"},
      onClick: (e) => {
        e.preventDefault();
        this.props.deleteRow(this);
      }
    }, "Delete"))));
  }
}
export default NonTabularLine;
