import React, {Component} from "../../../../_snowpack/pkg/react.js";
import Actions from "../actions.js";
import SchoolRow from "./SchoolRow.js";
import withTableRows from "../../SharedComponents/WithTableRows.js";
import withCategory from "../../SharedComponents/WithCategory.js";
const SchoolRowHOC = withTableRows(SchoolRow);
const SchoolCategoryHOC = withCategory(SchoolRowHOC)(Actions);
class SchoolCategory extends Component {
  constructor(props) {
    super(props);
    this.reportChange = this.reportChange.bind(this);
    this.writeChange = this.writeChange.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
  }
  writeChange(e) {
    e.preventDefault();
    if (confirm("Are you sure you want to save this change?")) {
      console.log(this);
      Actions.writeChange(this.props.data.school._id);
    }
  }
  deleteItem(e) {
    e.preventDefault();
    if (confirm("Are you sure you want to delete this school entry?")) {
      Actions.deleteItem(this.props.data.school._id);
    }
  }
  reportChange(change) {
    Actions.reportChange({currentValue: change.currentValue, dataType: change.dataType, _id: this.props.data.school._id});
  }
  render() {
    return /* @__PURE__ */ React.createElement("div", {
      className: "subSectionPadding"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "row margin buttonRow"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "col-xs-6"
    }, /* @__PURE__ */ React.createElement("button", {
      className: "changeButton btn btn-success",
      onClick: (e) => {
        e.preventDefault();
        this.writeChange(e);
      }
    }, "Save changes to DB")), /* @__PURE__ */ React.createElement("div", {
      className: "col-xs-6"
    }, /* @__PURE__ */ React.createElement("button", {
      className: "changeButton btn btn-danger pull-right",
      onClick: (e) => {
        e.preventDefault();
        this.deleteItem(e);
      }
    }, "Delete school"))), /* @__PURE__ */ React.createElement("div", {
      className: "row margin"
    }, /* @__PURE__ */ React.createElement(SchoolCategoryHOC, {
      data: this.props.data.school,
      dataType: "schoolInfo",
      reportToParent: this.reportChange,
      index: {serviceIndex: "none"},
      key: `Category_${this.props.data.school._id}`
    })));
  }
}
export default SchoolCategory;
