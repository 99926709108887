import React from "../../_snowpack/pkg/react.js";
import {Link} from "../../_snowpack/pkg/react-router-dom.js";
const Footer = (props) => {
  return /* @__PURE__ */ React.createElement("div", {
    className: "footer row futura"
  }, /* @__PURE__ */ React.createElement("div", {
    className: "col"
  }, /* @__PURE__ */ React.createElement(Link, {
    to: "/"
  }, "Back to main page")));
};
export default Footer;
