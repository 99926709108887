import React from "../../_snowpack/pkg/react.js";
import {Link} from "../../_snowpack/pkg/react-router-dom.js";
class AdminHome extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    return /* @__PURE__ */ React.createElement("section", {
      className: "section-home container"
    }, /* @__PURE__ */ React.createElement("h1", {
      className: "page-header"
    }, "Basix Student Admin"), /* @__PURE__ */ React.createElement("div", {
      className: "row"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "col-sm-6 col-sm-offset-3 "
    }), /* @__PURE__ */ React.createElement("div", {
      className: "col-sm-6 col-sm-offset-3"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "panel panel-default"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "panel-heading"
    }, /* @__PURE__ */ React.createElement("h3", {
      className: "panel-title"
    }, "Provider listings")), /* @__PURE__ */ React.createElement("div", {
      className: "panel-body"
    }, /* @__PURE__ */ React.createElement("p", null, "View, add, update, and delete provider entries."), /* @__PURE__ */ React.createElement("div", {
      className: "row"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "col-xs-6 col-xs-offset-3"
    }, /* @__PURE__ */ React.createElement(Link, {
      to: "/providers",
      className: "btn btn-success btn-block"
    }, "Go")))))), /* @__PURE__ */ React.createElement("div", {
      className: "col-sm-6 col-sm-offset-3 "
    }, /* @__PURE__ */ React.createElement("div", {
      className: "panel panel-default"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "panel-heading"
    }, /* @__PURE__ */ React.createElement("h3", {
      className: "panel-title"
    }, "Fee schedules")), /* @__PURE__ */ React.createElement("div", {
      className: "panel-body"
    }, /* @__PURE__ */ React.createElement("p", null, "View, add, update, and delete fee schedule entries."), /* @__PURE__ */ React.createElement("div", {
      className: "row"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "col-xs-6 col-xs-offset-3"
    }, /* @__PURE__ */ React.createElement(Link, {
      to: "/feeSchedules",
      className: "btn btn-success btn-block"
    }, "Go")))))), /* @__PURE__ */ React.createElement("div", {
      className: "col-sm-6 col-sm-offset-3"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "panel panel-default"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "panel-heading"
    }, /* @__PURE__ */ React.createElement("h3", {
      className: "panel-title"
    }, "Schools")), /* @__PURE__ */ React.createElement("div", {
      className: "panel-body"
    }, /* @__PURE__ */ React.createElement("p", null, "View, add, update, and delete school entries."), /* @__PURE__ */ React.createElement("div", {
      className: "row"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "col-xs-6 col-xs-offset-3"
    }, /* @__PURE__ */ React.createElement(Link, {
      to: "/schools",
      className: "btn btn-success btn-block"
    }, "Go")))))), /* @__PURE__ */ React.createElement("div", {
      className: "col-sm-6 col-sm-offset-3"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "panel panel-default"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "panel-heading"
    }, /* @__PURE__ */ React.createElement("h3", {
      className: "panel-title"
    }, "Waivers")), /* @__PURE__ */ React.createElement("div", {
      className: "panel-body"
    }, /* @__PURE__ */ React.createElement("p", null, "View, add, update, and delete waiver entries."), /* @__PURE__ */ React.createElement("div", {
      className: "row"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "col-xs-6 col-xs-offset-3"
    }, /* @__PURE__ */ React.createElement(Link, {
      to: "/waivers",
      className: "btn btn-success btn-block"
    }, "Go"))))))));
  }
}
export default AdminHome;
