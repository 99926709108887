export default {
  providers: {
    targetCity: [],
    neighborCities: [
      {
        _id: '999',
        dental_id: '999',
        first_name: 'Max',
        middle_initial: '',
        last_name: 'Mustermann',
        prof_des: 'DDS',
        specialty: 'General Dentistry',
        group_name: 'Aspen Dental',
        location: '',
        address1: '42 Wallaby Way',
        address2: '',
        city: 'Sydney',
        state: 'AU',
        zip: '111',
        phone: '999-999-9999',
        fax: '999-999-9999',
        school: '',
        fee_shedule: 'aspen_gen_dentistry',
        admin_contact: 'Check under group 25671 Basix Discount',
        note: '',
        fee_schedule_name: 'Aspen General Dentistry',
        regions: 're_idaho',
      },
      {
        _id: '999',
        dental_id: '999',
        first_name: 'Max',
        middle_initial: '',
        last_name: 'Mustermann',
        prof_des: 'DDS',
        specialty: 'General Dentistry',
        group_name: 'Aspen Dental',
        location: '',
        address1: '42 Wallaby Way',
        address2: '',
        city: 'Sydney',
        state: 'AU',
        zip: '111',
        phone: '999-999-9999',
        fax: '999-999-9999',
        school: '',
        fee_shedule: 'aspen_gen_dentistry',
        admin_contact: 'Check under group 25671 Basix Discount',
        note: '',
        fee_schedule_name: 'Aspen General Dentistry',
        regions: 're_idaho',
      },
      {
        _id: '999',
        dental_id: '999',
        first_name: 'Max',
        middle_initial: '',
        last_name: 'Mustermann',
        prof_des: 'DMD',
        specialty: 'General Dentistry',
        group_name: 'Aspen Dental',
        location: '',
        address1: '42 Wallaby Way',
        address2: '',
        city: 'Sydney',
        state: 'AU',
        zip: '111',
        phone: '999-999-9999',
        fax: '999-999-9999',
        school: '',
        fee_shedule: 'aspen_gen_dentistry',
        admin_contact: 'Check under group 25671 Basix Discount',
        note: '',
        fee_schedule_name: 'Aspen General Dentistry',
        regions: 're_idaho',
      },
    ],
  },
  schoolPage: {
    schoolName: '',
    schoolCopy:
      'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    servicesProps: [
      {
        name: 'dental',
        state: '',
        btnLink: '',
        headerCopy: 'Find dental providers in your area',
      },
    ],
    headerLink: '/',
  },

  scheduleContent: {
    scheduleTableData: {
      feeSchedule: {
        _id: '123',
        scheduleName: '',
        columnHeaders: {
          first: 'ADA Codes',
          second: 'Service Description',
          third: 'Fee Amount',
        },
        services: [
          {
            codes: [
              {
                details: {
                  description: 'Periodic Oral Evaluation',
                  price: '$0',
                },
                code: '9999',
              },
              {
                details: {
                  description: 'Comprehensive Oral Evaluation',
                  price: '$0',
                },
                code: '9999',
              },
              {
                details: {
                  description:
                    'X-Rays Intraoral - Complete Series (Incl. Bitewings)',
                  price: '$0',
                },
                code: '9999',
              },
              {
                details: {
                  description: 'X-Rays Intraoral - Periapical - First Film',
                  price: '$0',
                },
                code: '9999',
              },
              {
                details: {
                  description:
                    'X-Rays Intraoral - Periapical - Each Additional Film',
                  price: '$0',
                },
                code: '9999',
              },
              {
                details: {
                  description: 'X-Rays - Bitewing - One Film',
                  price: '$0',
                },
                code: '9999',
              },
              {
                details: {
                  description: 'X-Rays - Bitewing - Two Films',
                  price: '$0',
                },
                code: '9999',
              },
              {
                details: {
                  description: 'X-Rays - Bitewing - Four Films',
                  price: '$0',
                },
                code: '9999',
              },
              {
                details: {
                  description: 'X-Ray Panoramic Film',
                  price: '$0',
                },
                code: '9999',
              },
            ],
            serviceName: 'DIAGNOSTIC',
          },
          {
            codes: [
              {
                details: {
                  description: 'Cleaning - Adult',
                  price: '$0',
                },
                code: '9999',
              },
              {
                details: {
                  description: 'Cleaning - Child',
                  price: '$0',
                },
                code: '9999',
              },
              {
                details: {
                  description:
                    'Topical Application of Fluoride (Incl. PX) - Child',
                  price: '$0',
                },
                code: '9999',
              },
              {
                details: {
                  description: 'Sealant - Per Tooth',
                  price: '$0',
                },
                code: '9999',
              },
            ],
            serviceName: 'PREVENTIVE',
          },
        ],
      },
      provider: {
        _id: '123',
        dental_id: '123123',
        first_name: 'James',
        middle_initial: 'M',
        last_name: 'Martin',
        prof_des: 'DMD',
        specialty: 'General Dentistry',
        group_name: 'Aspen Dental',
        location: '',
        address1: '42 Wallaby Way',
        address2: '',
        city: 'Sydney',
        state: 'AU',
        zip: '111',
        phone: '999-999-9999',
        fax: '999-999-9999',
        school: '',
        fee_shedule: 'aspen_gen_dentistry',
        admin_contact: 'Check under group 25671 Basix Discount',
        note: 'www.aspendental.com',
        fee_schedule_name: 'Aspen General Dentistry',
        regions: 're_newyork',
      },
    },
    btnDisabled: true,
    emailBtnCopy: 'Email me this page',
    emailBtnLink: 'blank',
    emailBtnClassName: 'mainBtn color-complement-0',
    printBtnCopy: 'Print this page',
    printBtnLink: 'blank',
    printBtnClassName: 'mainBtn color-complement-0',
  },
  feeSchedule: {
    dummySchedule: true,
    feeSchedule: {
      feeSchedule: {
        _id: '111',
        scheduleName: 'aspen_gen_dentistry',
        columnHeaders: {
          first: 'ADA Codes',
          second: 'Service Description',
          third: 'Fee Amount',
        },
        services: [
          {
            codes: [
              {
                details: {
                  description: 'Periodic Oral Evaluation',
                  price: '$0',
                },
                code: '000',
              },
              {
                details: {
                  description: 'Comprehensive Oral Evaluation',
                  price: '$0',
                },
                code: '000',
              },
              {
                details: {
                  description:
                    'X-Rays Intraoral - Complete Series (Incl. Bitewings)',
                  price: '$0',
                },
                code: '000',
              },
              {
                details: {
                  description: 'X-Rays Intraoral - Periapical - First Film',
                  price: '$0',
                },
                code: '000',
              },
              {
                details: {
                  description:
                    'X-Rays Intraoral - Periapical - Each Additional Film',
                  price: '$0',
                },
                code: '000',
              },
              {
                details: {
                  description: 'X-Rays - Bitewing - One Film',
                  price: '$0',
                },
                code: '000',
              },
              {
                details: {
                  description: 'X-Rays - Bitewing - Two Films',
                  price: '$0',
                },
                code: '000',
              },
              {
                details: {
                  description: 'X-Rays - Bitewing - Four Films',
                  price: '$0',
                },
                code: '000',
              },
              {
                details: {
                  description: 'X-Ray Panoramic Film',
                  price: '$0',
                },
                code: '000',
              },
            ],
            serviceName: 'DIAGNOSTIC',
          },
          {
            codes: [
              {
                details: {
                  description: 'Cleaning - Adult',
                  price: '$0',
                },
                code: '000',
              },
              {
                details: {
                  description: 'Cleaning - Child',
                  price: '$0',
                },
                code: '000',
              },
              {
                details: {
                  description:
                    'Topical Application of Fluoride (Incl. PX) - Child',
                  price: '$0',
                },
                code: '000',
              },
              {
                details: {
                  description: 'Sealant - Per Tooth',
                  price: '$0',
                },
                code: '000',
              },
            ],
            serviceName: 'PREVENTIVE',
          },
          {
            codes: [
              {
                details: {
                  description: 'Amalgam - 1 Surface, Permanent',
                  price: '$0',
                },
                code: '000',
              },
              {
                details: {
                  description: 'Amalgam - 2 Surfaces, Permanent',
                  price: '$0',
                },
                code: '000',
              },
              {
                details: {
                  description: 'Amalgam - 3 Surfaces, Permanent',
                  price: '$0',
                },
                code: '000',
              },
              {
                details: {
                  description: 'Amalgam - 4 or more Surfaces, Permanent',
                  price: '$0',
                },
                code: '000',
              },
              {
                details: {
                  description: 'Resin - 1 Surface, Anterior',
                  price: '$0',
                },
                code: '000',
              },
              {
                details: {
                  description: 'Resin - 2 Surfaces, Anterior',
                  price: '$0',
                },
                code: '000',
              },
              {
                details: {
                  description: 'Resin - 3 Surfaces, Anterior',
                  price: '$0',
                },
                code: '000',
              },
              {
                details: {
                  description: 'Resin - 4 Surfaces or Involving Incisal Angle',
                  price: '$0',
                },
                code: '000',
              },
              {
                details: {
                  description: 'Resin - 1 Surface, Posterior',
                  price: '$0',
                },
                code: '000',
              },
              {
                details: {
                  description: 'Resin - 2 Surfaces, Posterior',
                  price: '$0',
                },
                code: '000',
              },
              {
                details: {
                  description: 'Resin - 3 Surfaces, Posterior',
                  price: '$0',
                },
                code: '000',
              },
              {
                details: {
                  description: 'Resin - 4 or more Surfaces, Posterior',
                  price: '$0',
                },
                code: '000',
              },
              {
                details: {
                  description: 'Sedative Filling',
                  price: '$0',
                },
                code: '000',
              },
            ],
            serviceName: 'RESTORATIVE',
          },
          {
            codes: [
              {
                details: {
                  description: 'Pulp Cap - Direct Excluding Final Restoration',
                  price: '$0',
                },
                code: '000',
              },
              {
                details: {
                  description:
                    'Pulp Cap - Indirect Excluding Final Restoration',
                  price: '$0',
                },
                code: '000',
              },
              {
                details: {
                  description:
                    'Therapeutic Pulpotomy Excluding Final Restoration',
                  price: '$0',
                },
                code: '000',
              },
              {
                details: {
                  description:
                    'Root Canal Anterior Excluding Final Restoration',
                  price: '$0',
                },
                code: '000',
              },
              {
                details: {
                  description:
                    'Root Canal Bicuspid Excluding Final Restoration',
                  price: '$0',
                },
                code: '000',
              },
              {
                details: {
                  description: 'Root Canal Molar Excluding Final Restoration',
                  price: '$0',
                },
                code: '000',
              },
            ],
            serviceName: 'ENDODONTICS',
          },
          {
            codes: [
              {
                details: {
                  description: 'Gingivectomy or Gingivoplasty - Per Quad',
                  price: '$0',
                },
                code: '000',
              },
              {
                details: {
                  description:
                    'Peridontal Scaling & Root Planning - Per Quadrant',
                  price: '$0',
                },
                code: '000',
              },
              {
                details: {
                  description: 'Full Mouth Debridement',
                  price: '$0',
                },
                code: '000',
              },
              {
                details: {
                  description:
                    'Peridontal Maintenance Proc. (Following Active Therapy)',
                  price: '$0',
                },
                code: '000',
              },
            ],
            serviceName: 'PERIDONTICS',
          },
          {
            codes: [
              {
                details: {
                  description: 'Surgical Removal of Erupted Tooth',
                  price: '$0',
                },
                code: '000',
              },
              {
                details: {
                  description:
                    'Surgical Extraction - Removal of Impacted Tooth - Soft Tissue',
                  price: '$0',
                },
                code: '000',
              },
              {
                details: {
                  description:
                    'Surgical Extraction - Removal of Impacted Tooth - Partially Bony',
                  price: '$0',
                },
                code: '000',
              },
              {
                details: {
                  description:
                    'Surgical Extraction - Removal of Impacted Tooth - Completely Bony',
                  price: '$0',
                },
                code: '000',
              },
              {
                details: {
                  description:
                    'Surgical Extraction - Surgical Removal of Residual Roots',
                  price: '$0',
                },
                code: '000',
              },
              {
                details: {
                  description:
                    'Alveoplasty in Conjunction w/Extractions - Per Quad.',
                  price: '$0',
                },
                code: '000',
              },
              {
                details: {
                  description:
                    'Incision & Drainage of Abscess - Intraoral Soft Tissue',
                  price: '$0',
                },
                code: '000',
              },
            ],
            serviceName: 'ORAL SURGERY',
          },
          {
            price: '',
            description: [
              "Services not listed above shall be priced at 80% of the Provider's usual and customary fee.",
            ],
            codes: [],
            serviceName: 'OTHER SERVICES',
          },
        ],
        additionalInfo: {
          lines: [
            "Services not listed above shall be priced at 80% of the Provider's usual and customary fee.",
          ],
        },
      },
      provider: {
        _id: '1111',
        dental_id: '999999',
        first_name: 'Max',
        middle_initial: '',
        last_name: 'Mustermann',
        prof_des: 'DDS',
        specialty: 'General Dentistry',
        group_name: 'Aspen Dental',
        location: '',
        address1: '42 Wallaby Way',
        address2: '',
        city: 'Syney',
        state: 'AU',
        zip: '111',
        phone: '999-999-9999',
        school: '',
        fee_shedule: 'dummySchedule',
        admin_contact: '',
        note: '',
        fee_schedule_name: '',
        regions: '',
      },
    },
    provider: {
      address1: '42 Wallaby Way',
      address2: '',
      admin_contact: 'Dory',
      city: 'Sydney',
      dental_id: '001010',
      fax: '999-999-9999',
      fee_schedule_name: 'Modified Savings',
      fee_shedule: 'wv_Berlin',
      first_name: 'Max',
      group_name: 'M',
      last_name: 'Mustermann',
      location: '',
      middle_initial: 'M',
      note: 'None',
      phone: '999-999-9999',
      prof_des: 'DDS',
      regions: 're_Berlin',
      school: 'TU Berlin',
      specialty: 'General Dentistry',
      state: 'DE',
      zip: '12345',
      _id: '12345',
    },
  },
};
