import React, {Component} from "../../../../_snowpack/pkg/react.js";
import Actions from "../actions.js";
import Store from "../store.js";
import withTableRows from "../../SharedComponents/WithTableRows.js";
import withCategory from "../../SharedComponents/WithCategory.js";
const renderIf = (predicate) => (element) => predicate && element;
import ServiceCategories from "./ServiceCategories.js";
import ServiceName from "./ServiceName.js";
import ServiceRow from "./ServiceRow.js";
import Service from "./Service.js";
import Headers from "./Headers.js";
import ScheduleName from "./ScheduleName.js";
import NonTabularLine from "./NonTabularLine.js";
const HeadersHOC = withTableRows(Headers);
const ScheduleNameHOC = withTableRows(ScheduleName);
const NonTabularLineHOC = withTableRows(NonTabularLine);
const NonTabularCategoryHOC = withCategory(NonTabularLineHOC)(Actions);
const ScheduleNameCategoryHOC = withCategory(ScheduleNameHOC)(Actions);
const HeadersCategoryHOC = withCategory(HeadersHOC)(Actions);
class Schedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scheduleId: this.props.schedule._id,
      activateDownloadButton: false,
      downloadButtonRowClass: "hidden"
    };
    this.writeChange = this.writeChange.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
    this.generateExportFile = this.generateExportFile.bind(this);
    this.downloadExportFile = this.downloadExportFile.bind(this);
  }
  componentWillUpdate(nextProps, nextState) {
    if (nextState.activateDownloadButton === true) {
      if (this.state.downloadButtonRowClass === "hidden")
        this.setState({
          downloadButtonRowClass: "show"
        });
    }
  }
  writeChange(e) {
    e.preventDefault();
    Actions.writeChange(this.state.scheduleId);
  }
  deleteItem(e) {
    e.preventDefault();
    if (confirm("Are you sure you want to delete this provider entry?")) {
      Actions.deleteItem(this.state.scheduleId);
    }
  }
  generateExportFile(e) {
    e.preventDefault();
    let opts = {
      printNonTabularTextOnly: false,
      writeNonTabularInfoWithHtmlInfo: true,
      printColumnHeaders: true,
      printScheduleName: true,
      printNonTabularInfo: true,
      printServices: true
    };
    let state = Store.getState();
    let scheduleToExport = void 0;
    if (state.feeSchedules.feeScheduleChanges.length < 1) {
      scheduleToExport = state.feeSchedules.feeScheduleChanges.newScheduleChanges;
    }
    if (Object.keys(state.feeSchedules.feeScheduleChanges[0]).length > 1) {
      scheduleToExport = state.feeSchedules.feeScheduleChanges[0];
    }
    Actions.generateScheduleExport(scheduleToExport, opts);
    this.setState({
      activateDownloadButton: true
    });
  }
  downloadExportFile() {
    let state = Store.getState();
    let data = state.feeSchedules.scheduleExportString;
    let filename = state.feeSchedules.scheduleExportFilename;
    let blob = new Blob([data], {type: "application/octet-stream"});
    if (typeof window.navigator.msSaveBlob !== "undefined") {
      window.navigator.msSaveBlob(blob, filename);
    } else {
      let blobURL = window.URL.createObjectURL(blob);
      let tempLink = document.createElement("a");
      tempLink.style.display = "none";
      tempLink.href = blobURL;
      tempLink.setAttribute("download", filename);
      if (typeof tempLink.download === "undefined") {
        tempLink.setAttribute("target", "_blank");
      }
      document.body.appendChild(tempLink);
      tempLink.click();
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(blobURL);
    }
  }
  render() {
    return /* @__PURE__ */ React.createElement("div", {
      className: "row individualProviderTable subSectionPadding"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "col-xs-12"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "row margin buttonRow"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "col-xs-6"
    }, /* @__PURE__ */ React.createElement("button", {
      className: "changeButton btn btn-success",
      onClick: this.writeChange
    }, "Save changes to DB")), /* @__PURE__ */ React.createElement("div", {
      className: "col-xs-6"
    }, /* @__PURE__ */ React.createElement("button", {
      className: "changeButton pull-right btn btn-danger",
      onClick: this.deleteItem
    }, "Delete schedule"))), /* @__PURE__ */ React.createElement("div", {
      className: "row margin buttonRow"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "col-xs-6 "
    }, /* @__PURE__ */ React.createElement("button", {
      className: "btn btn-success",
      onClick: this.generateExportFile
    }, " Generate Schedule Export File "))), /* @__PURE__ */ React.createElement("div", {
      className: this.state.downloadButtonRowClass + " row margin buttonRow"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "col-xs-6 "
    }, /* @__PURE__ */ React.createElement("button", {
      className: "btn btn-success",
      onClick: this.downloadExportFile
    }, " Download Schedule Export File "))), /* @__PURE__ */ React.createElement("div", {
      className: "row "
    }, /* @__PURE__ */ React.createElement("div", {
      className: "col-xs-12"
    }, /* @__PURE__ */ React.createElement("div", {
      className: `table${this.props.tableIndex}`
    }, /* @__PURE__ */ React.createElement("div", {
      className: "scheduleSection"
    }, /* @__PURE__ */ React.createElement("h4", null, "Schedule header information"), /* @__PURE__ */ React.createElement(ScheduleNameCategoryHOC, {
      data: {scheduleName: this.props.schedule.scheduleName},
      _id: this.props.schedule._id,
      dataType: "scheduleName",
      itemIndex: "scheduleName"
    }), /* @__PURE__ */ React.createElement(HeadersCategoryHOC, {
      data: {columnHeaders: this.props.schedule.columnHeaders},
      _id: this.props.schedule._id,
      dataType: "columnHeaders"
    })), /* @__PURE__ */ React.createElement("div", {
      className: "scheduleSection"
    }, /* @__PURE__ */ React.createElement("h4", null, "Schedule categories"), /* @__PURE__ */ React.createElement(ServiceCategories, {
      data: {serviceCategories: this.props.schedule.services},
      dataType: "services",
      _id: this.props.schedule._id
    }))), /* @__PURE__ */ React.createElement("div", {
      className: "scheduleSection"
    }, /* @__PURE__ */ React.createElement("h4", null, "Non-tabular information"), /* @__PURE__ */ React.createElement(NonTabularCategoryHOC, {
      data: {html: this.props.schedule.nonTabularInfo.html},
      dataType: "nonTabularInfo",
      _id: this.props.schedule._id
    }))))));
  }
}
export default Schedule;
