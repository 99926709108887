import React, {Component} from "../../_snowpack/pkg/react.js";
import Footer from "../components/Footer.js";
import Header from "../components/Header.js";
import ScheduleContent from "../components/ScheduleContent.js";
import Ajax from "../utils/ajax.js";
import Analytics from "../utils/GA-methods.js";
const EmailConfirmation = (props) => {
  return /* @__PURE__ */ React.createElement("h1", null, props.message);
};
class Schedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      schoolName: "",
      scheduleTableData: "",
      printBtnLink: "blank",
      printBtnCopy: "Print this page",
      userEmailAddress: "",
      reset: false,
      schoolInfo: {
        schoolName: void 0,
        schoolState: void 0,
        broker: void 0
      }
    };
    this.printScreen = this.printScreen.bind(this);
    this.revealEmailForm = this.revealEmailForm.bind(this);
    this.sendEmail = this.sendEmail.bind(this);
    this.onEmailChange = this.onEmailChange.bind(this);
  }
  printScreen(e) {
    e.preventDefault();
    let providerInfo = this.state.scheduleTableData.provider;
    let GTMPayload = Analytics.buildFeeScheduleInteraction(providerInfo);
    Analytics.send("feeSchedulePrint", GTMPayload);
    window.print();
  }
  revealEmailForm() {
    this.setState({
      emailDivClass: ""
    });
  }
  sendEmail() {
    const scheduleHeader = document.getElementById("provider-name");
    const scheduleBody = document.getElementById("schedule");
    const emailSubjectLine = `Fee Schedule for ${scheduleHeader.innerText}`;
    const emailBody = scheduleHeader.innerHTML + scheduleBody.outerHTML;
    return Ajax.emailSchedule(emailBody, emailSubjectLine, this.state.userEmailAddress).then((response) => {
      if (response.status === 200) {
        this.setState({
          emailStatusMessage: "Email sent!"
        }, () => {
          setTimeout(() => {
            this.setState({
              emailDivClass: "hide",
              userEmailAddress: "",
              emailStatusMessage: ""
            });
          }, 2e3);
        });
      }
      let providerInfo = this.state.scheduleTableData.provider;
      let GTMPayload = Analytics.buildFeeScheduleInteraction(providerInfo);
      Analytics.send("feeScheduleEmail", GTMPayload);
    }).catch((error) => {
      this.setState({
        emailStatusMessage: "Sorry, the email address you entered was invalid. Please try again.",
        userEmailAddress: "",
        reset: true
      }, () => {
        this.setState({reset: false});
      });
    });
  }
  onEmailChange(e) {
    this.setState({
      userEmailAddress: e.target.value
    });
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    const {schoolName, providerType, providerID} = this.props.match.params;
    Ajax.getSchool(schoolName).then((response) => {
      const responseData = response.data.schoolData;
      const schoolStateObj = this.state.schoolInfo;
      const schoolInfo = Object.assign({}, schoolStateObj, {
        schoolName: responseData.name,
        schoolState: responseData.state,
        broker: responseData.broker
      });
      this.setState({schoolInfo});
    });
    Ajax.getProviderAndFeeSchedule(schoolName, providerType, providerID).then((result) => {
      const {
        scheduleTableData,
        eligibilityCopy,
        eligibilityNumber,
        scheduleTableData: {provider}
      } = result.data;
      this.setState({scheduleTableData, eligibilityCopy, eligibilityNumber}, () => {
        const GTMPayload = Analytics.buildProviderInfo(provider);
        Analytics.send("providerVisit", GTMPayload);
      });
    });
  }
  render() {
    const {history} = this.props;
    console.log(this.props);
    return /* @__PURE__ */ React.createElement(ScheduleContent, {
      componentName: "ScheduleContent",
      match: this.props.match,
      eligibilityCopy: this.state.eligibilityCopy,
      eligibilityNumber: this.state.eligibilityNumber,
      onEmailChange: this.onEmailChange,
      sendEmail: this.sendEmail,
      printScreen: this.printScreen,
      reset: this.state.reset,
      scheduleTableData: this.state.scheduleTableData,
      userEmailAddress: this.state.userEmailAddress,
      emailStatusMsg: this.state.emailStatusMessage
    });
  }
}
export default Schedule;
