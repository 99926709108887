import React, {Component} from "../../_snowpack/pkg/react.js";
import {Link} from "../../_snowpack/pkg/react-router-dom.js";
import Ajax from "../utils/ajax.js";
import DOMPurify from "../../_snowpack/pkg/dompurify.js";
const {sanitize} = DOMPurify;
class Main extends Component {
  constructor(props) {
    super(props);
    this.getSchoolList = this.getSchoolList.bind(this);
  }
  getSchoolList() {
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.getSchoolList();
  }
  renderSchools() {
    return this.props.schoolMap.map((s, i) => {
      const {img, cls, title, mainLink, waiverLink} = s;
      const {waiverAccepted} = this.props;
      const link = waiverAccepted ? mainLink : waiverLink;
      return /* @__PURE__ */ React.createElement("li", {
        className: "school-link card"
      }, /* @__PURE__ */ React.createElement(Link, {
        className: "no-decoration",
        to: link
      }, /* @__PURE__ */ React.createElement("img", {
        className: cls,
        src: img,
        alt: `${title} logo`
      }), /* @__PURE__ */ React.createElement("p", null, title)));
    });
  }
  render() {
    return /* @__PURE__ */ React.createElement("div", {
      className: "main-page-container"
    }, /* @__PURE__ */ React.createElement("section", {
      className: "school-section"
    }, /* @__PURE__ */ React.createElement("h2", {
      className: "h2 school-select"
    }, "Select your school to get started"), /* @__PURE__ */ React.createElement("ul", {
      className: "school-list"
    }, this.renderSchools())), /* @__PURE__ */ React.createElement("div", {
      className: "cta"
    }, /* @__PURE__ */ React.createElement("p", {
      className: "cta-text"
    }, "How does the ", /* @__PURE__ */ React.createElement("strong", null, "BASIX Student Health Discount Program"), " ", "work?"), /* @__PURE__ */ React.createElement(Link, {
      style: {marginBottom: "var(--size-10)"},
      className: "btn-link",
      to: "/how-to-use"
    }, "Learn more")));
  }
}
export default Main;
