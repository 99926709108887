import React, {Component} from "../../../../_snowpack/pkg/react.js";
import Actions from "../actions.js";
import Select from "../../SharedComponents/Select.js";
class CreateProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedDB: "dental",
      providerCloningParams: {
        _id: ""
      },
      hideCreateProviderRowBtn: "hidden",
      createProviderRow: "hidden"
    };
    this.showHideRowAndButton = this.showHideRowAndButton.bind(this);
    this.createProvider = this.createProvider.bind(this);
    this.updateDBSelection = this.updateDBSelection.bind(this);
    this.updateProviderCloneSelection = this.updateProviderCloneSelection.bind(this);
  }
  showHideRowAndButton(e, toggleType, rowName, btnName) {
    e.preventDefault();
    this.setState({
      [rowName]: toggleType,
      [btnName]: toggleType
    });
  }
  updateDBSelection(selection) {
    this.setState({
      selectedDB: selection
    });
  }
  updateProviderCloneSelection(selection, paramType) {
    let providerCloningParamsUpdate = this.state.providerCloningParams;
    providerCloningParamsUpdate[paramType] = selection;
    this.setState({
      providerCloningParams: providerCloningParamsUpdate
    });
  }
  createProvider(e, newOrClone) {
    e.preventDefault();
    Actions.clearSearchAndChanges().then(() => {
      if (newOrClone === "clone") {
        Actions.createProvider("clone", {
          db: this.state.selectedDB,
          queries: [{
            field: "_id",
            query: this.state.providerCloningParams._id,
            hasWildCardSettings: false
          }]
        });
      } else {
        Actions.createProvider("new", this.state.selectedDB);
      }
    }).catch();
  }
  render() {
    return /* @__PURE__ */ React.createElement("div", {
      className: "row margin subSectionPadding"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "col-xs-12"
    }, /* @__PURE__ */ React.createElement("h2", null, "Add a new provider")), /* @__PURE__ */ React.createElement("div", {
      className: "col-xs-3"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "row "
    }, /* @__PURE__ */ React.createElement("div", {
      className: "col-xs-12"
    }, /* @__PURE__ */ React.createElement("form", {
      className: "form-inline margin"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "form-group row"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "col-xs-5"
    }, /* @__PURE__ */ React.createElement("button", {
      className: "btn btn-primary",
      onClick: (e) => {
        this.showHideRowAndButton(e, "show", "createProviderRow", "hideCreateProviderRowBtn");
      }
    }, " Show panel")), /* @__PURE__ */ React.createElement("div", {
      className: this.state.hideExportProviderRowBtn + " col-xs-4"
    }, /* @__PURE__ */ React.createElement("button", {
      className: this.state.hideCreateProviderRowBtn + " btn btn-warning",
      onClick: (e) => {
        this.showHideRowAndButton(e, "hide", "createProviderRow", "hideCreateProviderRowBtn");
      }
    }, " Hide panel"))))))), /* @__PURE__ */ React.createElement("div", {
      className: "col-xs-8"
    }, /* @__PURE__ */ React.createElement("div", {
      className: this.state.createProviderRow + " row"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "row subSectionPadding margin"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "col-xs-12"
    }, /* @__PURE__ */ React.createElement("h3", null, "Create a blank provider entry")), /* @__PURE__ */ React.createElement("div", {
      className: "col-xs-12"
    }, /* @__PURE__ */ React.createElement("form", {
      className: "form-inline"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "form-group"
    }, /* @__PURE__ */ React.createElement(Select, {
      options: this.props.dbOptions,
      selectedValue: this.state.selectedDB,
      placeholder: "What type of provider?",
      updateSelection: this.updateDBSelection
    })), /* @__PURE__ */ React.createElement("div", {
      className: "form-group"
    }, /* @__PURE__ */ React.createElement("button", {
      className: "btn btn-success",
      onClick: (e) => {
        this.createProvider(e, "new");
      }
    }, " Create "))))), /* @__PURE__ */ React.createElement("div", {
      className: "row subSectionPadding margin"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "col-xs-12"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "row"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "col-xs-12"
    }, /* @__PURE__ */ React.createElement("h3", null, "Clone an existing provider entry"), /* @__PURE__ */ React.createElement("div", {
      className: "row"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "col-xs-12"
    }, "To clone an existing provider entry, enter in the existing provider's database and id number below:", /* @__PURE__ */ React.createElement("form", {
      className: "form-inline marginTop"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "form-group"
    }, /* @__PURE__ */ React.createElement(Select, {
      options: this.props.dbOptions,
      placeholder: "Which database?",
      updateSelection: this.updateDBSelection,
      selectedValue: this.state.selectedDB,
      paramType: "db"
    })), /* @__PURE__ */ React.createElement("div", {
      className: "form-group"
    }, /* @__PURE__ */ React.createElement("input", {
      placeholder: "Provider id number",
      className: "form-control inputBox",
      onKeyUp: (e) => {
        this.updateProviderCloneSelection(e.target.value, "_id");
      }
    }))))), /* @__PURE__ */ React.createElement("div", {
      className: "row marginTop"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "col-xs-12"
    }, /* @__PURE__ */ React.createElement("button", {
      className: "search btn btn-success",
      onClick: (e) => {
        this.createProvider(e, "clone");
      }
    }, "Create"))))))))));
  }
}
export default CreateProvider;
