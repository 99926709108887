import React, {Component, Fragment} from "../../_snowpack/pkg/react.js";
import {Link} from "../../_snowpack/pkg/react-router-dom.js";
import Button from "../components/Button.js";
import Ajax from "../utils/ajax.js";
class Waiver extends Component {
  constructor(props) {
    super(props);
    const {schoolKey} = this.props;
    this.state = {
      waiver: null,
      acceptLink: `/${schoolKey}/main`,
      declineLink: "/"
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    const {schoolKey} = this.props;
    Ajax.getWaiver(schoolKey).then((response) => {
      this.setState({
        waiver: response.data.lines
      });
    });
  }
  renderWaiverCopy = () => {
    const {waiver} = this.state;
    if (!waiver) {
      return null;
    }
    return waiver.map((line, index) => {
      const {tagName: Tag, text} = line;
      return /* @__PURE__ */ React.createElement(Tag, {
        key: index
      }, text);
    });
  };
  acceptWaiver = () => {
    Ajax.logClientIP();
    window.localStorage.setItem("basixStudentWaiverAcceptance", true);
    this.props.setWaiverAccepted(true);
  };
  renderContent = () => {
    if (!this.state.waiver) {
      return null;
    }
    return /* @__PURE__ */ React.createElement(Fragment, null, /* @__PURE__ */ React.createElement("div", {
      className: "main-page-container"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "card waiver"
    }, /* @__PURE__ */ React.createElement("h1", null, "Program Waiver"), /* @__PURE__ */ React.createElement("p", null, "To use our program, you must accept the terms of the waiver."), /* @__PURE__ */ React.createElement("div", {
      style: {textAlign: "justify", marginBottom: "var(--scale-2)"}
    }, this.renderWaiverCopy()), /* @__PURE__ */ React.createElement(Link, {
      className: "btn-link mb-0",
      to: this.props.nextLink,
      onClick: this.acceptWaiver
    }, "Accept"), /* @__PURE__ */ React.createElement(Link, {
      className: "btn-link",
      to: "/"
    }, "Reject"))));
  };
  render() {
    return /* @__PURE__ */ React.createElement(Fragment, null, this.renderContent());
  }
}
export default Waiver;
