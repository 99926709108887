import Constants from "../constants.js"
import cloneDeep from '../../../../_snowpack/pkg/lodash.clonedeep.js'
import scheduleExporter from "../exportSchedule.js";

const initialState = {
	searchResults: [],
	feeScheduleChanges: [],
	newSchedule: {},
	newScheduleChanges: {},
	scheduleBrowsingList: []
};

const reducer = function(state = initialState, action) {
	if (action.response) {
		if (action.response.message) {
			handleError(action.response.message);
		}
	}

	if (action.type === Constants.CLEAR_SEARCH_AND_CHANGES) {
		return Object.assign({}, state, {
			searchResults: [],
			feeScheduleChanges: [],
			newSchedule: {},
			newScheduleChanges: {}
		});
	}

	if (action.type === Constants.SEND_UPDATE_RESPONSE) {
		const { _id, save, scheduleName, type } = action.response;
		const msg = `SAVE STATUS: ${save}.\nSAVE TYPE: ${type}.\nscheduleName: ${scheduleName}.\nscheduleId: ${_id}.`;
		alert(msg);
	}

	if (action.type === Constants.GET_SCHEDULES_LIST_RESPONSE) {

		console.log('reducer is going off', action.response)
		return Object.assign({}, state, {
			scheduleBrowsingList: action.response.scheduleList,
			tableColumns: action.response.tableColumns
		});
	}

	if (action.type === Constants.CLEAR_BROWSING_LIST) {
		return Object.assign({}, state, {
			scheduleBrowsingList: []
		});
	}

	if (action.type === Constants.GET_SCHEDULES_RESPONSE) {
		if (
			Object.keys(state.newSchedule).length > 0 ||
			Object.keys(state.newScheduleChanges).length > 0
		) {
			if (
				confirm(
					"Do you want to search for existing fee schedules? Any unsaved changes will be lost"
				)
			) {
				return Object.assign({}, state, {
					searchResults: action.response.feeSchedules,
					feeScheduleChanges: action.response.feeSchedules,
					newSchedule: {},
					newScheduleChanges: {}
				});
			}
		} else {
			return Object.assign({}, state, {
				searchResults: action.response.feeSchedules,
				feeScheduleChanges: action.response.feeSchedules
			});
		}
	}

	if (action.type === Constants.REPORT_CHANGE) {
		function editSchedule(change, scheduleType) {
			let editedFeeSchedule = undefined;
			let feeScheduleUpdate = undefined;

			if (scheduleType === "new") {
				feeScheduleUpdate = cloneDeep(state.newScheduleChanges);
			} else if (scheduleType === "existing") {
				editedFeeSchedule = cloneDeep(state.feeScheduleChanges);
				editedFeeSchedule = editedFeeSchedule.filter(schedule => {
					return schedule._id === change._id;
				});
				feeScheduleUpdate = Object.assign({}, editedFeeSchedule[0]);
			}

			switch (change.dataType) {
				case "scheduleName":
					feeScheduleUpdate.scheduleName = change.currentValue.scheduleName;
					break;
				case "nonTabularInfo":
					feeScheduleUpdate.nonTabularInfo.html = change.currentValue.html;
					break;
				case "services":
					feeScheduleUpdate.services = change.currentValue;
					break;
				case "columnHeaders":
					feeScheduleUpdate.columnHeaders = change.currentValue.columnHeaders;
					break;
			}
			return feeScheduleUpdate;
		}

		if (action.change._id === "new") {
			return Object.assign({}, state, {
				newScheduleChanges: editSchedule(action.change, "new")
			});
		} else {
			return Object.assign({}, state, {
				feeScheduleChanges: state.feeScheduleChanges.map(schedule => {
					if (schedule._id === action.change._id) {
						return editSchedule(action.change, "existing");
					} else {
						return schedule;
					}
				})
			});
		}
	}

	if (action.type === Constants.SEND_DELETION_RESPONSE) {
		function deleteItem(item) {
			return item._id !== action.response.recordDeleted;
		}
		return Object.assign({}, state, {
			feeScheduleChanges: state.feeScheduleChanges.filter(deleteItem),
			searchResults: state.searchResults.filter(deleteItem)
		});
	}

	if (action.type === Constants.CREATE_NEW_SCHEDULE) {
		let newScheduleTemplate = {
			_id: "new",
			scheduleName: "new schedule",
			columnHeaders: {
				first: "ADA Codes",
				second: "Service Description",
				third: "Fee amount"
			},
			services: [
				{
					serviceName: "new service",
					codes: [
						{
							code: "999",
							details: {
								description: "new code",
								price: "$999"
							}
						}
					]
				}
			],
			nonTabularInfo: {
				html: ""
			}
		};
		return createNewSchedule(newScheduleTemplate);
	}

	if (action.type === Constants.CREATE_CLONE_SCHEDULE_RESPONSE) {
		action.response.feeSchedules[0]._id = "new";
		action.response.feeSchedules[0].scheduleName = "new_schedule";
		return createNewSchedule(action.response.feeSchedules[0]);
	}

	if (action.type === Constants.GENERATE_SCHEDULE_EXPORT) {
		scheduleExporter.export(
			action.scheduleToExport,
			action.opts,
			action.Store,
			Constants
		);
	}

	if (action.type === Constants.GENERATE_SCHEDULE_EXPORT_RESPONSE) {
		return Object.assign({}, state, {
			scheduleExportString: action.scheduleExportString,
			scheduleExportFilename: action.scheduleExportFilename,
			activateDownloadButton: true
		});
	}

	if (action.type === Constants.IMPORT_SCHEDULE_RESPONSE) {
		return createNewSchedule(action.processedSchedule);
	}

	function createNewSchedule(newScheduleTemplate) {
		if (state.feeScheduleChanges.length > 0 || state.searchResults.length > 0) {
			if (
				confirm(
					"Do you want to create a new schedule? Any unsaved changes will be lost"
				)
			) {
				return Object.assign({}, state, {
					newSchedule: newScheduleTemplate,
					newScheduleChanges: newScheduleTemplate,
					feeScheduleChanges: [],
					searchResults: []
				});
			}
		} else {
			return Object.assign({}, state, {
				newSchedule: newScheduleTemplate,
				newScheduleChanges: newScheduleTemplate
			});
		}
	}

	function handleError(errorMsg) {
		if (errorMsg === "no results" || errorMsg === "schedules not found") {
			alert("There were no results for the specified search terms");
			return false;
		}
		if (errorMsg === "error") {
			alert(
				"There was a problem with your search, please check your search terms"
			);
			return false;
		}
	}

	return state;
};

export default reducer;
