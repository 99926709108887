import React, {Component} from "../../_snowpack/pkg/react.js";
import Dummy from "../utils/Dummy.js";
const dummyFeeSchedule = Dummy.feeSchedule;
const dummyScheduleContent = Dummy.scheduleContent;
const dummySchoolContent = Dummy.schoolPage;
const withPageLoading = (propName) => (WrappedComponent) => {
  return class withPageLoading extends Component {
    constructor(props) {
      super(props);
      this.isEmpty = this.isEmpty.bind(this);
      this.state = {
        dummyFeeSchedule,
        dummyScheduleContent,
        dummySchoolContent
      };
    }
    isEmpty(prop) {
      if (prop === null || prop === void 0 || prop.hasOwnProperty("length") && prop.length === 0 || prop.constructor === Object && Object.keys(prop).length === 0) {
        return true;
      } else {
        return false;
      }
    }
    render() {
      if (this.isEmpty(this.props[propName])) {
        if (this.props.componentName === "FeeSchedule") {
          return /* @__PURE__ */ React.createElement("div", {
            className: "loading"
          }, /* @__PURE__ */ React.createElement("div", {
            className: "loadScreenOverlay"
          }), /* @__PURE__ */ React.createElement(WrappedComponent, {
            data: this.state.dummyFeeSchedule
          }));
        } else if (this.props.componentName === "ScheduleContent") {
          return /* @__PURE__ */ React.createElement("div", {
            className: "loading"
          }, /* @__PURE__ */ React.createElement("div", {
            className: "loadScreenOverlay"
          }), /* @__PURE__ */ React.createElement(WrappedComponent, {
            ...this.state.dummyScheduleContent
          }));
        } else if (this.props.componentName === "SchoolContent") {
          return /* @__PURE__ */ React.createElement("div", {
            className: "loading"
          }, /* @__PURE__ */ React.createElement("div", {
            className: "loadScreenOverlay"
          }), /* @__PURE__ */ React.createElement(WrappedComponent, {
            ...this.state.dummySchoolContent
          }));
        }
      } else if (!this.isEmpty(this.props[propName])) {
        return /* @__PURE__ */ React.createElement(WrappedComponent, {
          ...this.props
        });
      }
    }
  };
};
export default withPageLoading;
