import React, {Component} from "../../_snowpack/pkg/react.js";
import Button from "./Button.js";
class SearchArea extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const {
      aria,
      btnWrapperCls,
      btnDisabled,
      btnClassName,
      btnLink,
      onClick,
      btnCopy,
      linkCls,
      wrapperCls
    } = this.props.searchArea;
    return /* @__PURE__ */ React.createElement("div", {
      className: wrapperCls
    }, this.props.children, /* @__PURE__ */ React.createElement("div", {
      style: {marginTop: "var(--size-4)"}
    }, /* @__PURE__ */ React.createElement(Button, {
      aria,
      disabled: btnDisabled,
      className: "btn-link filled",
      link: btnLink,
      linkCls,
      clickHandler: onClick,
      linkCopy: "Update Search"
    })));
  }
}
export default SearchArea;
