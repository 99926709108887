import FluxConstant from '../../../_snowpack/pkg/flux-constant.js'

export default FluxConstant.set([
  'GET_PROVIDERS',
  'GET_PROVIDERS_RESPONSE',
  'REPORT_CHANGE',
  'SEND_UPDATE',
  'SEND_UPDATE_RESPONSE',
  'SEND_DELETION',
  'SEND_DELETION_RESPONSE',
  'CREATE_NEW_PROVIDER',
  'CREATE_NEW_PROVIDER_RESPONSE',
  'CREATE_CLONE_PROVIDER_RESPONSE',
  'CREATE_CLONE_PROVIDER_RESPONSE',
  'NO_CITY_OR_STATE_ERR',
  'RESET_ERRORS',
  'GET_PROVIDERS_LIST',
  'GET_PROVIDERS_LIST_RESPONSE',
  'CLEAR_PROVIDERS_LIST',
  'CLEAR_SEARCH_AND_CHANGES',
  'GET_PROVIDERS_EXPORT',
  'GET_PROVIDERS_EXPORT_RESPONSE'

]);
