import FluxConstant from '../../../_snowpack/pkg/flux-constant.js'

const constants = FluxConstant.set([
  'GET_PROVIDERS',
  'GET_PROVIDERS_RESPONSE',
  'GET_USER',
  'GET_USER_RESPONSE',
  'HIDE_DETAILS_SAVE_SUCCESS',
  'HIDE_PASSWORD_SAVE_SUCCESS',
  'HIDE_USER_SAVE_SUCCESS',
  'SAVE_DETAILS',
  'SAVE_DETAILS_RESPONSE',
  'SAVE_PASSWORD',
  'SAVE_PASSWORD_RESPONSE',
  'SAVE_USER',
  'SAVE_USER_RESPONSE',
  'REPORT_CHANGE',
  'SEND_UPDATE',
  'SEND_UPDATE_RESPONSE',
  'SEND_DELETION',
  'SEND_DELETION_RESPONSE',
  'CREATE_SCHOOL',
  'CREATE_SCHOOL_RESPONSE',
  'NO_CITY_OR_STATE_ERR',
  'RESET_ERRORS',
  'GET_SCHOOL_LIST',
  'GET_SCHOOL_LIST_RESPONSE',
  'GET_SCHOOL',
  'GET_SCHOOL_RESPONSE',
  'SEND_SCHOOL_UPDATE',
  'SEND_SCHOOL_UPDATE_RESPONSE',
  'INCOMPLETE_SCHOOL_INFO',
  'CLEAR_SEARCH_AND_CHANGES'
]);

export default constants;