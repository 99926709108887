import React, {Component} from "../../../../../_snowpack/pkg/react.js";
import Actions from "../../actions.js";
import Store from "../../store.js";
import Select from "../../../SharedComponents/Select.js";
class SearchSchedules extends Component {
  constructor(props) {
    super(props);
    let store = Store.getState();
    this.state = {
      fieldOptions: [
        {key: "_id", label: "ID number"},
        {key: "scheduleName", label: "Schedule name"}
      ],
      scheduleSearchParams: {
        db: "schedules",
        field: "_id",
        query: ""
      },
      feeSchedules: store.feeSchedules
    };
    this.updateScheduleSearchParams = this.updateScheduleSearchParams.bind(this);
    this.getSchedules = this.getSchedules.bind(this);
    this.checkForCompleteSearchParams = this.checkForCompleteSearchParams.bind(this);
  }
  componentDidMount() {
    this.unsubscribeStore = Store.subscribe(this.onStoreChange.bind(this));
  }
  onStoreChange() {
    this.setState(Store.getState());
  }
  componentWillUnmount() {
    this.unsubscribeStore();
  }
  updateScheduleSearchParams(selection, paramType) {
    let scheduleSearchParamsUpdate = this.state.scheduleSearchParams;
    scheduleSearchParamsUpdate[paramType] = selection;
    this.setState({
      scheduleSearchParams: scheduleSearchParamsUpdate
    });
  }
  getSchedules(e) {
    e.preventDefault();
    if (this.checkForCompleteSearchParams(this.state.scheduleSearchParams)) {
      Actions.clearSearchAndChanges().then(() => {
        Actions.getFeeSchedules({
          db: "schedules",
          field: this.state.scheduleSearchParams.field,
          query: this.state.scheduleSearchParams.query
        });
      }).catch();
    }
  }
  checkForCompleteSearchParams(paramObj) {
    for (let key in paramObj) {
      if (paramObj.hasOwnProperty(key)) {
        if (paramObj[key] === "") {
          alert("Please enter all search terms");
          return false;
        }
      }
    }
    return true;
  }
  render() {
    return /* @__PURE__ */ React.createElement("div", {
      className: "row margin subSectionPadding"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "col-xs-12"
    }, /* @__PURE__ */ React.createElement("h2", null, "Search for existing schedules")), /* @__PURE__ */ React.createElement("form", {
      className: "form-inline col-xs-12"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "form-group"
    }, /* @__PURE__ */ React.createElement("label", {
      className: ""
    }, "Search field"), /* @__PURE__ */ React.createElement(Select, {
      options: this.state.fieldOptions,
      placeholder: "Which field?",
      updateSelection: this.updateScheduleSearchParams,
      paramType: "field"
    })), /* @__PURE__ */ React.createElement("div", {
      className: "form-group"
    }, /* @__PURE__ */ React.createElement("label", {
      className: ""
    }, "Search term"), /* @__PURE__ */ React.createElement("input", {
      placeholder: "Please enter search term",
      className: "form-control inputBox",
      onKeyUp: (e) => {
        this.updateScheduleSearchParams(e.target.value, "query");
      }
    })), /* @__PURE__ */ React.createElement("div", {
      className: "form-group"
    }, /* @__PURE__ */ React.createElement("button", {
      className: "search btn btn-primary",
      onClick: (e) => {
        this.getSchedules(e);
      }
    }, "Search!"))));
  }
}
export default SearchSchedules;
