import FluxConstant  from '../../../_snowpack/pkg/flux-constant.js'


export default FluxConstant.set([
  'GET_SCHEDULES',
  'GET_SCHEDULES_RESPONSE',
  'SAVE_DETAILS',
  'SAVE_DETAILS_RESPONSE',
  'REPORT_CHANGE',
  'SEND_UPDATE',
  'SEND_UPDATE_RESPONSE',
  'SEND_DELETION',
  'SEND_DELETION_RESPONSE',
  'CREATE_NEW_SCHEDULE',
  'CREATE_CLONE_SCHEDULE_RESPONSE',
  'GENERATE_SCHEDULE_EXPORT',
  'GENERATE_SCHEDULE_EXPORT_RESPONSE',
  'GET_SCHEDULES_LIST',
  'GET_SCHEDULES_LIST_RESPONSE',
  'CLEAR_BROWSING_LIST',
  'IMPORT_SCHEDULE',
  'IMPORT_SCHEDULE_RESPONSE',
  'CLEAR_SEARCH_AND_CHANGES'

]);
