import DOMPurify from '../../_snowpack/pkg/dompurify.js'

function GAPayLoad(eventType) {
  this.event = eventType;
  this.populate = function (tagArray) {
    for (let tag in tagArray) {
      let tagVariableName = Object.keys(tagArray[tag]);
      let tagVariableValue = tagArray[tag][Object.keys(tagArray[tag])];
      this[tagVariableName] = tagVariableValue;
    }
  };
}

export default {
  send: function (eventType, eventData) {
    let payload = new GAPayLoad(eventType);
    payload.populate(eventData);
    window.dataLayer.push(payload);
  },
  buildPayload: function (infoObj) {
    let payloadArray = [];
    for (let dataPoint in infoObj) {
      if (infoObj[dataPoint] !== '') {
        let dataPointObj = {};
        let tagVariableName = dataPoint;
        // tagVariableName = DOMPurify.sanitize(tagVariableName);
        let tagVariableValue = infoObj[dataPoint];
        // tagVariableValue = DOMPurify.sanitize(tagVariableValue);
        dataPointObj[tagVariableName] = tagVariableValue;
        payloadArray.push(dataPointObj);
      }
    }
    return payloadArray;
  },
  buildProviderInfo: function (providerInfo) {
    // commented out fields which aren't being used now, but which may be used in the future
    let info = {
      // providerID: providerInfo._id,
      providerState: providerInfo.state,
      providerName: '',
      providerLocation: '',
      // providerAddress: '',
      providerSpecialty: '',
    };
    if (providerInfo.location) {
      info.providerLocation = providerInfo.location;
    }
    if (providerInfo.first_name) {
      if (providerInfo.last_name) {
        info.providerName = `${providerInfo.first_name} ${providerInfo.last_name}`;
      } else if (!providerInfo.last_name) {
        info.providerName = providerInfo.first_name;
      }
    }
    if (!providerInfo.first_name) {
      // not all providers have names (health clubs, eyewear clinics)
      info.providerName = providerInfo.location;
    }
    if (providerInfo.specialty) {
      info.providerSpecialty = providerInfo.specialty;
    }
    // if (providerInfo.address1) {
    //   if (providerInfo.address2) {
    //     info.providerAddress = `${providerInfo.address1}, ${providerInfo.address2}, ${providerInfo.city}, ${providerInfo.state} ${providerInfo.zip}`
    //   } else {
    //     info.providerAddress = `${providerInfo.address1}, ${providerInfo.city}, ${providerInfo.state} ${providerInfo.zip}`
    //   }
    // }
    let payload = this.buildPayload(info);
    return payload;
  },
  buildSchoolInfo: function (schoolInfo) {
    let schoolName = undefined;
    let schoolState = undefined;
    schoolInfo.name ? (schoolName = schoolInfo.name) : (schoolName = undefined);
    schoolInfo.state
      ? (schoolState = schoolInfo.state)
      : (schoolState = undefined);
    let payloadArray = [
      { schoolName: `${schoolName}` },
      { schoolState: `${schoolState}` },
    ];
    return payloadArray;
  },
  buildSearchParamInfo: function (searchParams, serviceType) {
    let searchParamValues = {};
    for (let param in searchParams) {
      if (searchParams.hasOwnProperty(param)) {
        if (searchParams[param]) {
          searchParamValues[param] = searchParams[param].label;
        }
        if (!searchParams[param]) {
          searchParamValues[param] = `no ${param}`;
        }
      }
    }
    let payload = this.buildPayload(searchParamValues);
    let payloadWithService = Object.assign(payload, { service: serviceType });
    return payloadWithService;
  },
  buildSchoolServiceVisit: function (schoolServiceVisitInfo) {
    let payload = this.buildPayload(schoolServiceVisitInfo);
    return payload;
  },
  buildFeeScheduleInteraction: function (providerInfo) {
    let payload = this.buildProviderInfo(providerInfo);
    for (let dataPoint in payload) {
      // get rid of providerState before returning
      if (payload[dataPoint].providerState) {
        payload.splice(payload[dataPoint], 1);
      }
    }
    return payload;
  },
};
