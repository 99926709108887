import React, {Component} from "../../_snowpack/pkg/react.js";
import Select from "../../_snowpack/pkg/react-select.js";
import shortid from "../../_snowpack/pkg/shortid.js";
import withDropdownLoading from "../HOC/withDropdownLoading.js";
class Dropdown extends Component {
  constructor(props) {
    super(props);
    this.updateSelection = this.updateSelection.bind(this);
    this.id = shortid.generate();
  }
  updateSelection(selection) {
    if (selection === null) {
      this.props.onChangeSelection(this.props.dropdown.name, "none");
    } else {
      this.props.onChangeSelection(this.props.dropdown.name, selection);
    }
  }
  render() {
    const {
      placeholder,
      selectedValue,
      options,
      searchBarBootstrapProps: searchBarCls
    } = this.props.dropdown;
    const searchable = window.innerWidth > 1024 ? true : false;
    return /* @__PURE__ */ React.createElement("div", {
      className: searchBarCls
    }, /* @__PURE__ */ React.createElement("label", {
      id: this.id
    }, this.props.label), /* @__PURE__ */ React.createElement(Select, {
      className: "react-select-container",
      classNamePrefix: "react-select",
      "aria-labelledby": this.id,
      name: "form-field-name",
      placeholder,
      value: selectedValue,
      options,
      onChange: this.updateSelection,
      searchable
    }));
  }
}
export default withDropdownLoading("dropdown")(Dropdown);
