import React, {Component} from "../../../_snowpack/pkg/react.js";
import Store from "./store.js";
import ResultList from "./ResultList.js";
import Select from "../SharedComponents/Select.js";
import BrowseSchedules from "./Components/Index_Components/BrowseSchedules.js";
import SearchSchedules from "./Components/Index_Components/SearchSchedules.js";
import CreateSchedule from "./Components/Index_Components/CreateSchedule.js";
class FeeSchedulesArea extends Component {
  constructor(props) {
    super(props);
    this.state = Store.getState();
  }
  componentDidMount() {
    this.unsubscribeStore = Store.subscribe(this.onStoreChange.bind(this));
  }
  onStoreChange() {
    this.setState(Store.getState());
  }
  componentWillUnmount() {
    this.unsubscribeStore();
  }
  render() {
    return /* @__PURE__ */ React.createElement("section", {
      className: "section-home container"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "row"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "col-sm-12"
    }, /* @__PURE__ */ React.createElement("h1", {
      className: "page-header"
    }, "Fee Schedules"), /* @__PURE__ */ React.createElement(SearchSchedules, null), /* @__PURE__ */ React.createElement(BrowseSchedules, null), /* @__PURE__ */ React.createElement(CreateSchedule, null), /* @__PURE__ */ React.createElement("div", {
      className: "row margin"
    }, /* @__PURE__ */ React.createElement(ResultList, {
      feeSchedules: this.state.feeSchedules
    })))));
  }
}
export default FeeSchedulesArea;
