import React, {Component} from "../../../_snowpack/pkg/react.js";
export default function withTableRows(WrappedComponent) {
  return class TableRow extends Component {
    constructor(props) {
      super(props);
      this.state = {
        checked: false,
        rowClassName: "",
        dataType: this.props.dataType,
        textAreaRowCount: "",
        textAreaColCount: ""
      };
      if (this.props._id) {
        this.state._id = this.props._id;
      }
      this.toggleCheckBox = this.toggleCheckBox.bind(this);
      this.renderValue = this.renderValue.bind(this);
      this.renderArrayItems = this.renderArrayItems.bind(this);
      this.getTextAreaDimensions = this.getTextAreaDimensions.bind(this);
      this.buildContent = this.buildContent.bind(this);
    }
    toggleCheckBox() {
      this.state.checked === true ? this.setState({checked: false}) : this.setState({checked: true});
    }
    getTextAreaDimensions(content) {
      if (content.length < 50) {
        return {cols: `${content.length + content.length / 2}`, rows: 1};
      } else {
        return {cols: 50, rows: Math.ceil(content.length / 40)};
      }
    }
    buildContent(itemIndex, contentPath) {
      if (typeof contentPath === "object") {
        if (typeof itemIndex === "number" && Array.isArray(this.props.content)) {
          return this.props.content[itemIndex][contentPath[0]][contentPath[1]];
        } else {
          return this.props.content[contentPath[0]][contentPath[1]];
        }
      } else {
        if (typeof itemIndex === "number" && Array.isArray(this.props.content)) {
          return this.props.content[itemIndex][contentPath];
        } else {
          return this.props.content[contentPath];
        }
      }
    }
    renderValue(opts) {
      let content = void 0;
      if (this.state.checked === false) {
        content = this.buildContent(this.props.itemIndex, opts.content);
        return /* @__PURE__ */ React.createElement("p", {
          key: `${this.props.childKey}_${opts.name}`,
          className: opts.className
        }, content);
      } else if (this.state.checked === true) {
        content = this.buildContent(this.props.itemIndex, opts.content);
        let dimensions = this.getTextAreaDimensions(content);
        return /* @__PURE__ */ React.createElement("textarea", {
          key: `${this.props.childKey}_${opts.name}`,
          className: opts.className,
          cols: dimensions.cols,
          rows: dimensions.rows,
          value: content,
          onChange: (e) => {
            this.props.updateFieldValue(e, opts.content, this.props.itemIndex, this.props.categoryIndex);
          }
        });
      }
    }
    renderArrayItems(opts) {
      if (this.state.checked === false) {
        return /* @__PURE__ */ React.createElement("span", {
          key: `${this.props.childKey}_${opts.name}`,
          className: opts.className
        }, opts.content);
      } else if (this.state.checked === true) {
        let dimensions = this.getTextAreaDimensions(opts.content);
        return /* @__PURE__ */ React.createElement("textarea", {
          key: `${this.props.childKey}_${opts.name}`,
          className: opts.className,
          cols: dimensions.cols,
          rows: dimensions.rows,
          value: opts.content,
          onChange: (e) => {
            this.props.updateArrayValue(e, opts.arrayName, opts.arrayIndex);
          }
        });
      }
    }
    render() {
      return /* @__PURE__ */ React.createElement(WrappedComponent, {
        key: this.props.childKey,
        ...this.props,
        renderValue: this.renderValue,
        renderArrayItems: this.renderArrayItems,
        toggleCheckBox: this.toggleCheckBox,
        rowClassName: this.state.rowClassName,
        pushNewItemToArray: this.props.pushNewItemToArray,
        deleteArrayItem: this.props.deleteArrayItem,
        checked: this.state.checked
      });
    }
  };
}
