const options = {
  chiro: [
    {key: '_id', label: '_id'},
  	{key: 'first_name', label: 'first_name'},
  	{key: 'middle_initial', label: 'middle_initial'},
  	{key: 'last_name', label: 'last_name'},
  	{key: 'prof_des', label: 'prof_des'},
  	{key: 'specialty', label: 'specialty'},
  	{key: 'group_name', label: 'group_name'},
  	{key: 'location', label: 'location'},
  	{key: 'address1', label: 'address1'},
  	{key: 'address2', label: 'address2'},
  	{key: 'city', label: 'city'},
  	{key: 'state', label: 'state'},
  	{key: 'zip', label: 'zip'},
  	{key: 'phone', label: 'phone'},
  	{key: 'fax', label: 'fax'},
  	{key: 'school', label: 'school'},
  	{key: 'fee_shedule', label: 'fee_shedule'},
  	{key: 'admin_contact', label: 'admin_contact'},
  	{key: 'note', label: 'note'},
  	{key: 'fee_schedule_name', label: 'fee_schedule_name'},
  	{key: 'regions', label: 'regions'},
  	{key: 'preferred', label: 'preferred'},
  	{key: 'rank', label: 'rank'},
  	{key: 'ranking_group', label: 'ranking_group'},
  	{key: 'ranking_group_position', label: 'ranking_group_position'},
  	{key: 'updatedAt', label: 'updatedAt'},
  	{key: 'createdAt', label: 'createdAt'}
  ],
  dental: [
    {key: '_id', label: '_id'},
  	{key: 'dental_id', label: 'dental_id'},
  	{key: 'first_name', label: 'first_name'},
  	{key: 'middle_initial', label: 'middle_initial'},
  	{key: 'last_name', label: 'last_name'},
  	{key: 'prof_des', label: 'prof_des'},
  	{key: 'specialty', label: 'specialty'},
  	{key: 'group_name', label: 'group_name'},
  	{key: 'location', label: 'location'},
  	{key: 'address1', label: 'address1'},
  	{key: 'address2', label: 'address2'},
  	{key: 'city', label: 'city'},
  	{key: 'state', label: 'state'},
  	{key: 'zip', label: 'zip'},
  	{key: 'phone', label: 'phone'},
  	{key: 'fax', label: 'fax'},
  	{key: 'school', label: 'school'},
  	{key: 'fee_shedule', label: 'fee_shedule'},
  	{key: 'admin_contact', label: 'admin_contact'},
  	{key: 'note', label: 'note'},
  	{key: 'fee_schedule_name', label: 'fee_schedule_name'},
  	{key: 'regions', label: 'regions'},
  	{key: 'preferred', label: 'preferred'},
  	{key: 'rank', label: 'rank'},
  	{key: 'ranking_group', label: 'ranking_group'},
  	{key: 'ranking_group_position', label: 'ranking_group_position'},
  	{key: 'updatedAt', label: 'updatedAt'},
  	{key: 'createdAt', label: 'createdAt'}

  ],
  health_club: [
    {key: '_id', label: '_id'},
    {key: 'first_name', label: 'first_name'},
    {key: 'middle_initial', label: 'middle_initial'},
    {key: 'last_name', label: 'last_name'},
    {key: 'prof_des', label: 'prof_des'},
    {key: 'specialty', label: 'specialty'},
    {key: 'group_name', label: 'group_name'},
    {key: 'location', label: 'location'},
    {key: 'address1', label: 'address1'},
    {key: 'address2', label: 'address2'},
    {key: 'city', label: 'city'},
    {key: 'state', label: 'state'},
    {key: 'zip', label: 'zip'},
    {key: 'phone', label: 'phone'},
    {key: 'fax', label: 'fax'},
    {key: 'school', label: 'school'},
    {key: 'fee_shedule', label: 'fee_shedule'},
    {key: 'admin_contact', label: 'admin_contact'},
    {key: 'note', label: 'note'},
    {key: 'fee_schedule_name', label: 'fee_schedule_name'},
    {key: 'regions', label: 'regions'},
    {key: 'preferred', label: 'preferred'},
    {key: 'rank', label: 'rank'},
    {key: 'ranking_group', label: 'ranking_group'},
    {key: 'ranking_group_position', label: 'ranking_group_position'},
    {key: 'updatedAt', label: 'updatedAt'},
    {key: 'createdAt', label: 'createdAt'}
  ],
  eyewear: [
    {key: '_id', label: '_id'},
    {key: 'eyewear_id', label: 'eyewear_id'},
    {key: 'first_name', label: 'first_name'},
    {key: 'middle_initial', label: 'middle_initial'},
    {key: 'last_name', label: 'last_name'},
    {key: 'prof_des', label: 'prof_des'},
    {key: 'specialty', label: 'specialty'},
    {key: 'group_name', label: 'group_name'},
    {key: 'location', label: 'location'},
    {key: 'address1', label: 'address1'},
    {key: 'address2', label: 'address2'},
    {key: 'city', label: 'city'},
    {key: 'state', label: 'state'},
    {key: 'zip', label: 'zip'},
    {key: 'phone', label: 'phone'},
    {key: 'fax', label: 'fax'},
    {key: 'school', label: 'school'},
    {key: 'fee_shedule', label: 'fee_shedule'},
    {key: 'admin_contact', label: 'admin_contact'},
    {key: 'note', label: 'note'},
    {key: 'fee_schedule_name', label: 'fee_schedule_name'},
    {key: 'regions', label: 'regions'},
    {key: 'preferred', label: 'preferred'},
    {key: 'rank', label: 'rank'},
    {key: 'ranking_group', label: 'ranking_group'},
    {key: 'ranking_group_position', label: 'ranking_group_position'},
    {key: 'updatedAt', label: 'updatedAt'},
    {key: 'createdAt', label: 'createdAt'}
  ]
}

export default options
