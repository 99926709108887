import React, {Component} from "../../../_snowpack/pkg/react.js";
import Store from "./store.js";
import isEqual from "../../../_snowpack/pkg/lodash.isequal.js";
import SchoolCategory from "./Components/SchoolCategory.js";
class ResultList extends Component {
  constructor(props) {
    super(props);
    this.state = Store.getState();
    this.renderSchoolTable = this.renderSchoolTable.bind(this);
  }
  componentDidMount() {
    this.unsubscribeStore = Store.subscribe(this.onStoreChange.bind(this));
  }
  onStoreChange() {
    this.setState(Store.getState());
  }
  componentWillUnmount() {
    this.unsubscribeStore();
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.schools !== void 0) {
      if (isEqual(nextProps.schools.searchResult, this.props.schools.searchResult)) {
        console.log("nextProps.schools.searchResult DOES NOT MATCH this.props.schools.searchResult (reducer)");
        return true;
      } else if (Object.keys(nextProps.schools.newSchool).length > 0) {
        if (isEqual(nextProps.schools.newSchool, this.props.schools.newSchool)) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      console.log("nextProps.schools.searchResult MATCHES this.state.schools.searchResult (reducer)");
      return false;
    }
    return true;
  }
  renderSchoolTable() {
    if (this.props.schools !== void 0) {
      if (this.props.schools.newSchool !== void 0 && Object.keys(this.props.schools.newSchool).length > 0) {
        return /* @__PURE__ */ React.createElement(SchoolCategory, {
          data: {school: this.props.schools.newSchool},
          key: 1
        });
      } else {
        if (this.props.schools.searchResult !== void 0) {
          if (Object.keys(this.props.schools.searchResult).length > 0) {
            return /* @__PURE__ */ React.createElement("div", {
              className: ""
            }, /* @__PURE__ */ React.createElement("div", {
              className: "margin"
            }, /* @__PURE__ */ React.createElement(SchoolCategory, {
              data: {school: this.props.schools.searchResult},
              key: 1
            })));
          }
        }
      }
    }
  }
  render() {
    return /* @__PURE__ */ React.createElement("div", {
      className: "providerTable col-xs-10 col-xs-offset-1"
    }, this.renderSchoolTable());
  }
}
export default ResultList;
