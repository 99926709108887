import React, {Component} from "../../../../_snowpack/pkg/react.js";
import Actions from "../actions.js";
import WaiverLine from "./WaiverLine.js";
import WaiverName from "./WaiverName.js";
import withTableRows from "../../SharedComponents/WithTableRows.js";
import withCategory from "../../SharedComponents/WithCategory.js";
const WaiverLineHOC = withTableRows(WaiverLine);
const WaiverNameHOC = withTableRows(WaiverName);
const WaiverLinesCategoryHOC = withCategory(WaiverLineHOC)(Actions);
const WaiverNameCategoryHOC = withCategory(WaiverNameHOC)(Actions);
class WaiverCategory extends Component {
  constructor(props) {
    super(props);
    this.reportChange = this.reportChange.bind(this);
    this.writeChange = this.writeChange.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
  }
  writeChange(e) {
    e.preventDefault();
    if (confirm("Are you sure you want to save this change?")) {
      console.log(this);
      Actions.writeChange(this.props.content.waiver._id);
    }
  }
  deleteItem(e) {
    e.preventDefault();
    if (confirm("Are you sure you want to delete this waiver entry?")) {
      Actions.deleteItem(this.props.content.waiver._id);
    }
  }
  reportChange(change) {
    Actions.reportChange({currentValue: change.currentValue, dataType: change.dataType, _id: this.props.content.waiver._id});
  }
  renderWaiverLines(lines) {
    return lines.map((line, index) => {
      return /* @__PURE__ */ React.createElement(WaiverLineHOC, {
        content: line,
        dataType: "lines",
        reportToParent: this.reportChange,
        index: {serviceIndex: "none"},
        key: `waiverLine_${index}`
      });
    });
  }
  render() {
    return /* @__PURE__ */ React.createElement("div", {
      className: "subSectionPadding"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "row margin buttonRow"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "col-xs-6"
    }, /* @__PURE__ */ React.createElement("button", {
      className: "changeButton btn btn-success",
      onClick: (e) => {
        e.preventDefault();
        this.writeChange(e);
      }
    }, "Save changes to DB")), /* @__PURE__ */ React.createElement("div", {
      className: "col-xs-6"
    }, /* @__PURE__ */ React.createElement("button", {
      className: "changeButton btn btn-danger pull-right",
      onClick: (e) => {
        e.preventDefault();
        this.deleteItem(e);
      }
    }, "Delete waiver"))), /* @__PURE__ */ React.createElement("div", {
      className: "row margin"
    }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(WaiverNameCategoryHOC, {
      data: {waiverName: this.props.content.waiver.waiverName},
      _id: this.props.content.waiver._id,
      dataType: "waiverName",
      itemIndex: "waiverName"
    }), /* @__PURE__ */ React.createElement(WaiverLinesCategoryHOC, {
      data: this.props.content.waiver.lines,
      dataType: "lines",
      _id: this.props.content.waiver._id
    }))));
  }
}
export default withCategory(WaiverCategory)(Actions);
