import React, {Component} from "../../../../_snowpack/pkg/react.js";
import Actions from "../actions.js";
import ProviderRow from "./ProviderRow.js";
import withTableRows from "../../SharedComponents/WithTableRows.js";
import withCategory from "../../SharedComponents/WithCategory.js";
const ProviderRowHOC = withTableRows(ProviderRow);
const ProviderCategoryHOC = withCategory(ProviderRowHOC)(Actions);
const renderIf = (predicate) => (element) => predicate && element;
class ProviderCategory extends Component {
  constructor(props) {
    super(props);
    this.reportChange = this.reportChange.bind(this);
    this.writeChange = this.writeChange.bind(this);
    this.deleteItem = this.deleteItem.bind(this);
    this.cloneProvider = this.cloneProvider.bind(this);
  }
  writeChange(e) {
    e.preventDefault();
    if (confirm("Are you sure you want to save this change?")) {
      Actions.writeChange(this.props.data.provider._id, this.props.db);
    }
  }
  deleteItem(e) {
    e.preventDefault();
    if (confirm("Are you sure you want to delete this provider entry?")) {
      Actions.deleteItem(this.props.data.provider._id, this.props.db);
    }
  }
  reportChange(change) {
    Actions.reportChange({
      currentValue: change.currentValue,
      dataType: change.dataType,
      _id: this.props.data.provider._id
    });
  }
  cloneProvider(e, providerId, db) {
    e.preventDefault();
    Actions.clearSearchAndChanges().then(() => {
      Actions.createProvider("clone", {
        db,
        queries: [
          {field: "_id", query: providerId, hasWildCardSettings: false}
        ]
      });
    }).catch();
  }
  render() {
    return /* @__PURE__ */ React.createElement("div", {
      className: "subSectionPadding"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "row margin buttonRow"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "col-xs-6"
    }, /* @__PURE__ */ React.createElement("button", {
      className: "changeButton btn btn-success",
      onClick: (e) => {
        e.preventDefault();
        this.writeChange(e);
      }
    }, "Save changes to DB")), /* @__PURE__ */ React.createElement("div", {
      className: "col-xs-6"
    }, /* @__PURE__ */ React.createElement("button", {
      className: "changeButton btn btn-danger pull-right",
      onClick: (e) => {
        e.preventDefault();
        this.deleteItem(e);
      }
    }, "Delete provider"))), renderIf(this.props.data.provider._id !== "new")(/* @__PURE__ */ React.createElement("div", {
      className: "row margin buttonRow"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "col-xs-6 "
    }, /* @__PURE__ */ React.createElement("button", {
      className: "btn btn-success",
      onClick: (e) => this.cloneProvider(e, this.props.data.provider._id, this.props.db)
    }, "Create new cloned provider", " ")))), /* @__PURE__ */ React.createElement("div", {
      className: "row margin"
    }, /* @__PURE__ */ React.createElement(ProviderCategoryHOC, {
      data: this.props.data.provider,
      dataType: "providerInfo",
      reportToParent: this.reportChange,
      index: {serviceIndex: "none"},
      key: `Category_${this.props.data.provider._id}`
    })));
  }
}
export default ProviderCategory;
