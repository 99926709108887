import React, {Component} from "../../../../_snowpack/pkg/react.js";
import Actions from "../actions.js";
import Store from "../store.js";
import Select from "../../SharedComponents/Select.js";
import BrowsingTable from "../../SharedComponents/BrowsingTable.js";
class BrowseProviders extends Component {
  constructor(props) {
    super(props);
    let store = Store.getState();
    this.state = {
      providerBrowsingParams: ["_id", "first_name", "last_name"],
      selectedDB: "dental",
      hideBrowseProvidersBtn: "hide",
      browseProvidersRow: "hide",
      providers: store.providers
    };
    this.renderProviderBrowseDropdowns = this.renderProviderBrowseDropdowns.bind(this);
    this.showHideRowAndButton = this.showHideRowAndButton.bind(this);
    this.getBrowsingList = this.getBrowsingList.bind(this);
    this.clearBrowsingList = this.clearBrowsingList.bind(this);
    this.updateDBSelection = this.updateDBSelection.bind(this);
    this.addBrowsingField = this.addBrowsingField.bind(this);
    this.deleteBrowsingField = this.deleteBrowsingField.bind(this);
  }
  componentDidMount() {
    this.unsubscribeStore = Store.subscribe(this.onStoreChange.bind(this));
  }
  onStoreChange() {
    this.setState(Store.getState());
  }
  componentWillUnmount() {
    this.unsubscribeStore();
  }
  renderProviderBrowseDropdowns() {
    return /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("form", {
      className: "w-50"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "form-group row"
    }, /* @__PURE__ */ React.createElement(Select, {
      options: this.props.dbOptions,
      selectedValue: this.state.selectedDB,
      placeholder: "Which database?",
      updateSelection: (selectedDB) => {
        this.updateDBSelection(selectedDB);
      },
      paramType: "db"
    })), this.state.providerBrowsingParams.map((param, index) => {
      return /* @__PURE__ */ React.createElement("div", {
        className: "row vertical-offset-sm"
      }, /* @__PURE__ */ React.createElement(Select, {
        className: "col ",
        options: this.props.browsingColumnOptions[this.state.selectedDB],
        selectedValue: this.state.providerBrowsingParams[index],
        placeholder: "Which search field?",
        updateSelection: (val) => this.updateBrowsingColumnSelection(val, index),
        paramType: "browsingColumn"
      }), /* @__PURE__ */ React.createElement("button", {
        className: "btn btn-danger btn-sm pull-right",
        onClick: (e) => this.deleteBrowsingField(e, param)
      }, " Remove "));
    }), /* @__PURE__ */ React.createElement("div", {
      className: "form-group row"
    }, /* @__PURE__ */ React.createElement("button", {
      className: "btn btn-success",
      onClick: this.addBrowsingField
    }, " Add browsing field "))));
  }
  showHideRowAndButton(e, toggleType, rowName, btnName) {
    e.preventDefault();
    this.setState({
      [rowName]: toggleType,
      [btnName]: toggleType
    });
  }
  updateDBSelection(selection) {
    this.setState({
      selectedDB: selection
    });
  }
  updateBrowsingColumnSelection(fieldName, index) {
    let providerBrowsingParams = this.state.providerBrowsingParams;
    providerBrowsingParams = providerBrowsingParams.map((param, paramIndex) => {
      if (paramIndex === index) {
        return fieldName;
      } else {
        return param;
      }
    });
    this.setState({
      providerBrowsingParams
    });
  }
  addBrowsingField(e) {
    e.preventDefault();
    let providerBrowsingParams = this.state.providerBrowsingParams;
    providerBrowsingParams.push("");
    this.setState({
      providerBrowsingParams
    });
  }
  deleteBrowsingField(e, paramToDelete) {
    e.preventDefault();
    let providerBrowsingParams = this.state.providerBrowsingParams;
    providerBrowsingParams = providerBrowsingParams.filter((param) => param !== paramToDelete);
    this.setState({
      providerBrowsingParams
    });
  }
  getBrowsingList(e) {
    e.preventDefault();
    this.clearBrowsingList();
    window.setTimeout(() => {
      let browsingFields = this.state.providerBrowsingParams;
      if (browsingFields.indexOf("_id") < 0) {
        browsingFields.unshift("_id");
      }
      Actions.getBrowsingList({
        db: this.state.selectedDB,
        fields: JSON.stringify(browsingFields)
      });
    }, 500);
  }
  clearBrowsingList(e) {
    e && e.preventDefault();
    Actions.clearBrowsingList();
  }
  renderTable() {
    if (!this.state.providers.providerBrowsingList || !this.state.providers.tableColumns) {
      return null;
    }
    return /* @__PURE__ */ React.createElement(BrowsingTable, {
      tableItems: this.state.providers.providerBrowsingList,
      tableColumns: this.state.providers.tableColumns
    });
  }
  render() {
    return /* @__PURE__ */ React.createElement("div", {
      className: "row margin subSectionPadding"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "col-xs-12"
    }, /* @__PURE__ */ React.createElement("h2", null, "Browse provider entries")), /* @__PURE__ */ React.createElement("div", {
      className: "col-xs-12"
    }, /* @__PURE__ */ React.createElement("form", {
      className: "margin"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "form-group row"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "col-xs-5"
    }, /* @__PURE__ */ React.createElement("button", {
      className: "browse btn btn-primary",
      onClick: (e) => {
        this.showHideRowAndButton(e, "show", "browseProvidersRow", "hideBrowseProvidersBtn");
      }
    }, "Show panel")), /* @__PURE__ */ React.createElement("div", {
      className: this.state.hideBrowseProvidersBtn + " col-xs-4"
    }, /* @__PURE__ */ React.createElement("button", {
      className: "browse btn btn-warning",
      onClick: (e) => {
        this.clearBrowsingList(e);
        this.showHideRowAndButton(e, "hide", "browseProvidersRow", "hideBrowseProvidersBtn");
      }
    }, " Close panel "))))), /* @__PURE__ */ React.createElement("div", {
      className: this.state.browseProvidersRow + " col-xs-12 browseConfigureRow"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "row"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "col-xs-12"
    }, "Use the dropdowns below to select which information you want to display when browsing provider entries")), /* @__PURE__ */ React.createElement("form", {
      className: "marginTop"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "form-group"
    }, this.renderProviderBrowseDropdowns())), /* @__PURE__ */ React.createElement("form", {
      className: "marginTop"
    }, /* @__PURE__ */ React.createElement("div", {
      className: "form-group"
    }, /* @__PURE__ */ React.createElement("button", {
      className: "search btn btn-primary",
      onClick: (e) => {
        this.getBrowsingList(e);
      }
    }, "Get list")))), /* @__PURE__ */ React.createElement("div", {
      className: this.state.browseProvidersRow + " col-xs-12 marginTop"
    }, this.renderTable()));
  }
}
export default BrowseProviders;
