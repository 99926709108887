import React, {Fragment, useState, useEffect} from "../_snowpack/pkg/react.js";
import {HashRouter as Router, Route, Switch, Link} from "../_snowpack/pkg/react-router-dom.js";
import {createBrowserHistory} from "../_snowpack/pkg/history.js";
import Navbar from "./admin/SharedComponents/Navbar.js";
import Home from "./admin/Home.js";
import FeeSchedules from "./admin/FeeSchedules/FeeSchedules.js";
import Providers from "./admin/Providers/Providers.js";
import Schools from "./admin/Schools/Schools.js";
import Waivers from "./admin/Waivers/Waivers.js";
import Main from "./containers/Main.js";
import Waiver from "./containers/Waiver.js";
import School from "./containers/School.js";
import ProviderSearch from "./containers/ProviderSearch.js";
import Schedule from "./containers/Schedule.js";
import WithBranding from "./containers/WithBranding.js";
import schoolBranding from "./school-branding.js";
import {
  CopyPage,
  About,
  Contact,
  HowToUse,
  FAQ,
  ProviderHome
} from "./components/CopyPage.js";
import PayPalThankYou from "./components/PayPalThankYou.js";
import Header from "./components/Header.js";
const history = createBrowserHistory();
const ClientFacingRouter = () => {
  const [waiverAccepted, setWaiverAccepted] = useState(false);
  useEffect(() => {
    if (localStorage.getItem("basixStudentWaiverAcceptance") === "true") {
      setWaiverAccepted(true);
    }
  }, []);
  const schoolMap = [
    {
      key: "BYU",
      img: "/images/school-logos/byu.svg",
      title: "Brigham Young University",
      waiverLink: "/BYU/waiver",
      mainLink: "/BYU/main"
    },
    {
      key: "Dartmouth",
      img: "/images/school-logos/dartmouth.svg",
      title: "Dartmouth College",
      waiverLink: "/Dartmouth/waiver",
      mainLink: "/Dartmouth/services/dental?state=NH&city=Hanover"
    },
    {
      key: "Duke",
      img: "/images/school-logos/generic.svg",
      title: "Duke University",
      waiverLink: "/Duke/waiver",
      mainLink: "/Duke/services/dental?state=NC&city=Durham"
    },
    {
      key: "Northeastern",
      img: "/images/school-logos/northeastern.svg",
      title: "Northeastern University",
      waiverLink: "/Northeastern/waiver",
      mainLink: "/Northeastern/services/dental?state=MA&city=Boston"
    },
    {
      key: "University_of_New_Hampshire",
      img: "/images/school-logos/unh.svg",
      title: "University of New Hampshire",
      waiverLink: "/University_of_New_Hampshire/waiver",
      mainLink: "/University_of_New_Hampshire/services/dental?state=NH&city=Portsmouth"
    }
  ];
  return /* @__PURE__ */ React.createElement(Fragment, null, /* @__PURE__ */ React.createElement(Header, {
    history,
    schoolMap
  }), /* @__PURE__ */ React.createElement("main", {
    id: "page-wrap"
  }, /* @__PURE__ */ React.createElement(Switch, null, /* @__PURE__ */ React.createElement(Route, {
    exact: true,
    path: "/",
    history,
    render: (routeProps) => {
      return /* @__PURE__ */ React.createElement(Fragment, null, /* @__PURE__ */ React.createElement(WithBranding, {
        img: "/images/bg-img/home.png"
      }, /* @__PURE__ */ React.createElement("p", {
        style: {marginTop: 0}
      }, "Since you're enrolled in a student health plan, you also have access to the", " ", /* @__PURE__ */ React.createElement("strong", null, "BASIX Student Health Discount Program"), ", which offers you quality care at exclusive discounted prices."), /* @__PURE__ */ React.createElement("p", {
        style: {margin: 0}
      }, /* @__PURE__ */ React.createElement("strong", null, "The BASIX Student Health Discount Program is a discount plan, not insurance."))), /* @__PURE__ */ React.createElement("div", {
        className: "content-wrap"
      }, /* @__PURE__ */ React.createElement(Main, {
        history,
        schoolMap,
        waiverAccepted
      })));
    }
  }), /* @__PURE__ */ React.createElement(Route, {
    path: "/:schoolName/waiver",
    render: (routeProps) => {
      const schoolKey = routeProps.match.params.schoolName;
      const schoolName = schoolBranding[schoolKey].name;
      const img = schoolBranding[schoolKey].bgImg;
      const nextLink = schoolMap.find((x) => x.key === schoolKey).mainLink;
      return /* @__PURE__ */ React.createElement(Fragment, null, /* @__PURE__ */ React.createElement(WithBranding, {
        img
      }), /* @__PURE__ */ React.createElement("div", {
        className: "content-wrap wider"
      }, /* @__PURE__ */ React.createElement(Waiver, {
        nextLink,
        schoolKey,
        setWaiverAccepted
      })));
    }
  }), /* @__PURE__ */ React.createElement(Route, {
    path: "/:schoolName/main",
    render: (routeProps) => {
      console.log("eesh", routeProps);
      const schoolKey = routeProps.match.params.schoolName;
      const schoolName = schoolBranding[schoolKey].name;
      const img = schoolBranding[schoolKey].bgImg;
      return /* @__PURE__ */ React.createElement(Fragment, null, /* @__PURE__ */ React.createElement(WithBranding, {
        img
      }, /* @__PURE__ */ React.createElement("p", {
        style: {marginTop: 0, marginBottom: "var(--size-4)"}
      }, "Enrollment in the ", schoolName, " student health plan confirms your automatic access to the", " ", /* @__PURE__ */ React.createElement("strong", null, "BASIX Student Health Discount Program"), ". Click on the available service that aligns with your current needs below to view providers in your area."), /* @__PURE__ */ React.createElement("p", {
        style: {margin: 0}
      }, /* @__PURE__ */ React.createElement("strong", null, "The BASIX Student Health Discount Program is a discount plan, not insurance."))), /* @__PURE__ */ React.createElement("div", {
        className: "content-wrap"
      }, /* @__PURE__ */ React.createElement(School, {
        history,
        ...routeProps
      })));
    }
  }), /* @__PURE__ */ React.createElement(Route, {
    path: "/:schoolName/services/:service",
    history,
    render: (routeProps) => {
      const schoolKey = routeProps.match.params.schoolName;
      const schoolName = schoolBranding[schoolKey].name;
      const img = schoolBranding[schoolKey].bgImg;
      return /* @__PURE__ */ React.createElement(Fragment, null, /* @__PURE__ */ React.createElement(WithBranding, {
        img
      }, /* @__PURE__ */ React.createElement("p", {
        style: {marginTop: 0, marginBottom: "var(--size-4)"}
      }, "Enrollment in the ", schoolName, " student health plan confirms your automatic access to the", " ", /* @__PURE__ */ React.createElement("strong", null, "BASIX Student Health Discount Program"), ". You can sort and filter this list to find the provider that best meets your specific needs."), /* @__PURE__ */ React.createElement("p", {
        style: {margin: 0}
      }, /* @__PURE__ */ React.createElement("strong", null, "The BASIX Student Health Discount Program is a discount plan, not insurance."))), /* @__PURE__ */ React.createElement("div", {
        className: "content-wrap"
      }, /* @__PURE__ */ React.createElement(ProviderSearch, {
        history,
        ...routeProps
      })));
    }
  }), /* @__PURE__ */ React.createElement(Route, {
    path: "/:schoolName/providers/:providerType/:providerID",
    history,
    render: (routeProps) => {
      const schoolKey = routeProps.match.params.schoolName;
      const schoolName = schoolBranding[schoolKey].name;
      const img = schoolBranding[schoolKey].bgImg;
      console.log("hi");
      console.log(schoolName, img);
      return /* @__PURE__ */ React.createElement(Fragment, null, /* @__PURE__ */ React.createElement(WithBranding, {
        img
      }), /* @__PURE__ */ React.createElement("div", {
        className: "content-wrap"
      }, /* @__PURE__ */ React.createElement(Schedule, {
        history,
        ...routeProps
      })));
    }
  }), /* @__PURE__ */ React.createElement(Route, {
    path: "/about",
    render: () => {
      return /* @__PURE__ */ React.createElement(CopyPage, null, /* @__PURE__ */ React.createElement(About, null));
    }
  }), /* @__PURE__ */ React.createElement(Route, {
    path: "/contact",
    render: () => {
      return /* @__PURE__ */ React.createElement(CopyPage, null, /* @__PURE__ */ React.createElement(Contact, null));
    }
  }), /* @__PURE__ */ React.createElement(Route, {
    path: "/how-to-use",
    render: () => {
      return /* @__PURE__ */ React.createElement(CopyPage, null, /* @__PURE__ */ React.createElement(HowToUse, null), /* @__PURE__ */ React.createElement("section", null, /* @__PURE__ */ React.createElement(FAQ, null)));
    }
  }), /* @__PURE__ */ React.createElement(Route, {
    path: "/provider-home",
    render: () => {
      return /* @__PURE__ */ React.createElement(CopyPage, null, /* @__PURE__ */ React.createElement(ProviderHome, null));
    }
  }), /* @__PURE__ */ React.createElement(Route, {
    exact: true,
    path: "/thank_you",
    component: PayPalThankYou,
    history
  }))), /* @__PURE__ */ React.createElement("footer", {
    className: "footer"
  }, /* @__PURE__ */ React.createElement("img", {
    src: "./images/BASIX-white.svg",
    alt: "Basix"
  }), /* @__PURE__ */ React.createElement(Link, {
    to: "/provider-home"
  }, "Are you a provider? Click here to learn more.")));
};
const AdminRouter = () => {
  return /* @__PURE__ */ React.createElement(Fragment, null, /* @__PURE__ */ React.createElement(Route, {
    path: "/admin",
    exact: true,
    component: () => /* @__PURE__ */ React.createElement("div", {
      className: "section-home container"
    }, /* @__PURE__ */ React.createElement(Navbar, null), /* @__PURE__ */ React.createElement(Home, null))
  }), /* @__PURE__ */ React.createElement(Route, {
    path: "/admin/schools",
    exact: true,
    component: () => {
      return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Navbar, null), /* @__PURE__ */ React.createElement(Schools, null));
    }
  }), /* @__PURE__ */ React.createElement(Route, {
    path: "/admin/waivers",
    exact: true,
    component: () => {
      return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Navbar, null), /* @__PURE__ */ React.createElement(Waivers, null));
    }
  }), /* @__PURE__ */ React.createElement(Route, {
    path: "/admin/providers",
    exact: true,
    component: () => {
      return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Navbar, null), /* @__PURE__ */ React.createElement(Providers, null));
    }
  }), /* @__PURE__ */ React.createElement(Route, {
    path: "/admin/feeSchedules",
    exact: true,
    component: () => {
      return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Navbar, null), /* @__PURE__ */ React.createElement(FeeSchedules, null));
    }
  }));
};
const App = () => /* @__PURE__ */ React.createElement(Router, {
  history,
  basename: "/app"
}, /* @__PURE__ */ React.createElement("div", {
  id: "outer-container"
}, /* @__PURE__ */ React.createElement(ClientFacingRouter, null), /* @__PURE__ */ React.createElement(AdminRouter, null)));
export default App;
