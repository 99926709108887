import React, {Component} from "../../_snowpack/pkg/react.js";
import Provider from "./Provider.js";
import withListLoading from "../HOC/withListLoading.js";
const renderIf = (predicate) => (element) => predicate && element;
const ProviderList = (props) => {
  console.log(props);
  let providersToRender = void 0;
  if (Object.keys(props.providers).length < 1) {
    return null;
  }
  if (Object.keys(props.providers).length > 0) {
    providersToRender = props.providers;
  }
  let targetCity = "";
  let neighborCities = "";
  if (providersToRender.targetCity.length > 0) {
    targetCity = providersToRender.targetCity.map((providerDetails, index) => {
      return /* @__PURE__ */ React.createElement(Provider, {
        providerData: providerDetails,
        schoolName: props.schoolAndService.schoolName,
        service: props.schoolAndService.service
      });
    });
  }
  if (providersToRender.neighborCities.length > 0) {
    neighborCities = providersToRender.neighborCities.map((providerDetails, index) => {
      return /* @__PURE__ */ React.createElement(Provider, {
        providerData: providerDetails,
        schoolName: props.schoolAndService.schoolName,
        service: props.schoolAndService.service
      });
    });
  }
  const headingCopy = {
    chiro: "Chiropractic Providers",
    dental: "Dental Providers",
    health_club: "Health Clubs",
    eyewear: "Vision Providers"
  };
  const sectionHeading = headingCopy[props.schoolAndService.service];
  return /* @__PURE__ */ React.createElement("section", null, /* @__PURE__ */ React.createElement("h2", {
    className: "h2",
    style: {textTransform: "capitalize"}
  }, sectionHeading), /* @__PURE__ */ React.createElement("ul", {
    className: "provider-list"
  }, renderIf(targetCity !== "")(targetCity), renderIf(neighborCities !== "")(neighborCities)));
};
export default withListLoading("providers")("queryingProviders")("searchStatus")("searchMessage")(ProviderList);
