import FluxConstant from '../../../_snowpack/pkg/flux-constant.js'
 
export default FluxConstant.set([
  'REPORT_CHANGE',
  'SEND_UPDATE',
  'SEND_UPDATE_RESPONSE',
  'SEND_DELETION',
  'SEND_DELETION_RESPONSE',
  'CREATE_WAIVER',
  'CREATE_WAIVER_RESPONSE',
  'NO_CITY_OR_STATE_ERR',
  'RESET_ERRORS',
  'GET_WAIVER_LIST',
  'GET_WAIVER_LIST_RESPONSE',
  'GET_WAIVER',
  'GET_WAIVER_RESPONSE',
  'SEND_WAIVER_UPDATE',
  'SEND_WAIVER_UPDATE_RESPONSE',
  'INCOMPLETE_WAIVER_INFO',
  'CLEAR_SEARCH_AND_CHANGES'

]);
