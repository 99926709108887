import React, {Component} from "../../_snowpack/pkg/react.js";
import Dummy from "../utils/Dummy.js";
const dummyProviders = Dummy.providers;
const withListLoading = (propName) => (isLoading) => (searchStatus) => (searchMessage) => (WrappedComponent) => {
  return class withListLoading extends Component {
    constructor(props) {
      super(props);
      this.isEmpty = this.isEmpty.bind(this);
      this.state = {
        dummyProviders,
        providers: this.props[propName]
      };
    }
    isEmpty(prop) {
      if (prop === null || prop === void 0 || prop.hasOwnProperty("length") && prop.length === 0 || prop.constructor === Object && Object.keys(prop).length === 0) {
        return true;
      } else {
        return false;
      }
    }
    componentWillReceiveProps(nextProps) {
      this.setState({
        providers: nextProps.providers
      });
    }
    shouldComponentUpdate(nextProps, nextState) {
      if (nextProps[searchStatus] !== this.props[searchStatus]) {
        return true;
      }
      if (nextProps[isLoading]) {
        return true;
      }
      if (JSON.stringify(this.state.providers) === JSON.stringify(nextProps.providers)) {
        return false;
      } else {
        return true;
      }
    }
    render() {
      const isInitialLoad = !this.props[isLoading] && this.props[searchStatus] === "initial page load";
      const isFailedLoad = !this.props[isLoading] && this.props[searchStatus] === "failed";
      const isCurrentlyLoading = this.props[isLoading];
      const hasContent = !this.props[isLoading] && !this.isEmpty(this.props[propName]) && this.props[searchStatus];
      const searchMsgComponent = /* @__PURE__ */ React.createElement("div", {
        className: "col-xs-12 col-md-6 text-center"
      }, " ", this.props.searchMessage, " ");
      const loadingComponent = /* @__PURE__ */ React.createElement("div", {
        className: "row"
      }, /* @__PURE__ */ React.createElement("div", {
        className: "col loading",
        "aria-hidden": "true"
      }, /* @__PURE__ */ React.createElement("div", {
        className: "loading-screen-overlay",
        "aria-hidden": "true"
      }), /* @__PURE__ */ React.createElement(WrappedComponent, {
        ...this.props,
        dummyProviders: this.state.dummyProviders
      })));
      const resultCount = hasContent ? this.props.providers.neighborCities.length + this.props.providers.targetCity.length : 0;
      const component = isInitialLoad || isFailedLoad ? searchMsgComponent : isCurrentlyLoading ? loadingComponent : hasContent ? /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(WrappedComponent, {
        ...this.props
      }), /* @__PURE__ */ React.createElement("p", {
        className: ""
      }, resultCount, " providers returned from search.")) : null;
      const {queryingProviders} = this.props;
      const ariaBusy = queryingProviders ? true : false;
      const aria = {
        "aria-live": "polite",
        "aria-busy": ariaBusy,
        id: "provider-list"
      };
      return /* @__PURE__ */ React.createElement("div", {
        className: "row",
        ...aria
      }, /* @__PURE__ */ React.createElement("div", {
        className: "col"
      }, component));
    }
  };
};
export default withListLoading;
