import React, {Component} from "../../../../_snowpack/pkg/react.js";
const renderIf = (predicate) => (element) => predicate && element;
class ServiceRow extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    if (this.props.empty) {
      return /* @__PURE__ */ React.createElement("tr", null, /* @__PURE__ */ React.createElement("td", {
        colspan: "5"
      }, /* @__PURE__ */ React.createElement("button", {
        className: "btn btn-success btn-xs",
        onClick: (e) => {
          e.preventDefault();
          this.props.addRow(-1, "code");
        }
      }, "Add row")));
    } else {
      return /* @__PURE__ */ React.createElement("table", {
        className: "table table-sm table-striped"
      }, /* @__PURE__ */ React.createElement("tbody", null, renderIf(this.props.itemIndex === 0)(/* @__PURE__ */ React.createElement("tr", null, /* @__PURE__ */ React.createElement("td", {
        colspan: "5"
      }, /* @__PURE__ */ React.createElement("button", {
        className: "btn btn-success btn-xs",
        onClick: (e) => {
          e.preventDefault();
          this.props.addRow(-1, "code");
        }
      }, "Add row")))), /* @__PURE__ */ React.createElement("tr", {
        className: "underline"
      }, /* @__PURE__ */ React.createElement("td", null, "Edit?", /* @__PURE__ */ React.createElement("input", {
        type: "checkbox",
        onChange: this.props.toggleCheckBox
      })), /* @__PURE__ */ React.createElement("td", null, /* @__PURE__ */ React.createElement("p", null, /* @__PURE__ */ React.createElement("strong", null, "code:")), " ", /* @__PURE__ */ React.createElement("span", null, this.props.renderValue({tag: "td", className: "field", content: "code", name: "name"}))), /* @__PURE__ */ React.createElement("td", null, /* @__PURE__ */ React.createElement("p", null, /* @__PURE__ */ React.createElement("strong", null, "description:")), " ", /* @__PURE__ */ React.createElement("span", null, this.props.renderValue({
        tag: "td",
        className: "field",
        content: {
          0: "details",
          1: "description"
        },
        name: "description"
      }))), /* @__PURE__ */ React.createElement("td", null, /* @__PURE__ */ React.createElement("p", null, /* @__PURE__ */ React.createElement("strong", null, "price:")), " ", /* @__PURE__ */ React.createElement("span", null, this.props.renderValue({
        tag: "td",
        className: "field",
        content: {
          0: "details",
          1: "price"
        },
        name: "price"
      }))), /* @__PURE__ */ React.createElement("td", null, /* @__PURE__ */ React.createElement("button", {
        className: "btn btn-danger btn-sm pull-right",
        style: {marginRight: "15px"},
        onClick: (e) => {
          e.preventDefault();
          this.props.deleteRow(this);
        }
      }, "Delete"))), /* @__PURE__ */ React.createElement("tr", null, /* @__PURE__ */ React.createElement("td", {
        colspan: "5"
      }, /* @__PURE__ */ React.createElement("button", {
        className: "btn btn-success btn-xs",
        onClick: (e) => {
          e.preventDefault();
          this.props.addRow(this.props.itemIndex, "code");
        }
      }, "Add row")))));
    }
  }
}
export default ServiceRow;
