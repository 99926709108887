import React, {Component} from "../../../../_snowpack/pkg/react.js";
import shortid from "../../../../_snowpack/pkg/shortid.js";
class ProviderRow extends Component {
  constructor(props) {
    super(props);
    this.renderVals = this.renderVals.bind(this);
    this.renderMultipleItems = this.renderMultipleItems.bind(this);
    this.state = {
      keyID: shortid.generate()
    };
  }
  renderMultipleItems(itemArray, itemType) {
    return itemArray.map((item, index) => {
      return /* @__PURE__ */ React.createElement("tr", {
        key: `${this.state.keyID}_${index}`,
        className: "underline"
      }, /* @__PURE__ */ React.createElement("td", {
        style: {width: "100px"}
      }, "Edit?", /* @__PURE__ */ React.createElement("input", {
        type: "checkbox",
        onChange: this.props.toggleCheckBox
      })), /* @__PURE__ */ React.createElement("td", {
        style: {width: "200px"}
      }, itemType), /* @__PURE__ */ React.createElement("td", null, this.props.renderArrayItems({
        tag: "td",
        content: item,
        arrayName: itemType,
        arrayIndex: index
      })));
    });
  }
  renderVals() {
    let content = this.props.content;
    let contentItem = Object.keys(content)[0];
    if (Array.isArray(this.props.content[contentItem])) {
      return this.renderMultipleItems(this.props.content[contentItem], contentItem);
    } else if (contentItem === "_id") {
      return /* @__PURE__ */ React.createElement("tr", {
        className: "underline"
      }, /* @__PURE__ */ React.createElement("td", {
        style: {width: "100px"}
      }), /* @__PURE__ */ React.createElement("td", {
        style: {width: "200px"}
      }, contentItem), /* @__PURE__ */ React.createElement("td", null, this.props.renderValue({
        tag: "td",
        content: contentItem
      })));
    } else {
      return /* @__PURE__ */ React.createElement("tr", {
        className: "underline"
      }, /* @__PURE__ */ React.createElement("td", {
        style: {width: "100px"}
      }, "Edit?", /* @__PURE__ */ React.createElement("input", {
        type: "checkbox",
        onChange: this.props.toggleCheckBox
      })), /* @__PURE__ */ React.createElement("td", {
        style: {width: "200px"}
      }, contentItem), /* @__PURE__ */ React.createElement("td", null, this.props.renderValue({
        tag: "td",
        content: contentItem
      })));
    }
  }
  render() {
    return this.renderVals();
  }
}
export default ProviderRow;
