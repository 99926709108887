import React, {Component} from "../../../_snowpack/pkg/react.js";
import shortid from "../../../_snowpack/pkg/shortid.js";
import isEqual from "../../../_snowpack/pkg/lodash.isequal.js";
import Schedule from "./Components/Schedule.js";
class ResultList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      feeSchedules: {
        searchResults: [],
        feeScheduleChanges: [],
        newSchedule: {},
        newScheduleChanges: {},
        keyID: shortid.generate()
      }
    };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.feeSchedules !== void 0 && this.props.feeSchedules !== void 0) {
      if (!isEqual(nextProps.feeSchedules, this.state.feeSchedules)) {
        if (!isEqual(nextProps.feeSchedules.searchResults, this.state.feeSchedules.searchResults) || !isEqual(nextProps.feeSchedules.newSchedule, this.state.feeSchedules.newSchedule)) {
          this.setState({
            feeSchedules: nextProps.feeSchedules,
            keyID: shortid.generate()
          });
        }
      } else {
      }
    }
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.feeSchedules !== void 0 && this.props.feeSchedules !== void 0) {
      if (!isEqual(nextProps.feeSchedules.searchResults, this.state.feeSchedules.searchResults)) {
        console.log("nextProps.feeSchedules.searchResults DOES NOT MATCH this.state.feeSchedules.searchResults (reducer)");
        return true;
      } else if (Object.keys(nextProps.feeSchedules.newSchedule).length > 0) {
        if (!isEqual(nextProps.feeSchedules.newSchedule, this.state.newSchedule)) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      console.log("nextProps.feeSchedules.searchResults MATCHES this.state.feeSchedules.searchResults (reducer)");
      return false;
    }
    return true;
  }
  renderSchedules() {
    if (this.state.feeSchedules !== void 0 && Object.keys(this.state.feeSchedules.newSchedule).length > 0) {
      return /* @__PURE__ */ React.createElement(Schedule, {
        key: `Schedule_${this.state.keyID}`,
        schedule: this.state.feeSchedules.newSchedule,
        tableIndex: 0
      });
    } else {
      if (this.state.feeSchedules.searchResults !== void 0) {
        if (this.state.feeSchedules.searchResults.length > 0) {
          return this.state.feeSchedules.searchResults.map((schedule, tableIndex) => {
            return /* @__PURE__ */ React.createElement(Schedule, {
              key: `Schedule_${this.state.keyID}`,
              schedule,
              tableIndex
            });
          });
        }
      }
    }
  }
  render() {
    return /* @__PURE__ */ React.createElement("div", {
      className: "providerTable col-xs-12"
    }, this.renderSchedules());
  }
}
export default ResultList;
