import React, {Component} from "../../../_snowpack/pkg/react.js";
import isEqual from "../../../_snowpack/pkg/lodash.isequal.js";
import Store from "./store.js";
import Waiver from "./Components/Waiver.js";
class ResultList extends Component {
  constructor(props) {
    super(props);
    this.state = Store.getState();
    this.renderWaiverTable = this.renderWaiverTable.bind(this);
  }
  componentDidMount() {
    this.unsubscribeStore = Store.subscribe(this.onStoreChange.bind(this));
  }
  onStoreChange() {
    this.setState(Store.getState());
  }
  componentWillUnmount() {
    this.unsubscribeStore();
  }
  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.waivers !== void 0 && this.props.waivers !== void 0) {
      if (!isEqual(nextProps.waivers.newWaiver, this.props.waivers.newWaiver)) {
        return true;
      }
      if (!isEqual(nextProps.waivers.newWaiverChanges, this.props.waivers.newWaiverChanges)) {
        return true;
      }
      if (!isEqual(nextProps.waivers.searchResult, this.props.waivers.searchResult)) {
        return true;
      }
      if (!isEqual(nextProps.waivers.waiverChanges, this.props.waivers.waiverChanges)) {
        return true;
      }
      return false;
    }
  }
  renderWaiverTable() {
    if (this.props.waivers !== void 0) {
      if (this.props.waivers.newWaiver !== void 0 && Object.keys(this.props.waivers.newWaiver).length > 0) {
        return /* @__PURE__ */ React.createElement(Waiver, {
          data: {waiver: this.props.waivers.newWaiver},
          key: 1
        });
      } else {
        if (this.props.waivers.searchResult !== void 0) {
          if (Object.keys(this.props.waivers.searchResult).length > 0) {
            return /* @__PURE__ */ React.createElement("div", {
              className: "margin"
            }, /* @__PURE__ */ React.createElement(Waiver, {
              data: {waiver: this.props.waivers.searchResult},
              key: 1
            }));
          }
        }
      }
    }
  }
  render() {
    return /* @__PURE__ */ React.createElement("div", {
      className: "providerTable col-xs-10 col-xs-offset-1"
    }, this.renderWaiverTable());
  }
}
export default ResultList;
