// import ApiActions from '../../../actions/api'
import Constants from './constants.js'
import Store from './store.js'
import axios from '../../../_snowpack/pkg/axios.js'

const ApiActions = {get: () => {}, post: () => {}}

class Actions {

  static getProviders(query) {
    if (query.type === 'get') {
      axios({
        method: 'get',
        url: '/admin/providerz',
        params: query
      }).then(res => {
        const { data } = res
  
        Store.dispatch({
          type: Constants.GET_PROVIDERS_RESPONSE, 
          response: data
        })
      });

    }
    if (query.type === 'export') {
      axios({
        method: 'get',
        url: '/admin/providerz',
        params: query
      }).then(res => {
        const { data } = res
  
        Store.dispatch({
          type: Constants.GET_PROVIDERS_EXPORT_RESPONSE, 
          response: data
        })
      });
    }
  }

  static getBrowsingList(query) {
    axios({
      method: 'get',
      url: '/admin/providerz/list',
      params: query
    }).then(res => {
      const { data } = res

      Store.dispatch({
        type: Constants.GET_PROVIDERS_LIST_RESPONSE, 
        response: data
      })
    });
  }

  static clearBrowsingList() {
    Store.dispatch({
      type: Constants.CLEAR_PROVIDERS_LIST,
      data: ''
    });
  }

  static reportChange(changeObj) {
    Store.dispatch({
      type: Constants.REPORT_CHANGE,
      change: changeObj
    });
  }

  static writeChange(scheduleId, db){
    let editedProvider = undefined
    let reduxStore = Store.getState()

    if (scheduleId === 'new') {
      editedProvider = reduxStore.providers.newProviderChanges
      if (!editedProvider.state || !editedProvider.city) {
        Store.dispatch({
          type: Constants.NO_CITY_OR_STATE_ERR,
          error: {}
        });
        return false;
      }
    } else {
      let changes = reduxStore.providers.providerChanges
      editedProvider = changes.filter(provider => {
        return provider._id === scheduleId
      })
      editedProvider = editedProvider[0]
    }

    axios({
      method: 'post',
      url: '/admin/providerz/update/',
      data: {editedProvider: editedProvider, db: db}
    }).then(res => {
      const { data } = res

      Store.dispatch({
        type: Constants.SEND_UPDATE_RESPONSE, 
        response: data
      })
    });
  }

  static deleteItem(scheduleId, db) {
    axios({
      method: 'post',
      url: '/admin/providerz/delete/',
      data: {providerToDelete: scheduleId, db: db}
    }).then(res => {
      const { data } = res

      Store.dispatch({
        type: Constants.SEND_DELETION_RESPONSE, 
        response: data
      })
    });
  }

  static createProvider(newOrClone, newProviderParams){
    console.log(newOrClone);
    console.log(newProviderParams);
    if (newOrClone === 'new') {
      Store.dispatch({
        type: Constants.CREATE_NEW_PROVIDER,
        createType: 'new',
        providerType: newProviderParams
      });
    }
    if (newOrClone === 'clone') {
      let query = {
        db: newProviderParams.db,
        queries: JSON.stringify(newProviderParams.queries)
      }

      axios({
        method: 'get',
        url: '/admin/providerz/',
        params: query
      }).then(res => {
        const { data } = res
  
        Store.dispatch({
          type: Constants.CREATE_CLONE_PROVIDER_RESPONSE, 
          response: data
        })
      });
    }
  }

  static clearSearchAndChanges() {
    return new Promise((resolve, reject) => {
      let state = Store.getState()
      if (state.providers.searchResults.length > 0 || Object.keys(state.providers.newProvider).length > 0) {
        if (confirm('Would you like to proceed? Any unsaved changes you\'ve made will be lost')) {
          Store.dispatch({
            type: Constants.CLEAR_SEARCH_AND_CHANGES
          })
          resolve()
        }
        reject()
      }
      resolve()
    })
  }


  static resetErrorMessage(){
    Store.dispatch({
      type: Constants.RESET_ERRORS,
      change: {}
    });
  }
}


export default Actions;
