import React, {Component} from "../../../_snowpack/pkg/react.js";
class Select extends Component {
  constructor(props) {
    super(props);
    this.renderOptions = this.renderOptions.bind(this);
  }
  renderOptions(optionList) {
    return optionList.map((optionItem, index) => {
      return /* @__PURE__ */ React.createElement("option", {
        key: index,
        value: optionItem.key
      }, optionItem.label);
    });
  }
  render() {
    if (this.props.options) {
      return /* @__PURE__ */ React.createElement("select", {
        className: this.props.className + " form-control",
        value: this.props.selectedValue,
        onChange: (e) => {
          this.props.updateSelection(e.target.value, this.props.paramType, this.props.index);
        }
      }, /* @__PURE__ */ React.createElement("option", {
        value: "",
        disabled: true
      }, this.props.placeholder), this.renderOptions(this.props.options));
    } else {
      return /* @__PURE__ */ React.createElement("div", null);
    }
  }
}
export default Select;
